import { useState } from "react";
import QuestMenu from "./_components/menu";
import Carousel from "./_components/carousel";
import TaskScreen from "./screens/taskScreen";
import AchievementScreen from "./screens/achievementScreen";
import UpperInformation from "../../components/upperInformation/upperInformation";

export default function QuestScreen() {
  const [sectionSelected, setSectionSelected] = useState(0);

  return (
    <div
      className="w-full h-full flex flex-col items-start pb-5 gap-4 mt-4"
      style={{ marginBottom: "100px" }}
    >
      <UpperInformation
        showProfilePicture={false}
        showProgressBar={false}
        useMargin
      />
      <Carousel />
      <QuestMenu
        selected={sectionSelected}
        setSelected={(x: number | string) => {
          if (typeof x === "number") setSectionSelected(x);
        }}
      />
      {sectionSelected === 0 ? <TaskScreen /> : <AchievementScreen />}
    </div>
  );
}
