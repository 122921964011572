export function formatNumber(num: number) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K";
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else {
    return num;
  }
}

export async function remember(key: string, ttl: number, cb: Function) {
  let value = localStorage.getItem(key);
  if (value) {
    const data = JSON.parse(value);
    if (data.expiry > Date.now()) {
      return data.value;
    }
  }
  value = await cb();
  localStorage.setItem(
    key,
    JSON.stringify({ value, expiry: Date.now() + ttl })
  );
  return value;
}

export function capitalizeFirstLetter(string: string) {
  var splitStr = string.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}
