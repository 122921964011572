import "tailwindcss/tailwind.css";
import { useGame } from "../../../providers/GameProvider";
import { useAuth } from "../../../providers/AuthProvider";
import { formatNumber } from "../../../utils/helper";
import { useProfilePicture } from "../../../hooks/useProfilePicture";
import HelpTipButton from "../../../components/HelpTipButton";
import UpperInformation from "../../../components/upperInformation/upperInformation";

interface HomeUpperI {
  title: string;
  description: string;
}

interface CoinHolderI {
  text: string;
  coinImage: string;
}

function CoinHolder({ text, coinImage }: CoinHolderI) {
  return (
    <div
      className="relative flex bg-[#8B6D75] rounded-tl-2xl rounded-br-2xl rounded-tr-sm rounded-bl-sm border-black border-2 pb-1"
      style={{
        minWidth: "77px",
        maxWidth: "82px",
        height: "24px",
        paddingBottom: "2.5px",
      }}
    >
      <div className="grid grid-cols-4 py-1 bg-[#FFF8DB] rounded-tl-2xl rounded-br-xl rounded-tr-sm px-2 w-full">
        <div className="col-span-1">
          <img
            src={coinImage}
            alt="coin"
            className="absolute"
            style={{
              maxWidth: "25.5px",
              maxHeight: "25.5px",
              top: "-12px",
              left: "5px",
            }}
          />
        </div>
        <span
          className="absolute col-span-3 font-bold text-[#714C56]"
          style={{
            right: "6px",
            top: "0px",
            fontSize: "12px",
          }}
        >
          {text}
        </span>
      </div>
    </div>
  );
}

export default function ShopHeader(props: HomeUpperI) {
  const { title, description } = props;

  return (
    <div className="relative w-full" style={{ height: "275px" }}>
      <div
        className="w-full flex flex-row justify-between relative"
        style={{
          background: "url('/shop_store_roof.png')",
          backgroundSize: "cover",
          zIndex: 10,
        }}
      >
        <UpperInformation
          showProfilePicture={true}
          showProgressBar={false}
          useMargin={false}
        />
      </div>
      <div
        className="pt-8 px-4 flex flex-col gap-2 w-[200px] relative"
        style={{
          zIndex: 9,
        }}
      >
        <h5
          className="text-xl font-extrabold text-white"
          style={{
            textShadow:
              "-0.8px 0 #27364E, 0 0.8px #27364E, 0.8px 0 #27364E, 0 -0.8px #27364E",
          }}
        >
          {title}
        </h5>
        <p
          className="text-xs font-extrabold text-white"
          style={{
            textShadow:
              "-0.8px 0 #4E6D9B, 0 0.8px #4E6D9B, 0.8px 0 #4E6D9B, 0 -0.8px #4E6D9B",
          }}
        >
          {description}
        </p>
      </div>
      <img
        src="/shop_banner_pic.png"
        alt="Shop Banner Picture Icon"
        className="w-full absolute top-0 z-[5] mt-3"
      />
      <img
        src="/shop_banner_bg.png"
        alt="Shop Banner Picture Icon"
        className="w-full absolute bottom-0 z-0"
      />
    </div>
  );
}
