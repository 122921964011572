import { useEffect, useState } from "react";
import { QuestTaskI, QuestTaskListI } from "../data/tasks";
import AchievementItem from "../_components/achievementItem";
import axios from "axios";
import TaskItem from "../_components/taskItem";
import { capitalizeFirstLetter } from "../../../utils/helper";
import { AlertTheme } from "../../../const/alert.const";
import { useAuth } from "../../../providers/AuthProvider";

export default function TaskScreen() {
  const [screenLoading, setScreenLoading] = useState<boolean>(false);
  const [quests, setQuests] = useState<QuestTaskI[]>([
    {
      name: "Claim Rewards",
      type: "rewards",
      list: [],
    },
    {
      name: "Tasks List",
      type: "tasks",
      list: [],
    },
    {
      name: "Finished Tasks",
      type: "finished",
      list: [],
    },
  ]);
  const { setUser, user } = useAuth();

  const fetchTasks = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/tasks`
    );

    const data: QuestTaskListI[] = response.data.data;
    const readyToClaim = data.filter((x) => x.status === "ready_to_claim");
    const taskList = data.filter(
      (x) => x.status === "open" || x.status === "verifying"
    );
    const finished = data.filter((x) => x.status === "completed");

    setQuests([
      {
        name: "Claim Rewards",
        type: "rewards",
        list: readyToClaim,
      },
      {
        name: "Tasks List",
        type: "tasks",
        list: taskList,
      },
      {
        name: "Finished Tasks",
        type: "finished",
        list: finished,
      },
    ]);
  };

  useEffect(() => {
    setScreenLoading(true);
    fetchTasks().finally(() => setScreenLoading(false));
  }, []);

  const verifyTask = async (item: QuestTaskListI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/tasks/${item.id}/verify`
      );

      if (response.status === 200) {
        const data: QuestTaskListI = response.data.data;

        if (data.completed_at) {
          fetchTasks();

          alert(
            `Verification Success, you can claim your reward!`,
            //@ts-ignore
            AlertTheme.SUCCESS
          );
        } else if (data.started_at && !item.started_at) {
          // if link available then open the link
          if (item.link && item.started_at === null) {
            window.location.href = item.link;
            return;
          }
        } else {
          throw new Error(
            `Verification Failed, make sure you completed the task!`
          );
        }
        return;
        //
      }

      throw new Error(`Verification Failed: ${response.status}`);
    } catch (e: Error | any) {
      console.error(e);

      if (e instanceof Error) {
        alert(
          `${e.message}`,
          //@ts-ignore
          AlertTheme.ERROR
        );
      } else {
        alert(
          `Verification Failed, unknown reason!`,
          //@ts-ignore
          AlertTheme.ERROR
        );
      }

      throw e;
    }
  };

  const getButtonText = (data: QuestTaskListI) => {
    if (data.started_at && !data.completed_at) {
      return "Check";
    } else if (data.completed_at && data.status === "ready_to_claim") {
      return "Claim";
    } else if (data.completed_at) {
      return "Claimed";
    } else if (!data.started_at && !data.completed_at) {
      // text based on task type
      if (data.category) {
        const txt = data.category.split(":");
        if (txt.length > 1) {
          return `${capitalizeFirstLetter(txt[1].replace("_", " "))}`;
        } else if (txt.length === 1) {
          return `Open ${txt[0]}`;
        }
      }
    }

    return "Claim";
  };

  const claimTask = async (taskId: string, reward: string | number) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/tasks/${taskId}/claim`
    );

    if (response.status === 200) {
      fetchTasks().then(() => {
        if (user) {
          const _newU = { ...user };
          _newU.amber_amount = _newU.amber_amount + Number(reward);
          setUser(_newU);
        }
      });
      //@ts-ignore
      alert(
        <>
          <div className="w-full flex flex-row justify-between">
            <span className="ml-2">Claimed Success!</span>
            <div className="flex flex-row gap-2">
              <img src="amber_icon.png" alt="amber icon" className="max-h-5" />
              {reward}
            </div>
          </div>
        </>,
        //@ts-ignore
        AlertTheme.SUCCESS
      );
    } else {
      //@ts-ignore
      alert(`Claimed Failed!`, AlertTheme.ERROR);
    }
  };

  if (screenLoading)
    return (
      <div className="w-full h-full flex flex-col gap-5 px-4 items-center justify-center text-[#353D54]">
        <p>Loading...</p>
      </div>
    );

  return (
    <div className="w-full flex flex-col gap-5 px-4">
      {quests.map((quest, idx) => (
        <div
          className={`flex flex-col ${
            quest.list.length === 0 ? "gap-2" : "gap-4"
          } text-[#353D54]`}
          key={idx}
        >
          <p className="text-base font-extrabold">{quest.name}</p>
          <div className="flex flex-col gap-1">
            {quest.list.length === 0 ? (
              <div className="text-sm">
                <p>No item yet</p>
              </div>
            ) : (
              <>
                {quest.list.map((item) => (
                  <TaskItem
                    name={item.name}
                    imageUrl={item.asset_url}
                    reward={item.reward.toLocaleString()}
                    type={quest.type}
                    borderColor={
                      quest.type === "finished"
                        ? "rgba(179, 126, 43, 0.5)"
                        : undefined
                    }
                    key={item.id}
                    id={item.id}
                    description={item.description}
                    onClick={() => {
                      if (item.status === "ready_to_claim") {
                        return claimTask(item.id.toString(), item.reward);
                      } else if (
                        item.status === "verifying" ||
                        item.status === "open"
                      ) {
                        return verifyTask(item);
                      }
                      return Promise.all([]);
                    }}
                    buttonEnabled={item.status !== "completed"}
                    buttonText={getButtonText(item)}
                    item={item}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
