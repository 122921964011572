// List of game assets that are used in the game
// these assets will be loaded befoer the game starts
// TODO: Add more assets here
// TODO: Asset from backend need to be loaded
export const ASSETS = {
  NO_EGGS: "/no-eggs.png",
  HOME_BG: "/homebg.png",
  EGG_HATCHING: "/egg-hatching.png",
  REWARD_RIBBON: "/reward-ribbon.png",
  AUTOFARM_BG:
    "https://blastbirds-assets.sgp1.cdn.digitaloceanspaces.com/miniapps/autofarm-bg.png",
  COIN: "/icon_bbcoin.svg",
  UNDEPLOYED_HATCHLING_BOX:
    "https://blastbirds-assets.sgp1.cdn.digitaloceanspaces.com/miniapps/deploy-block-hatchling.png",
  ICON_CHECK:
    "https://blastbirds-assets.sgp1.cdn.digitaloceanspaces.com/miniapps/check.png",
};

export const hatchlingBg = (
  faction: string | undefined
): {
  bgGradient: string;
  border: string;
  radialGradient?: string;
} => {
  switch (faction) {
    case "lava":
      return {
        bgGradient: "bg-gradient-to-br from-[#BE4028] to-[#FF2618]",
        border: "border-[#B00E0E]",
        radialGradient:
          "radial-gradient(50% 50% at 50% 50%, #BE4028 0%, #FF2618 100%)",
      };
    case "sun":
      return {
        bgGradient: "bg-gradient-to-br from-[#E6AA3C] to-[#D8BD36]",
        border: "border-[#F59E0B]",
        radialGradient:
          "radial-gradient(50% 50% at 50% 50%, #FFF3BD 0%, #F2891E 100%)",
      };
    case "ocean":
      return {
        bgGradient: "bg-gradient-to-br from-[#4C93EF] to-[#52B8EB]",
        border: "border-[#87D0F3]",
        radialGradient:
          "radial-gradient(50% 50% at 50% 50%, #4C93EF 0%, #52B8EB 100%)",
      };
    case "shadow":
      return {
        bgGradient: "bg-gradient-to-br from-[#B2AEB3] to-[#656368]",
        border: "border-[#868B98]",
        radialGradient:
          "radial-gradient(50% 50% at 50% 50%, #B2AEB3 0%, #656368 100%)",
      };
    case "nature":
      return {
        bgGradient: "bg-gradient-to-br from-[#99D78E] to-[#3CAE20]",
        border: "border-[#95F534]",
        radialGradient:
          "radial-gradient(50% 50% at 50% 50%, #99D78E 0%, #3CAE20 100%)",
      };
    case "random":
      return {
        bgGradient:
          "bg-gradient-to-br from-[#B5DDEC] via-[#F8D475] to-[#F07C87]",
        border: "border-transparent bg-clip-border",
        radialGradient:
          "radial-gradient(50% 50% at 50% 50%, #F8D475 0%, #F07C87 100%)",
      };
    default:
      return {
        bgGradient: "bg-gradient-to-br from-[#B2AEB3] to-[#656368]",
        border: "border-[#868B98]",
        radialGradient:
          "radial-gradient(50% 50% at 50% 50%, #B2AEB3 0%, #656368 100%)",
      };
  }
};
