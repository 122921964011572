import { useState } from "react";
import HelpTipButton from "../../../components/HelpTipButton";
import * as Icon from "@heroicons/react/24/solid";
import { AlertTheme } from "../../../const/alert.const";

interface InviteFriendSectionI {
  onInvite: (friendCode: string) => void;
  userFriendCode: string;
  currentReffer: number;
  targetReffer: number;
  earned: number;
}

const FriendProgress = (props: { percentage: number }) => {
  const { percentage } = props;
  return (
    <div
      className="w-full rounded-full bg-[#20697B] border-[#175868] border-2"
      style={{
        height: "18px",
        paddingBottom: "4px",
      }}
    >
      <div className="relative w-full h-full bg-[#308BA2] rounded-full">
        <div
          className={`absolute bg-[#baedfb] ${
            percentage < 97.5 ? "rounded-l-full" : "rounded-full"
          }`}
          style={{
            width: `${percentage}%`,
            paddingTop: "3px",
            height: "14px",
          }}
        >
          <div
            className={`w-full h-full bg-[#3fa6c3] ${
              percentage < 97.5 ? "rounded-l-full" : "rounded-full"
            }`}
            style={{ paddingBottom: "3px" }}
          >
            <div
              className={`w-full h-full bg-[#55d5f5] ${
                percentage < 97.5 ? "rounded-l-full" : "rounded-full"
              }`}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InviteFriendSection = (props: InviteFriendSectionI) => {
  const [friendCode, setFriendCode] = useState<string>("");
  const percentage = (props.currentReffer / props.targetReffer) * 100;

  return (
    <div className="flex flex-col items-center justify-center gap-2 w-full">
      {/* title here */}
      <div className="w-full flex flex-row gap-2">
        <span className="font-bold text-xl">Invite Friend</span>
        <HelpTipButton />
      </div>

      <div className="bg-[#F7F8FA] w-full px-2 py-2 border-t-2 border-blue-200 rounded-lg shadow-sm w-full">
        <div className="flex flex-row items-center justify-between w-full">
          <span className="font-semibold text-sm text-blue-300">{`Your Friend Code is ${props.userFriendCode}`}</span>
          <Icon.DocumentDuplicateIcon
            className="w-5 h-5 hover:scale-110 cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(props.userFriendCode);

              //@ts-ignore
              alert("success copy friend code", AlertTheme.SUCCESS);
            }}
            color="black"
          />
        </div>
      </div>

      {/* progress bar here */}
      <div className="flex flex-col w-full">
        <FriendProgress percentage={percentage} />
        <div className="flex flex-row items-center justify-between w-full text-[#324267] font-semibold text-xs px-1 mt-1">
          <span>{`${props.currentReffer}/${props.targetReffer} Referred`}</span>
          <span>{`${props.earned} Earned`}</span>
        </div>
      </div>
    </div>
  );
};

export default InviteFriendSection;
