import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { CanvasTapButton } from "./canvasTapButton";
import { useEffect, useRef, useState } from "react";
import { useGame } from "../../../providers/GameProvider";
import { useAuth } from "../../../providers/AuthProvider";
import "../home.css";
import { BbirdButton } from "../../../components/BbirdButton";
import { BbirdButtonTheme } from "../../../const/button.const";
import { PickEggModal } from "./modal";
import useModal from "../../../hooks/useModal";
import { LevelUp } from "./levelUp";
import { ASSETS } from "../../../const/asset.const";
import { ShopBtn } from "./nest";
import { usePage } from "../../../providers/PageProvider";
import { AppContext } from "../../../const/state.const";

export default function HomeClicker() {
  const { user } = useAuth();
  const lastTapTimes = useRef<any[]>([]);
  const [impactOccurred, notificationOccurred] = useHapticFeedback();
  const { energy, tap, currentEnergy, activeEgg, hatch } = useGame();
  const [deviceMode, setDeviceMode] = useState<"DESKTOP" | "MOBILE" | null>(
    null
  );
  const { isOpen, openModal, closeModal } = useModal();
  const { getContext } = usePage();

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(
      userAgent
    );
    const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(
      userAgent
    );
    if (isMobile || isTablet) {
      setDeviceMode("MOBILE");
    } else {
      setDeviceMode("DESKTOP");
    }
  }, []);

  const handleClick = (event: any) => {
    const now = Date.now();
    const touchIdentifier = event.changedTouches
      ? event.changedTouches[0].identifier
      : "mouse";

    // Clean up old tap times
    lastTapTimes.current = lastTapTimes.current.filter(
      (tap: any) => now - tap.time < 200
    );

    // Check if there's any recent tap
    if (lastTapTimes.current.some((tap: any) => tap.id === touchIdentifier)) {
      return;
    }

    // Register the new tap
    lastTapTimes.current.push({ id: touchIdentifier, time: now });

    if (energy?.currentEnergy! <= 0) {
      notificationOccurred("error");
      alert("Maximum clicks reached! Wait a moment to regain clicks.");
      return;
    }

    if ((energy?.currentEnergy ?? 0) - user?.level.earn_per_tap! < 0) {
      notificationOccurred("error");
      console.log("tap will negative");
      return;
    }

    tap(user?.level.earn_per_tap!);
    impactOccurred("medium");
  };
  return (
    <>
      {/* Start Modal */}
      <PickEggModal isOpen={isOpen} onClose={() => closeModal()}></PickEggModal>
      {/* End Modal */}
      <div
        className="relative h-full w-full flex items-center justify-center px-4"
        style={{
          minHeight: "50vh",
        }}
      >
        <div
          className="absolute top-24 right-0 flex flex-col gap-4 pr-2"
          style={{
            zIndex: 15,
          }}
        >
          <LevelUp />
          {getContext(AppContext.SHOW_SHOP_BTN) && <ShopBtn />}
        </div>

        {/* Show the pick button if the user doesn't have the active egg */}
        {!activeEgg && (
          <div
            className="absolute flex justify-center items-center left-0 right-0 m-auto"
            style={{
              width: "100%",
              height: "300px",
            }}
          >
            <BbirdButton
              theme={BbirdButtonTheme.PRIMARY}
              onClick={async () => {
                openModal();
              }}
            >
              <p
                className="text-center text-white font-bold text-stroke"
                style={{ fontSize: "14px" }}
              >
                Pick an Egg!
              </p>
            </BbirdButton>
          </div>
        )}

        {activeEgg ? (
          <CanvasTapButton
            disabled={false}
            onClick={handleClick}
            energyPerTap={energy?.energyPerTap ?? 0}
            currentEnergy={currentEnergy ?? 0}
            deviceMode={deviceMode ?? undefined}
            activeEgg={activeEgg}
            hatch={hatch}
          />
        ) : (
          <div>
            <img src={ASSETS.NO_EGGS} alt="No Eggs" />
          </div>
        )}
      </div>
    </>
  );
}
