import React from "react";
import { HatchlingDeployed } from "../interfaces/item.interface";
import { useAutoFarm } from "../hooks/useAutoFarm";
import { Energy, useGame } from "./GameProvider";

interface AutoFarmContextProps {
  items: HatchlingDeployed[];
  totalPassiveIncome: number;
  deploy: (hatchlingIds: number[]) => void;
}

const AutoFarmContext = React.createContext<AutoFarmContextProps | undefined>(
  undefined
);

export function AutoFarmProvider({ children }: { children: React.ReactNode }) {
  const { items, deploy } = useAutoFarm();
  const { setEnergy } = useGame();
  const totalPassiveIncome = React.useMemo(() => {
    return (
      items.reduce((acc, item) => acc + item.passive_income_per_second, 0) * 60
    );
  }, [items]);

  React.useEffect(() => {
    // Update total passive income if items change
    setEnergy((energy: any): Energy => {
      return {
        ...energy,
        passiveIncomePerSec: totalPassiveIncome / 60,
      };
    });
  }, [totalPassiveIncome]);

  // Define the provider value here
  return (
    <AutoFarmContext.Provider
      value={{
        items,
        totalPassiveIncome,
        deploy,
      }}
    >
      {children}
    </AutoFarmContext.Provider>
  );
}

export function useAutoFarmContext() {
  const context = React.useContext(AutoFarmContext);
  if (!context) {
    throw new Error("useAutoFarm must be used within a AutoFarmProvider");
  }
  return context;
}

export default AutoFarmProvider;
