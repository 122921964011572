import React from "react";
import { Item } from "../interfaces/item.interface";
import axios, { AxiosResponse } from "axios";
import { useGame } from "../providers/GameProvider";

type UseEggI = {
  eggs: Item[];
  loading: boolean;
  pickEgg: (userItemId: number) => Promise<AxiosResponse<any, any> | undefined>;
  getEggs: () => void;
};

export function useEggs(): UseEggI {
  const { activeEgg } = useGame();
  const [eggs, setEggs] = React.useState<Item[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    setLoading(true);
    getEggs();
  }, []);

  // When the active egg changes, we need to refresh the eggs list
  React.useEffect(() => {
    if (!activeEgg) {
      getEggs();
    }
  }, [activeEgg]);

  const getEggs = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/eggs`)
      .then((data) => {
        // Grouping items by their properties
        const groupedItems: any = {};

        data.data.data.forEach((entry: any) => {
          const item = entry.item;
          if (item) {
            const itemId = item.id;

            if (!groupedItems[itemId]) {
              groupedItems[itemId] = {
                ...item,
                count_total: 0,
                ids: [],
              };
            }

            groupedItems[itemId].count_total++;
            groupedItems[itemId].ids.push(entry.id);
          }
        });

        // Convert the grouped items object to an array
        const result: Item[] = Object.values(groupedItems);
        setEggs(result);
      })
      .finally(() => setLoading(false));
  };

  const pickEgg = async (userItemId: number) => {
    try {
      setLoading(true);
      return await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/eggs/choose`,
        {
          egg_id: userItemId,
        }
      );
    } catch (e: any) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return {
    eggs,
    loading,
    pickEgg,
    getEggs,
  };
}
