// src/context/AlertContext.js
import React, { createContext, useEffect, useState } from "react";
import {
  AlertTheme,
  AlertThemeData,
  AlertThemeI,
} from "../../const/alert.const";
import BbirdCustomAlert from "./BbirdAlert";

interface AlertContextI {
  message: string | React.ReactNode;
  theme?: AlertTheme | AlertThemeI;
  setAlert: (
    message: string | React.ReactNode,
    theme?: AlertTheme | AlertThemeI
  ) => void;
  visible: boolean;
}

export const AlertContext = createContext<AlertContextI>({
  message: "",
  setAlert: () => {},
  theme: AlertTheme.PRIMARY,
  visible: false,
});

export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [message, setMessage] = useState<string | React.ReactNode>("");
  const [visible, setVisible] = useState(false);
  const [theme, setTheme] = useState<AlertThemeI>(
    AlertThemeData[AlertTheme.PRIMARY]
  );

  const setAlert = (
    message: string | React.ReactNode,
    _theme?: AlertTheme | AlertThemeI
  ) => {
    setMessage(message);
    setVisible(true);
    if (_theme) {
      if (typeof _theme === "string") {
        setTheme(AlertThemeData[_theme]);

        return;
      }
      setTheme(_theme);
    }
  };

  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        setVisible(false);
        setMessage("");
        setTheme(AlertThemeData[AlertTheme.PRIMARY]);
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [visible]);

  return (
    <AlertContext.Provider value={{ message, visible, theme, setAlert }}>
      <BbirdCustomAlert
        theme={theme}
        visible={visible}
        message={message}
      ></BbirdCustomAlert>
      {children}
    </AlertContext.Provider>
  );
};
