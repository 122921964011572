import React from "react";
import { AlertThemeI } from "../../const/alert.const";

interface AlertI {
  children?: React.ReactNode;
  theme: AlertThemeI;
  visible: boolean;
  message: string | React.ReactNode;
}

export default function BbirdCustomAlert(props: AlertI) {
  if (!props.visible) return <></>;
  return (
    <div
      className={`absolute flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end transition duration-500 ease-in-out transform ${
        props.visible ? "translate-y-0 opacity-100" : "translate-y-4 opacity-0"
      }`}
      role="alert"
      style={{ zIndex: 1000, width: "100vw" }}
    >
      <div
        className="max-w-sm w-full  border-2  text-black font-semibold px-4 py-3 rounded-xl relative pointer-events-auto flex flex-row items-center"
        style={{
          backgroundColor: props.theme.backgroundColor,
          borderColor: props.theme.borderColor,
        }}
      >
        {props.theme.icon}
        {typeof props.message === "string" ? (
          <span className="ml-2">{props.message}</span>
        ) : (
          props.message
        )}
      </div>
    </div>
  );
}
