interface QuestMenuI {
    selected: number | string;
    setSelected: (x: number | string) => void;
}

export default function QuestMenu(props: QuestMenuI) {
    const { selected, setSelected } = props;

    return (
        <div className="w-full py-2 flex flex-col gap-2 px-4">
            <div
                className="w-full flex border-black border-2 bg-[#FFBAB9] rounded-full"
                style={{
                    height: "37px",
                }}
            >
                <div className="relative w-full h-full flex flex-row border-[#FF9A97] border-b-4 rounded-full">
                    <div
                        onClick={() => setSelected(0)}
                        className={`absolute left-0 w-1/2 flex text-center font-bold ${
                            selected === 0
                                ? "bg-[#FF9A97] border-2 border-[#5A3D45] rounded-full"
                                : "border-transparent text-[#fffbea] cursor-pointer"
                        }`}
                        style={{
                            paddingTop: "2.5px",
                        }}
                    >
                        <div
                            className={`w-full flex  rounded-full ${
                                selected === 0 ? "bg-[#AB3636]" : ""
                            }`}
                            style={{
                                paddingBottom: "2.5px",
                            }}
                        >
                            <div
                                className={`relative w-full rounded-full ${
                                    selected === 0 ? "bg-[#FF5B5A]" : ""
                                }`}
                            >
                                <span
                                    className="text-stroke"
                                    data-text="Tasks"
                                    style={{
                                        fontSize: "12px",
                                    }}
                                >
                                    Tasks{" "}
                                </span>
                                {selected === 0 && (
                                    <div
                                        className="absolute inset-0 top-1 left-1 bg-[#FF9A97] rounded-l-full max-w-8 max-h-5"
                                        style={{
                                            clipPath: "circle(20% at 20% 20%)",
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        onClick={() => setSelected(1)}
                        className={`absolute right-0 w-1/2 flex text-center ${
                            selected === 1
                                ? "bg-[#FF9A97] border-2 border-[#5A3D45] rounded-full"
                                : "border-transparent text-[#fffbea]"
                        } ${
                            selected !== 1
                                ? "cursor-pointer"
                                : "cursor-disabled"
                        }`}
                        style={{
                            paddingTop: "2.5px",
                        }}
                    >
                        <div
                            className={`w-full flex  rounded-full ${
                                selected === 1 ? "bg-[#AB3636]" : ""
                            }`}
                            style={{
                                paddingBottom: "2.5px",
                            }}
                        >
                            <div
                                className={`relative w-full rounded-full ${
                                    selected === 1 ? "bg-[#FF5B5A]" : ""
                                }`}
                            >
                                <span
                                    className="text-stroke-home-opt font-bold"
                                    data-text="Achievements"
                                    style={{
                                        fontSize: "12px",
                                    }}
                                >
                                    Achievements{" "}
                                </span>
                                {selected === 1 && (
                                    <div
                                        className="absolute inset-2 top-1 left-1 bg-[#FF9A97] rounded-l-full max-w-8 max-h-5"
                                        style={{
                                            clipPath: "circle(20% at 20% 20%)",
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
