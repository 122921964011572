import { formatNumber } from "../../../../utils/helper";
import { FriendI } from "./interface";

interface FriendListCardI {
  friend: FriendI;
}

export default function FriendListCard(props: FriendListCardI) {
  return (
    <div
      className="w-full bg-white rounded-xl border-blue-200 p-2 mb-1 text-[#3B5274]"
      style={{ borderWidth: "3px" }}
    >
      <div className="grid grid-cols-8 w-full gap-2">
        <div className="col-span-2 flex flex-col items-center justify-center">
          <img
            src={props.friend.photo ?? "/profpic_dummy.png"}
            alt={props.friend.username}
            className="w-10 h-10"
          />
        </div>

        <div className="col-span-4 flex flex-col items-start justify-center">
          <span className="font-bold ">{props.friend.name}</span>
          <span className="text-sm">@{props.friend.username}</span>
        </div>

        <div className="col-span-2 flex flex-col items-center justify-center">
          <span className="font-bold break-all">
            {formatNumber(props.friend.score)}
          </span>
        </div>
      </div>
    </div>
  );
}
