export enum BbirdButtonTheme {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  SUCCESS = "success",
  ERROR = "error",
}

export interface ButtonColorVariant {
  default: string;
  pressed: string;
  disabled: string;
}

export interface ButtonTheme {
  topStrokeColor: ButtonColorVariant;
  bottomStrokeColor: ButtonColorVariant;
  mainColor: ButtonColorVariant;
  lightningEffectColor: ButtonColorVariant;
  borderColor: ButtonColorVariant;
}

export interface BbirdButtonI {
  onClick?: (a: any) => void;
  children?: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  width?: string;
  theme: BbirdButtonTheme | ButtonTheme;
  className?: string;
}

export const BbirdButtonThemeOptions: {
  [key in BbirdButtonTheme]: ButtonTheme;
} = {
  [BbirdButtonTheme.PRIMARY]: {
    topStrokeColor: {
      default: "#FFDF88", //255,223,136
      pressed: "#FFB33A", //255,179,58
      disabled: "#FFF2CF", //255,242,207
    },
    mainColor: {
      default: "#FFB33A", //255,179,58
      pressed: "#D96922", //217,105,34
      disabled: "#FEE69D", //254,230,157
    },
    bottomStrokeColor: {
      default: "#B0471B", //176,71,27
      pressed: "#B0471B", //176,71,27
      disabled: "#FFD471", //255,212,113
    },
    lightningEffectColor: {
      default: "#FFF0C3", //255,240,195
      pressed: "#FFC954", //255,201,84
      disabled: "#FFF2CF", //255,242,207
    },
    borderColor: {
      default: "#8B3618", //139,54,24
      pressed: "#8B3618", //139,54,24
      disabled: "#FBA444", //251,164,68
    },
  },
  [BbirdButtonTheme.SECONDARY]: {
    topStrokeColor: {
      default: "#BAEDFB", //186,237,251
      pressed: "#76DDF7", //118,221,247
      disabled: "#D5F4FC", //213,244,252
    },
    mainColor: {
      default: "#43AFCA", //67,175,202
      pressed: "#308BA2", //48,139,162
      disabled: "#C8F1FC", //200,241,252
    },
    bottomStrokeColor: {
      default: "#20697B", //32,105,123
      pressed: "#20697B", //32,105,123
      disabled: "#ABEBFA", //171,235,250
    },
    lightningEffectColor: {
      default: "#CAF2FC", //202,242,252
      pressed: "#76DDF7", //118,221,247
      disabled: "#E3F8FD", //227,248,253
    },
    borderColor: {
      default: "#175868", //23,88,104
      pressed: "#175868", //23,88,104
      disabled: "#76DDF7", //118,221,247
    },
  },
  [BbirdButtonTheme.SUCCESS]: {
    topStrokeColor: {
      default: "#AFE8C2", //175,232,194
      pressed: "#63D288", //99,210,136
      disabled: "#CFF2DA", //207,242,218
    },
    mainColor: {
      default: "#3DC76E", //61,199,110
      pressed: "#256F3F", //37,112,63
      disabled: "#BFEFCE", //191,237,206
    },
    bottomStrokeColor: {
      default: "#256F3F", //37,112,63
      pressed: "#1B492B", //27,73,43
      disabled: "#9FE4B5", //159,228,181
    },
    lightningEffectColor: {
      default: "#AFE8C2", //175,232,194
      pressed: "#63D288", //99,210,136
      disabled: "#E5F4EB", //229,244,235
    },
    borderColor: {
      default: "#16492B", //22,73,43
      pressed: "#153620", //21,54,32
      disabled: "#63D288", //99,210,136
    },
  },
  [BbirdButtonTheme.ERROR]: {
    topStrokeColor: {
      default: "#FF9DA5", //255,157,165
      pressed: "#FF4456", //255,68,86
      disabled: "#FFC3C8", //255,195,200
    },
    mainColor: {
      default: "#FF4456", //255,68,86
      pressed: "#921320", //146,19,32
      disabled: "#FFB0B6", //255,176,182
    },
    bottomStrokeColor: {
      default: "#921320", //146,19,32
      pressed: "#5D0F17", //93,15,23
      disabled: "#FF8B95", //255,139,149
    },
    lightningEffectColor: {
      default: "#FFB5BB", //255,181,187
      pressed: "#FF4456", //255,68,86
      disabled: "#FFD8DB", //255,216,219
    },
    borderColor: {
      default: "#5D0F17", //93,15,23
      pressed: "#450B12", //69,11,18
      disabled: "#FF6774", //255,103,116
    },
  },
};
