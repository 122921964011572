import { MyRankResponse, UserRankResponse } from "../data/rank.dto";
import RankBanner from "./banner";
import Ranks from "./ranks";

interface RankContentI {
  user: MyRankResponse | null;
  rankList: UserRankResponse[];
  isLoading: boolean;
  focusMode: boolean;
}

export default function RankContent(props: RankContentI) {
  const lastUpdateTime = new Date((props.user?.last_update ?? 0) * 1000);
  return (
    <div className="flex flex-col gap-3 w-full">
      <RankBanner user={props.user} isLoading={props.isLoading} />
      {props.user && !props.isLoading ? (
        <p className="text-center text-black" style={{ fontSize: "12px" }}>
          Last updated{" "}
          <span className="font-extrabold">
            {lastUpdateTime.toLocaleString()}
          </span>
        </p>
      ) : (
        <div className="flex justify-center items-center">
          {/* Skeleton Loader */}
          <div className="w-32 h-4 bg-gray-300 animate-pulse rounded-md"></div>
        </div>
      )}
      <Ranks
        ranks={props.rankList}
        user={props.user}
        isLoading={props.isLoading}
        focusMode={props.focusMode}
      />
    </div>
  );
}
