import { useEffect, useState } from "react";
import {
  QuestAchievementsI,
  QuestAchievementsListI,
} from "../data/achievements";
import axios from "axios";
import AchievementItem from "../_components/achievementItem";
import { AlertTheme } from "../../../const/alert.const";
import { useAuth } from "../../../providers/AuthProvider";

export default function AchievementScreen() {
  const [screenLoading, setScreenLoading] = useState<boolean>(false);
  const [achievements, setAchievements] = useState<QuestAchievementsI[]>([
    {
      name: "Claim Rewards",
      type: "rewards",
      list: [],
    },
    {
      name: "Tasks List",
      type: "tasks",
      list: [],
    },
    {
      name: "Finished Tasks",
      type: "finished",
      list: [],
    },
  ]);
  const { setUser, user } = useAuth();

  const fetchAchievements = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/achievements`
    );
    const data: QuestAchievementsListI[] = response.data.data;
    const readyToClaim = data.filter((x) => x.status === "ready_to_claim");
    const taskList = data.filter((x) => x.status === "open");
    const finished = data.filter((x) => x.status === "completed");

    setAchievements([
      {
        name: "Claim Rewards",
        type: "rewards",
        list: readyToClaim,
      },
      {
        name: "Tasks List",
        type: "tasks",
        list: taskList,
      },
      {
        name: "Finished Tasks",
        type: "finished",
        list: finished,
      },
    ]);
  };

  useEffect(() => {
    setScreenLoading(true);
    fetchAchievements().finally(() => setScreenLoading(false));
  }, []);

  const claimAchievements = async (
    achievementId: string,
    reward: string | number
  ) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/achievements/${achievementId}/claim`
    );

    if (response.status === 200) {
      fetchAchievements().then(() => {
        if (user) {
          const _newU = { ...user };
          _newU.amber_amount = _newU.amber_amount + Number(reward);
          setUser(_newU);
        }
      });
      //@ts-ignore
      alert(
        <>
          <div className="w-full flex flex-row justify-between">
            <span className="ml-2">Claimed Success!</span>
            <div className="flex flex-row gap-2">
              <img src="amber_icon.png" alt="amber icon" className="max-h-5" />
              {reward}
            </div>
          </div>
        </>,
        //@ts-ignore
        AlertTheme.SUCCESS
      );
    } else {
      //@ts-ignore
      alert(`Claimed Failed!`, AlertTheme.ERROR);
    }
  };

  if (screenLoading)
    return (
      <div className="w-full h-full flex flex-col gap-5 px-4 items-center justify-center text-[#353D54]">
        <p>Loading...</p>
      </div>
    );

  return (
    <div className="w-full flex flex-col gap-5 px-4">
      {achievements.map((quest, idx) => (
        <div
          className={`flex flex-col ${
            quest.list.length === 0 ? "gap-2" : "gap-4"
          } text-[#353D54]`}
          key={idx}
        >
          <p className="text-base font-extrabold">{quest.name}</p>
          <div className="flex flex-col gap-1">
            {quest.list.length === 0 ? (
              <div className="text-sm">
                <p>No item yet</p>
              </div>
            ) : (
              <>
                {quest.list.map((item, idx) => (
                  <AchievementItem
                    name={item.name}
                    imageUrl={item.asset_url}
                    reward={item.reward.toLocaleString()}
                    type={quest.type}
                    status={item.status}
                    borderColor={
                      quest.type === "finished"
                        ? "rgba(179, 126, 43, 0.5)"
                        : undefined
                    }
                    key={idx}
                    id={item.id}
                    description={item.description}
                    onClick={() => {
                      return claimAchievements(item.id.toString(), item.reward);
                    }}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
