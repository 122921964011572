interface RankItemI {
  rank: number;
  username: string;
  amber: string;
  level: string;
  isLoading?: boolean;
  borderColor?: string;
  backgroundColor?: string;
  profilePicture?: string;
}

export default function RankItem(props: RankItemI) {
  const {
    rank,
    username,
    amber,
    borderColor = "#FFFFFF",
    backgroundColor = "#FEEBCD",
    profilePicture,
    level,
    isLoading,
  } = props;

  return (
    <div
      className="w-full flex items-center py-[10px] px-4 gap-[10px]"
      style={{
        border: `2px solid ${isLoading ? "" : borderColor}`,
        borderRadius: "10px",
        backgroundColor: isLoading ? "#F4F3D4" : backgroundColor,
      }}
    >
      {isLoading ? (
        <>
          {/* Skeleton for Rank */}
          <div className="w-[28px] h-[28px] bg-gray-300 animate-pulse flex items-center justify-center rounded-xl" />

          {/* Skeleton for Profile Picture */}
          <div className="w-[32px] h-[32px] bg-gray-300 rounded-full animate-pulse" />

          {/* Skeleton for Username and Level */}
          <div className="flex-1 flex items-center justify-between gap-1 text-[#5A3D45]">
            <div className="flex flex-col gap-1 flex-1">
              <div className="w-24 h-4 bg-gray-300 animate-pulse rounded-md" />
              <div className="w-16 h-3 bg-gray-300 animate-pulse rounded-md" />
            </div>

            {/* Skeleton for Amber Icon and Value */}
            <div className="flex items-center gap-1">
              <div className="w-[20px] h-[20px] bg-gray-300 animate-pulse rounded-full" />
              <div className="w-8 h-4 bg-gray-300 animate-pulse rounded-md" />
            </div>
          </div>
        </>
      ) : (
        <>
          {/* Actual Content When Not Loading */}
          {rank > 3 ? (
            <div
              className="flex items-center justify-center"
              style={{ width: "28px", height: "28px" }}
            >
              <span
                className="text-sm font-extrabold"
                style={{ color: "#5A3D45" }}
              >
                {rank}
              </span>
            </div>
          ) : (
            <img
              src={`profile_rank_${rank}.png`}
              alt={`Rank ${rank}`}
              width={28}
              height={28}
            />
          )}
          <img
            src={profilePicture ?? "profile.png"}
            alt="Profile picture"
            width={32}
            height={32}
            className="rounded-full"
          />
          <div className="flex-1 flex items-center justify-between gap-1 text-[#5A3D45]">
            <div className="flex flex-col gap-1 flex-1">
              <p className="text-sm font-extrabold">{username}</p>
              <p className="text-xs">{level}</p>
            </div>
            <div className="flex items-center gap-1">
              <img
                src="amber_icon.png"
                alt="amber icon"
                width={20}
                height={20}
              />
              <p className="text-sm font-extrabold">{amber}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
