import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";

export enum AlertTheme {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  SUCCESS = "success",
  ERROR = "error",
}

export interface AlertThemeI {
  borderColor: string;
  backgroundColor: string;
  icon?: React.ReactNode;
}

export interface AlertI {
  theme: AlertThemeI | AlertTheme;
  duration?: number;
  label: string;
  children?: React.ReactNode;
}

export const AlertThemeData: { [key in AlertTheme]: AlertThemeI } = {
  [AlertTheme.PRIMARY]: {
    borderColor: "rgb(249,144,43)",
    backgroundColor: "rgb(255, 240, 195)",
    icon: (
      <ExclamationTriangleIcon
        className="w-6 h-6 mr-2"
        color="rgb(249,144,43)"
      />
    ),
  },
  [AlertTheme.SECONDARY]: {
    borderColor: "rgb(85,213,245)",
    backgroundColor: "rgb(202,242,252)",
    icon: (
      <ExclamationTriangleIcon
        className="w-6 h-6 mr-2"
        color="rgb(67, 199, 110)"
      />
    ),
  },
  [AlertTheme.SUCCESS]: {
    borderColor: "rgb(49, 155, 86)",
    backgroundColor: "rgb(195, 238, 209)",
    icon: (
      <CheckCircleIcon className="w-6 h-6 mr-2" color="rgb(61, 199, 110)" />
    ),
  },
  [AlertTheme.ERROR]: {
    borderColor: "rgb(255,68,86)",
    backgroundColor: "rgb(255, 206, 210)",
    icon: (
      <ExclamationTriangleIcon
        className="w-6 h-6 mr-2"
        color="rgb(255, 26, 53)"
      />
    ),
  },
};

export default function ADummy() {
  return <></>;
}
