import React from "react";
import { FloatingIcon } from "../../../components/FloatingIcon/FloatingIcon";
import useModal from "../../../hooks/useModal";
import { NestModal } from "./modal";
import { usePage } from "../../../providers/PageProvider";

export const ShopBtn = () => {
  const { isOpen, toggleModal } = useModal();
  const { setSelectedPage } = usePage();
  return (
    <>
      {/* <NestModal isOpen={isOpen} onClose={() => toggleModal()} /> */}
      <div className="animated-button cursor-pointer">
        <FloatingIcon
          icon={"/shop_icon.png"}
          title="Shop"
          onClick={() => {
            setSelectedPage("shop");
          }}
        />
      </div>
    </>
  );
};
