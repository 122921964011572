import { FunctionComponent } from "react";

interface TotalPlayerComponentI {
  totalPlayer: number;
}

const TotalPlayersSection = (props: TotalPlayerComponentI) => {
  return (
    <div className="self-stretch rounded-radius-m bg-color-secondary-blue-b-600 border-color-secondary-blue-b-800 border-[1.8px] border-solid flex flex-row items-center justify-between py-spacing-spacing-x-sm px-spacing-spacing-sm text-center text-xs text-color-generic-white gap-2">
      <div className="flex-1 flex flex-row items-center justify-start gap-spacing-spacing-x-sm">
        <img
          className="w-5 relative h-5 overflow-hidden shrink-0"
          alt=""
          src="user-group.svg"
        />
        <div className="relative leading-[14px] font-extrabold">
          Total Players
        </div>
      </div>
      <div className="relative leading-[14px] font-semibold">
        {props.totalPlayer.toLocaleString()}
      </div>
    </div>
  );
};

export default TotalPlayersSection;
