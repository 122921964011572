import { useInitData } from "@vkruglikov/react-telegram-web-app";
import { useEffect, useState } from "react";
import AuthProvider, { useAuth } from "../../providers/AuthProvider";
import { usePage } from "../../providers/PageProvider";
import axios from "axios";
import "./onboarding.css";
import { BbirdButton } from "../../components/BbirdButton";
import { BbirdButtonTheme } from "../../const/button.const";
import { CardDialog } from "../../components/dialog/cardDialog";
import { Dialog } from "../../components/dialog/dialog";

enum OnBoardingDialogStatus {
  SUCCESS,
  ERROR,
  NONE,
}

export default function OnboardingScreen() {
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState<OnBoardingDialogStatus>(
    OnBoardingDialogStatus.NONE
  );
  const [initDataUnsafe, initData] = useInitData();
  const [referralCode, setReferralCode] = useState("");
  const { user, setRefreshUser } = useAuth();
  const { setSelectedPage, query } = usePage();

  useEffect(() => {
    if (user && query && query.refcode) {
      setSelectedPage("Friends");
    } else if (user) {
      setSelectedPage("Home");
    } else if (query && query.refcode) {
      setReferralCode(query.refcode.toUpperCase());
    }
  }, [user]);

  const onTap = (e: any) => {
    e.preventDefault();
    if (!referralCode || referralCode.length < 3) {
      setShowDialog(OnBoardingDialogStatus.ERROR);
      return;
    }

    if (!initData) {
      setShowDialog(OnBoardingDialogStatus.ERROR);
      return;
    }

    setLoading(true);

    // Push the referral code to the backend
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/auth/register`, {
        init_data: initData,
        referral_code: referralCode,
      })
      .then((response) => {
        if (response.status === 200) {
          setShowDialog(OnBoardingDialogStatus.SUCCESS);
          return;
        }

        throw new Error(`Something went wrong: ${response.status}`);
      })
      .catch((e) => {
        console.error(e);
        setShowDialog(OnBoardingDialogStatus.ERROR);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const letsPlay = () => {
    window.location.reload();
  };

  return (
    <div
      className="flex items-center justify-center h-full px-4 "
      // style={{
      //   backgroundImage: `url('/onboard_bg.png')`,
      //   backgroundSize: "cover",
      // }}
    >
      <CardDialog
        title="Activation Code"
        background="#537FB7"
        innerBackground="#D4E3F3"
      >
        <form className="flex flex-col items-center justify-center gap-2">
          <input
            className="bg-[#F7F8FA] w-full mt-4 px-3 py-2 border-t-2 border-blue-300 rounded-lg shadow-sm"
            id="referral"
            type="text"
            placeholder="Input Code e.g. A1B2C3"
            value={referralCode}
            onChange={(e) =>
              setReferralCode(e.target.value.toString().toUpperCase())
            }
          />

          <p className="w-full text-xs text-[#4E6D9B] text-left font-semibold">
            Activate your valid activation code here.
          </p>

          <div className="w-full flex flex-col items-center justify-center">
            <BbirdButton
              theme={BbirdButtonTheme.PRIMARY}
              onClick={onTap}
              width="60%"
              disabled={loading}
            >
              <div className="flex flex-row items-center justify-center">
                {loading && (
                  <img
                    src="/loading_circle.svg"
                    alt="loading-cirlce"
                    className="w-4 h-4 mr-2"
                  />
                )}
                <p
                  className="text-center text-white font-bold text-stroke"
                  style={{ fontSize: "14px" }}
                >
                  {loading ? "Loading..." : "Submit"}
                </p>
              </div>
            </BbirdButton>
            <a href={process.env.GET_ACTIVATION_CODE_URL ?? "#"}>
              <div
                className="underline text-[#3B5274] font-bold mt-2 cursor-pointer"
                style={{
                  fontSize: "14px",
                }}
              >
                Get Activation Code
              </div>
            </a>
          </div>
        </form>
      </CardDialog>

      {/* dialog will be under here */}
      <Dialog
        open={showDialog === OnBoardingDialogStatus.ERROR}
        title="Invalid Code"
        background="#ed7267"
        innerBackground="#f8d4d1"
        titleClass="text-stroke-error font-bold pb-1"
        onClose={() => setShowDialog(OnBoardingDialogStatus.NONE)}
      >
        <div className="w-full flex flex-col items-center justify-center gap-4">
          <img
            src="/onboard_error.png"
            alt="error-image"
            className="w-20 h-20"
          />
          <div className="w-full flex flex-col items-center justify-center">
            <span className="text-lg font-bold text-[#AF403A]">
              Code Not Recognized
            </span>
            <span className="text-sm text-center text-[#D7554E] p-2">
              The code is invalid. Check the Announcements channel for a new
              code.
            </span>
          </div>
          <BbirdButton
            theme={BbirdButtonTheme.ERROR}
            width="50%"
            onClick={() => {
              alert("Get A Code");
            }}
          >
            <span className="text-stroke-error font-bold">Get A Code</span>
          </BbirdButton>
        </div>
      </Dialog>

      <Dialog
        open={showDialog === OnBoardingDialogStatus.SUCCESS}
        title="Success!"
        background="#78cc7c"
        innerBackground="#d4f0d7"
        titleClass="text-stroke-success font-bold pb-1"
      >
        <div className="w-full flex flex-col items-center justify-center gap-4">
          <img src="/onboard_ok.png" alt="error-image" className="w-20 h-20" />
          <div className="w-full flex flex-col items-center justify-center">
            <span className="text-lg font-bold text-[#347C44]">
              Code Activated
            </span>
            <span className="text-sm text-center text-[#46A65B] p-2">
              Your code has been activated. Let’s get started!
            </span>
          </div>
          <BbirdButton
            theme={BbirdButtonTheme.SUCCESS}
            width="50%"
            onClick={() => {
              letsPlay();
            }}
          >
            <span className="text-stroke-success font-bold">Let's Play!</span>
          </BbirdButton>
        </div>
      </Dialog>
    </div>
  );
}
