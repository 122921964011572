import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { MenuList, PageI, PageLocation } from "../const/menu.const";
import MenuButton from "./MenuButton";

interface FooterMenuI {
  selectedMenu?: string;
  setSelectedMenu?: (menu: string) => void;
  setScreen?: (screen: React.ReactElement) => void;
}

export default function FooterMenu(props: FooterMenuI) {
  const [impactOccurred] = useHapticFeedback();
  let buttonPressTimer: NodeJS.Timeout | null = null;

  const handleButtonPress = () => {
    buttonPressTimer = setTimeout(() => {
      impactOccurred("light");
    }, 1500);
  };

  const handleButtonRelease = () => {
    if (buttonPressTimer) {
      clearTimeout(buttonPressTimer);
    }
  };

  const onClick = (menu: PageI) => {
    if (menu.disabled) return;

    props.setSelectedMenu!(menu.name);
    if (menu.screen) {
      props.setScreen!(menu.screen);
    }
  };

  return (
    <footer className="w-full bg-[#353D54] py-2 mt-auto rounded-t-xl">
      <nav className="grid grid-cols-5 justify-items-center items-center mt-1">
        {MenuList.map((menu) => {
          if (menu.location.includes(PageLocation.FOOTER)) {
            const isSelected = props.selectedMenu === menu.name;
            return (
              <div key={menu.name} className="relative group">
                <button
                  onTouchStart={handleButtonPress}
                  onTouchEnd={handleButtonRelease}
                  onMouseDown={handleButtonPress}
                  onMouseUp={handleButtonRelease}
                  onMouseLeave={handleButtonRelease}
                  className={`flex flex-col items-center transition-transform transform ${
                    menu.disabled
                      ? "text-gray-500 cursor-not-allowed"
                      : isSelected
                      ? "text-blue-700 scale-110"
                      : "text-blue-500 hover:text-blue-700 hover:scale-110"
                  }`}
                  onClick={() => onClick(menu)}
                  disabled={menu.disabled}
                  data-tooltip={menu.toolTip}
                >
                  {isSelected && menu.iconOnSelected ? (
                    typeof menu.iconOnSelected === "string" ? (
                      // <img
                      //   src={menu.iconOnSelected}
                      //   alt={`${menu.name} icon`}
                      //   className="h-14 w-14 mb-1 transition-all duration-300"
                      // />
                      <div className="h-12 w-12 transition-all duration-300">
                        <MenuButton
                          icon={menu.icon}
                          text={menu.name}
                          onClick={() => {}}
                        />
                      </div>
                    ) : (
                      <div className="h-14 w-14 mb-1 transition-all duration-300">
                        {menu.iconOnSelected}
                      </div>
                    )
                  ) : menu.iconOnHover ? (
                    typeof menu.iconOnHover === "string" ? (
                      <img
                        src={menu.iconOnHover}
                        alt={`${menu.name} icon`}
                        className="h-12 mb-1 transition-all duration-300"
                      />
                    ) : (
                      <div className="h-12 mb-1 transition-all duration-300">
                        {menu.iconOnHover}
                      </div>
                    )
                  ) : typeof menu.icon === "string" ? (
                    <img
                      src={menu.icon}
                      alt={`${menu.name} icon`}
                      className="h-12 mb-1 transition-all duration-300"
                    />
                  ) : (
                    <div className="h-12 mb-1 transition-all duration-300">
                      {menu.icon}
                    </div>
                  )}
                </button>
              </div>
            );
          }

          return null;
        })}
      </nav>
    </footer>
  );
}
