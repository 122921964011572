import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

const AuthContext = createContext({
  token: localStorage.getItem("token") || null,
  setToken: (newToken: string | null) => {},
  user: null as User | null,
  setUser: (newUser: User | null) => {},
  loading: false,
  setLoading: (newLoading: boolean) => {},
  refreshUser: false,
  setRefreshUser: (x: boolean) => {},
});

export interface Level {
  id: number;
  level: number;
  name: string;
  upgrade_price: number;
  tap_recovered: number;
  earn_per_tap: number;
  max_tap: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
}

interface User {
  id: number;
  tg_id: number;
  first_name: string;
  last_name: string;
  username: string;
  language_code: string;
  coin_amount: number;
  level_id: number;
  last_tap: number;
  is_premium: boolean;
  referral_code: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  amber_amount: number;
  ton_coin_amount: number;
  level: Level;
}

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  // State to hold the authentication token
  const [token, setToken_] = useState<string | null>(null);

  // State to hold the user
  const [user, setUser_] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [refreshUser, setRefreshUser] = useState(false);

  // Function to set the authentication token and persist it in local storage
  const setToken = (newToken: string | null) => {
    setToken_(newToken);
    if (newToken) {
      localStorage.setItem("token", newToken);
      axios.defaults.headers.common["Authorization"] = "Bearer " + newToken;
    } else {
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  // Function to set the user state
  const setUser = (newUser: User | null) => {
    setUser_(newUser);
  };

  // Effect to set the Axios default authorization header when token changes
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken && !token) {
      setToken_(storedToken);
      axios.defaults.headers.common["Authorization"] = "Bearer " + storedToken;
    }
  }, [token]);

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      user,
      setUser,
      loading,
      setLoading,
      refreshUser,
      setRefreshUser,
    }),
    [token, user, loading]
  );

  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

// Custom hook to use the authentication context
export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
