import { CardDialog, CardDialogI } from "./cardDialog";

interface DialogI extends CardDialogI {
  backdrop?: string;
  open: boolean;
}

export const Dialog = (props: DialogI) => {
  if (!props.open) {
    return <></>;
  }

  return (
    <div
      className={`fixed z-50 inset-0 flex items-center justify-center px-4 ${
        props.backdrop ?? "bg-black bg-opacity-50"
      }`}
    >
      <div className="animate-fadeIn">
        <CardDialog {...props} />
      </div>
    </div>
  );
};
