import React from "react";
import { hatchlingBg, ASSETS } from "../../../../const/asset.const";
import { Faction } from "../../../../const/faction.enum";
import { HatchlingDeployed } from "../../../../interfaces/item.interface";
import { useAutoFarmContext } from "../../../../providers/AutoFarmProvider";

function DeployBlock({
  iconUrl,
  faction,
  onClick,
}: {
  iconUrl?: string;
  faction?: Faction;
  onClick?: () => void;
}) {
  let bgStyle = {
    border: "2.484px solid #DDDDDC",
    borderRadius: "992.596px",
    background: "#E4E4E7",
    boxShadow: "2px 3px 8px 0px rgba(0, 0, 0, 0.20)",
  };

  const factionBackground = React.useMemo(
    () => hatchlingBg(faction),
    [faction]
  );

  if (iconUrl) {
    bgStyle = {
      border: "2px #7D411C",
      borderRadius: "992.596px",
      background: factionBackground.radialGradient!,
      boxShadow: "2px 3px 8px 0px rgba(0, 0, 0, 0.20)",
    };
  }

  return (
    <div
      className={`${iconUrl ? "p-[1px]" : "p-[2px]"} m-auto cursor-pointer`}
      style={{
        border: iconUrl ? "2px solid #EEE" : "2px dashed #EEE",
        borderRadius: "100%",
      }}
      onClick={onClick}
    >
      <div
        className="flex w-[50px] h-[50px] p-[9px] justify-center items-center"
        style={bgStyle}
      >
        <img
          width={32}
          src={iconUrl ? iconUrl : ASSETS.UNDEPLOYED_HATCHLING_BOX}
          alt="deploy-block"
        />
      </div>
    </div>
  );
}

export function InfoBlock({
  onDeploClicked,
}: {
  onDeploClicked?: () => void;
  items: HatchlingDeployed[];
}) {
  const { items, totalPassiveIncome } = useAutoFarmContext();

  return (
    <div className="absolute flex flex-col gap-2 items-center bottom-[135px] w-full">
      <div
        style={{
          display: "flex",
          width: "200px",
          // padding: "4px 10px",
          justifyContent: "center",
          alignItems: "center",
          gap: "4px",
          borderRadius: "12px",
          border: "2px solid #22171A)",
          background: " #FFF8DB",
        }}
      >
        <div
          className="w-full flex gap-1 justify-center items-center"
          style={{
            borderBottom: "3px solid #8B6D75",
            borderRadius: "10px",
            overflow: "hidden",
            padding: "4px 10px",
          }}
        >
          <img src={ASSETS.COIN} width={20} height={20} alt="coin" />
          <p
            style={{
              fontSize: "12px",
              fontWeight: "800",
              color: "#714C56",
            }}
          >
            {totalPassiveIncome.toLocaleString()}/min
          </p>
        </div>
      </div>
      <div className="flex gap-1">
        {items.map((item) => (
          <DeployBlock
            key={item.id}
            iconUrl={item.asset_url}
            faction={item.faction}
            onClick={onDeploClicked}
          />
        ))}
        {Array.from(Array(5 - items.length)).map((_, index) => (
          <DeployBlock key={index} onClick={onDeploClicked} />
        ))}
      </div>
    </div>
  );
}
