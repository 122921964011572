// Define the TimeType enum equivalent to Go's string type alias
export enum TimeType {
  All = "all",
  Weekly = "weekly",
}

// Interface representing a user's rank response
export interface UserRankResponse {
  user_id: number; // TypeScript's `number` is used for Go's `uint`
  username: string;
  name: string;
  rank: number;
  total_ambers: number;
  level: string;
  profile_picture: string;
}

// Interface for rank response containing lists of users
export interface RankResponse {
  top_3_list: UserRankResponse[];
  rank_list: UserRankResponse[];
}

// Interface for my rank response, containing a single user rank and additional information
export interface MyRankResponse {
  my_rank: UserRankResponse;
  earn_per_minute: number; // TypeScript's `number` covers both `int32` and `float`
  last_update: number;
}
