import { Typography } from "./Typography/Typography";

const Style = {
  Typography,
};

export enum Theme {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  SUCCESS = "success",
  ERROR = "error",
}

export default Style;
