interface ShopHeadingI {
    text: string;
}

export default function ShopHeading(props: ShopHeadingI) {
    const { text } = props;
    return (
        <div
            className="w-full px-3 py-2 flex items-center justify-center rounded-xl"
            style={{
                backgroundColor: "#EBD0A5",
                border: "1.8px solid #C69954",
            }}
        >
            <p className="text-lg font-extrabold" style={{ color: "#714C56" }}>
                {text}
            </p>
        </div>
    );
}
