import { useState } from "react";
import { BbirdButton } from "../../../components/BbirdButton";
import { BbirdButtonTheme } from "../../../const/button.const";
import QuestDrawer from "./drawer";

interface AchievementItemI {
  imageUrl: string;
  name: string;
  reward: string;
  type: string;
  id: string | number;
  description: string;
  status?: "open" | "completed" | "ready_to_claim";

  onClick: (x: any) => Promise<any>;

  borderColor?: string;
  backgroundColor?: string;
}

export default function AchievementItem(props: AchievementItemI) {
  const {
    imageUrl,
    name,
    reward,
    type,
    status,
    borderColor = "#EBD0A5",
    backgroundColor = "#FEEBCD",
  } = props;

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  return (
    <div>
      <div
        className="w-full flex items-center py-[10px] px-4 gap-[10px] relative"
        style={{
          border: `2px solid ${borderColor}`,
          borderRadius: "10px",
          backgroundColor: backgroundColor,
        }}
        onClick={() => {
          if (type === "rewards" || type === "tasks") {
            setDrawerOpen(true);
          }
        }}
      >
        <img src={imageUrl} alt={name} width={30} height={30} />
        <div className="flex-1 flex items-center justify-between gap-1">
          <div className="flex flex-col gap-1 flex-1">
            <p className="text-sm font-extrabold">{name}</p>
            <div className="flex items-center gap-1">
              <img
                src="amber_icon.png"
                alt="amber icon"
                width={15}
                height={15}
              />
              <p className="text-xs font-extrabold">{reward}</p>
            </div>
          </div>
        </div>
        {type === "rewards" && (
          <BbirdButton
            theme={BbirdButtonTheme.SUCCESS}
            width="82px"
            onClick={() => {
              setDrawerOpen(true);
            }}
          >
            <p
              className="text-center text-white font-bold text-stroke"
              style={{ fontSize: "13px" }}
            >
              Claim
            </p>
          </BbirdButton>
        )}
        {type === "tasks" && (
          <img
            src="cheveron_right_icon.png"
            alt="chevron right icon"
            width={20}
            className="cursor-pointer"
            onClick={() => {
              setDrawerOpen(true);
            }}
          />
        )}
        {type === "finished" && (
          <>
            <img
              src="quest_finished_task.png"
              alt="finished task icon"
              width={40}
            />
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgba(216, 181, 124, 0.5)",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "10px",
              }}
            ></div>
          </>
        )}
      </div>
      <QuestDrawer
        open={drawerOpen}
        setOpen={(x) => setDrawerOpen(false)}
        id={props.id.toString()}
        title={props.name}
        description={props.description}
        reward={props.reward}
        onConfirm={() => props.onClick(props.id)}
        questImageUrl={imageUrl}
        buttonEnabled={status === "ready_to_claim"}
        alert={status === "ready_to_claim" ? "success" : "error"}
        buttonText={
          status !== "ready_to_claim" ? "Not yet ready to claim" : undefined
        }
      />
    </div>
  );
}
