import { BbirdButton } from "../../../components/BbirdButton";
import { BbirdButtonTheme } from "../../../const/button.const";

interface ShopCardI {
    outerBorderColor: string;
    innerBorderColor: string;
    bgColor: string;
    text: string;
    imgUrl: string;
    quantity: string;
    price: string;
    discount?: string;
    type: string;
}

export default function ShopCard(props: ShopCardI) {
    const {
        outerBorderColor,
        innerBorderColor,
        bgColor,
        text,
        imgUrl,
        quantity,
        price,
        discount,
        type,
    } = props;
    return (
        <div
            className="w-full rounded-xl relative"
            style={{
                borderWidth: "1px 1px 4px 1px",
                borderColor: outerBorderColor,
                borderStyle: "solid",
            }}
        >
            {discount && (
                <div className="absolute top-[-1.9px] right-4 w-[42px] h-[44.25px] flex items-center justify-center">
                    <img
                        src="/discount_badge.png"
                        alt="Discount badge"
                        className="absolute top-0 left-0 z-10 w-full"
                    />
                    <p
                        className="relative text-xs font-extrabold text-white z-20 text-center"
                        style={{
                            width: "27px",
                            textShadow:
                                "-0.8px 0 #CA1E2A, 0 0.8px #CA1E2A, 0.8px 0 #CA1E2A, 0 -0.8px #CA1E2A",
                        }}
                    >
                        60% OFF
                    </p>
                </div>
            )}
            <div
                className="w-full flex flex-col gap-3 p-4 rounded-lg"
                style={{
                    border: `4px solid ${innerBorderColor}`,
                    background: bgColor,
                }}
            >
                <p
                    className="text-base font-extrabold text-white"
                    style={{
                        textShadow: `-1.3px 0 ${outerBorderColor}, 0 1.3px ${outerBorderColor}, 1.3px 0 ${outerBorderColor}, 0 -1.3px ${outerBorderColor}`,
                    }}
                >
                    {text}
                </p>
                <div className="flex justify-between items-end">
                    <div
                        className="flex flex-col items-center justify-center p-[2px] rounded-md"
                        style={{ backgroundColor: "#353D54" }}
                    >
                        <div
                            className="w-[56px] h-[48px] rounded-md flex items-center justify-center"
                            style={{ backgroundColor: "#FFFBE9" }}
                        >
                            <img src={imgUrl} alt="" width={32} />
                        </div>
                        <p
                            className="text-[10px] font-extrabold text-white"
                            style={{
                                textShadow:
                                    "-1px 0 #442E34, 0 1px #442E34, 1px 0 #442E34, 0 -1px #442E34",
                            }}
                        >
                            {quantity}
                        </p>
                    </div>
                    <BbirdButton theme={BbirdButtonTheme.PRIMARY}>
                        <div className="flex items-center gap-1">
                            <img
                                src={
                                    type === "coins"
                                        ? "/eggcoin_icon.png"
                                        : "/amber_icon.png"
                                }
                                alt="Coins"
                                width={20}
                            />
                            <p
                                className="text-center text-white font-bold text-stroke"
                                style={{
                                    fontSize: "14px",
                                    textShadow:
                                        "-1.3px 0 #D97706, 0 1.3px #D97706, 1.3px 0 #D97706, 0 -1.3px #D97706",
                                }}
                            >
                                {price}
                            </p>
                        </div>
                    </BbirdButton>
                </div>
            </div>
        </div>
    );
}
