import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "react-loading-skeleton/dist/skeleton.css";
import App from "./App";
import { WebAppProvider } from "@vkruglikov/react-telegram-web-app";
import AuthProvider from "./providers/AuthProvider";
import AuthMiddleware from "./middleware/AuthMiddleware";
import GameProvider from "./providers/GameProvider";
import PageProvider from "./providers/PageProvider";
import { AlertProvider } from "./components/alert/AlertProvider";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <WebAppProvider>
      <AlertProvider>
        <PageProvider>
          <AuthProvider>
            <GameProvider>
              <AuthMiddleware>
                <App />
              </AuthMiddleware>
            </GameProvider>
          </AuthProvider>
        </PageProvider>
      </AlertProvider>
    </WebAppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
