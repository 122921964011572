interface ShopEggItemI {
  title: string;
  discount?: string;
  imageUrl: string;
  price: string;
  quantity: number;
}

export default function ShopEggItem(props: ShopEggItemI) {
  const { title, discount, imageUrl, price, quantity } = props;
  return (
    <div
      className="relative p-1 rounded-xl flex flex-col"
      style={{ backgroundColor: "#F59E0B", border: "1px solid #92400E" }}
    >
      {discount && (
        <>
          <img
            src="/rectangle.png"
            alt="rectangle discount"
            width={62}
            className="absolute top-0 right-0"
          />
          <span
            className="text-[10px] text-white font-extrabold absolute top-[12px] right-0"
            style={{
              transform: "rotate(37.66deg)",
              textShadow:
                "-1px 0 #CA1E2A, 0 1px #CA1E2A, 1px 0 #CA1E2A, 0 -1px #CA1E2A",
            }}
          >
            {discount}
          </span>
        </>
      )}
      <div
        className="w-full p-2 pt-8 flex flex-col gap-1 items-center justify-center rounded-t-[10px]"
        style={{ backgroundColor: "#FFFBE9" }}
      >
        <div className="relative" style={{ height: "60px" }}>
          <img
            src={imageUrl}
            alt=""
            style={{
              height: "60px",
              width: "max-content",
              objectFit: "contain",
            }}
          />
          <p
            className="text-xs font-extrabold text-white absolute right-0 bottom-0"
            style={{
              textShadow:
                "-1px 0 #442E34, 0 1px #442E34, 1px 0 #442E34, 0 -1px #442E34",
            }}
          >
            x{quantity}
          </p>
        </div>
        <p
          className="text-[10px] font-extrabold text-center"
          style={{ color: "#5A3D45" }}
        >
          {title}
        </p>
      </div>
      <div className="px-3 pt-[5px] pb-1 flex gap-1 items-center justify-center">
        <img src="/eggcoin_icon.png" alt="Coins" width={20} />
        <p className="text-sm font-extrabold" style={{ color: "#2D1E22" }}>
          {price}
        </p>
      </div>
    </div>
  );
}
