import { useEffect, useState } from "react";

export default function QuestCarousel() {
  const [centeredItemId, setCenteredItemId] = useState("item-1");

  useEffect(() => {
    const timer = setTimeout(() => {
      const observerOptions = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setCenteredItemId(entry.target.id);
          }
        });
      }, observerOptions);

      document
        .querySelectorAll(".carousel-item")
        .forEach((item) => observer.observe(item));

      return () => {
        document
          .querySelectorAll(".carousel-item")
          .forEach((item) => observer.unobserve(item));
      };
    }, 1000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-full">
      <div className="carousel carousel-center rounded-box max-w-md px-4 py-2">
        {Array.from({ length: 3 }).map((_, idx) => (
          <div
            id={`item-${idx + 1}`}
            className="carousel-item w-full"
            key={idx}
          >
            <img src={`banner_quest_${idx + 1}.png`} className="rounded-box" />
          </div>
        ))}
      </div>
      <div className="w-full flex justify-center">
        <div className="w-[70%] grid grid-cols-3 gap-2">
          {Array.from({ length: 3 }).map((_, idx) =>
            centeredItemId === `item-${idx + 1}` ? (
              <div
                className="h-[5px]"
                style={{
                  border: "0.5px solid rgb(175, 64, 58)",
                  backgroundColor: "rgb(255, 135, 129)",
                  borderRadius: "10px",
                  boxShadow: "0 1.2px 0 0 rgba(215,85,78,1)",
                }}
                key={idx}
              ></div>
            ) : (
              <div
                className="h-[5px]"
                style={{
                  border: "0.5px solid rgba(217, 119, 6, 1)",
                  backgroundColor: "rgba(254, 243, 199, 1)",
                  borderRadius: "10px",
                  boxShadow: "0 1.2px 0 0 rgba(239, 161, 72, 1)",
                }}
                key={idx}
              ></div>
            )
          )}
        </div>
      </div>
    </div>
  );
}
