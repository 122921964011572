import React from "react";
import axios from "axios";
import { remember } from "../utils/helper";
import { useAuth } from "../providers/AuthProvider";

export function useProfilePicture() {
  const [profile, setProfile] = React.useState<string>("");
  const { user } = useAuth();

  React.useEffect(() => {
    async function loadProfilePicture() {
      const profile = await remember(
        "profile",
        1000 * 60 * 60 * 24, // 24 hours
        async () => {
          let profile = `https://ui-avatars.com/api/?name=${user?.first_name} ${user?.last_name}&?background=random`;
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND_URL}/profile-picture`
            );
            profile = `data:image/jpeg;base64,${response.data.data}`;
          } catch (error) {
            console.warn("Failed to load profile picture", error);
          }
          return profile;
        }
      );
      setProfile(profile);
    }

    loadProfilePicture();
  }, [user]);

  return profile;
}
