import axios from "axios";
import React from "react";
import {} from "../"
import { Level } from "../providers/AuthProvider";

export function useLevel() {
  const [levels, setLevels] = React.useState<Level[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const nextLevel = (currentLevel: number) => {
    return levels.find((level) => level.level === currentLevel + 1);
  };

  React.useEffect(() => {
    // Load levels
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/level`).then((res) => {
      setLevels(res.data.data);
    });
  }, []);

  /**
   * Upgrade to the next level
   *
   * @param level The current level
   * @returns The new level
   */
  const upgrade = async (level: Level): Promise<Level> => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/level/upgrade`,
        {
          level: level.level,
        }
      );

      return response.data.data.newLevel as Level;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return { levels, nextLevel, upgrade, loading };
}
