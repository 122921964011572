import { FunctionComponent, useEffect, useState } from "react";
import { BbirdButton } from "../../../components/BbirdButton";
import { BbirdButtonTheme } from "../../../const/button.const";
import { AlertTheme } from "../../../const/alert.const";
import { usePage } from "../../../providers/PageProvider";
import axios from "axios";
import { AppContext } from "../../../const/state.const";

const FriendCodeSection: FunctionComponent = () => {
  const [friendCode, setFriendCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { query, setQuery, insertOrUpdateContext, removeContext, getContext } =
    usePage();

  useEffect(() => {
    if (query?.refcode) {
      setFriendCode(query.refcode);
      setQuery({ refcode: null });
    }
  }, []);

  const addFriendOnClick = async () => {
    setLoading(true);
    try {
      if (friendCode === "HARBOURFRONT") {
        if (getContext(AppContext.SHOW_SHOP_BTN)) {
          removeContext(AppContext.SHOW_SHOP_BTN);
          //@ts-ignore
          alert("SHOP CLOSED!", AlertTheme.ERROR);
          return;
        }

        insertOrUpdateContext(AppContext.SHOW_SHOP_BTN, true);
        //@ts-ignore
        alert("SHOP OPENED!", AlertTheme.SUCCESS);
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/friends/add`,
        {
          referral_code: friendCode,
        }
      );
      if (response.status === 200) {
        //@ts-ignore
        alert("Success add friend code!", AlertTheme.SUCCESS);
        return;
      }
    } catch (e) {
      //@ts-ignore
      alert("Invalid Friend Code", AlertTheme.ERROR);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="w-full flex flex-row items-center justify-center gap-2">
      <div className="w-full grid grid-cols-3 w-full gap-2">
        <div className="col-span-2">
          <input
            className="w-full bg-[#F7F8FA] w-full p-2 border-t-2 border-blue-200 rounded-lg shadow-sm font-semibold text-blue-300"
            id="referral"
            type="text"
            placeholder="Friend code"
            value={friendCode}
            onChange={(e) => setFriendCode(e.target.value.toUpperCase())}
          />
        </div>
        <div className="col-span-1 flex justify-center items-center h-full">
          <BbirdButton
            theme={BbirdButtonTheme.PRIMARY}
            width="100%"
            onClick={() => {
              addFriendOnClick();
            }}
            disabled={loading}
          >
            <p
              className="text-center text-white font-bold text-stroke"
              style={{ fontSize: "13px" }}
            >
              {loading ? "Loading..." : "Add Friend"}
            </p>
          </BbirdButton>
        </div>
      </div>
    </form>
  );
};

export default FriendCodeSection;
