import { FunctionComponent } from "react";

const BannerSection: FunctionComponent = () => {
  return (
    <div className="w-full relative flex flex-row items-center justify-between text-left text-base text-color-secondary-blue-b-800 font-label-small-medium">
      <div className="w-[236px] flex flex-col items-start justify-start gap-2.5">
        <div className="self-stretch relative leading-[18px] font-extrabold">
          Invite Friends to Earn Blast Eggs! Get Your Code Now!
        </div>
        <div
          className="self-stretch relative leading-[14px] font-medium text-color-secondary-blue-b-700"
          style={{
            fontSize: "12px",
          }}
        >
          Share your Friend Code to earn 5 extra Blast Eggs whitelist. Each code
          is valid up to 20 uses. Start sharing now!
        </div>
      </div>
      <img
        className="w-[91px] relative h-[88px] object-cover"
        src="highfive_nobg.png"
      />
    </div>
  );
};

export default BannerSection;
