import React from "react";
import { HatchlingDeployed } from "../interfaces/item.interface";
import axios from "axios";

type UseAutoFarmI = {
  items: HatchlingDeployed[];
  loading: boolean;
  deploy: (hatchlingIds: number[]) => void;
};

export function useAutoFarm(): UseAutoFarmI {
  const [items, setItems] = React.useState<HatchlingDeployed[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    // Fetch items
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/auto-farm`)
      .then((data) => {
        if (data.data.data) {
          setItems(data.data.data || []);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const deploy = async (hatchlingIds: number[]) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auto-farm/deploy`,
        {
          item_ids: hatchlingIds,
        }
      );

      setItems(response.data.data || []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    items,
    loading,
    deploy,
  };
}
