import React from "react";
import { useEggs } from "../../../hooks/useEgg";
import { Item } from "../../../interfaces/item.interface";
import { BbirdButton } from "../../../components/BbirdButton";
import { BbirdButtonTheme } from "../../../const/button.const";
import Skeleton from "react-loading-skeleton";
import Style, { Theme } from "../../../components/Style";
import { CardDialog } from "../../../components/dialog/cardDialog";
import { Dialog } from "../../../components/dialog/dialog";
import { useGame } from "../../../providers/GameProvider";
import { useAuth } from "../../../providers/AuthProvider";
import { useLevel } from "../../../hooks/useLevel";
import { ASSETS, hatchlingBg } from "../../../const/asset.const";
import { Modal } from "../../../components/Modal/Modal";
import { useHatch } from "../../../hooks/useHatch";

export const PickEggModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { eggs, loading, pickEgg } = useEggs();
  const [selectedEgg, setSelectedEgg] = React.useState<number | null>(null);
  const [selectedUserItem, setSelectedUserItem] = React.useState<number | null>(
    null
  );
  const { setActiveEgg } = useGame();

  const onEggSelected = (eggId: number, userItemId: number) => {
    setSelectedEgg(eggId);
    setSelectedUserItem(userItemId);
  };

  // render not found if no eggs
  const renderNoEggs = () => {
    return (
      <div className="flex flex-col text-center gap-4">
        <div className="px-5 my-5">
          <Style.Typography
            variant="h5"
            className="font-extrabold text-[#4E6D9B] mb-6"
          >
            You have run out of eggs :(
          </Style.Typography>
          <Style.Typography
            variant="label"
            className="text-[#4E6D9B] font-normal"
          >
            Refer friends to get free eggs! Or buy them from the shop using
            Ambers!
          </Style.Typography>
        </div>

        <div className="flex gap-2">
          <BbirdButton
            theme={BbirdButtonTheme.PRIMARY}
            onClick={() => alert("Should go to refer friends page")}
          >
            <p
              className="text-center text-white font-bold text-stroke"
              style={{ fontSize: "14px" }}
            >
              Refer Friends
            </p>
          </BbirdButton>
          <BbirdButton
            theme={BbirdButtonTheme.SECONDARY}
            onClick={() => alert("Should go to Shop page")}
          >
            <p
              className="text-center text-white font-bold text-stroke"
              style={{ fontSize: "14px" }}
            >
              Shop Now
            </p>
          </BbirdButton>
        </div>
      </div>
    );
  };

  const renderEggsList = () => {
    return (
      <>
        {loading ? (
          <Skeleton height={40} width={300} count={6} />
        ) : (
          <div>
            {eggs.map((eggs) => {
              return (
                <EggListItem
                  key={eggs.id}
                  egg={eggs}
                  onEggSelected={onEggSelected}
                  selectedEgg={selectedEgg}
                />
              );
            })}
          </div>
        )}
        <BbirdButton
          theme={BbirdButtonTheme.PRIMARY}
          onClick={async () => {
            try {
              const response = await pickEgg(Number(selectedUserItem));
              const activeEgg = response?.data.data.active_egg;
              setActiveEgg(activeEgg);
              setSelectedEgg(null);
            } catch (e: any) {
              alert("Failed to choose an egg");
            } finally {
              onClose();
            }
          }}
          disabled={loading}
        >
          {" "}
          <p
            className="text-center text-white font-bold text-stroke"
            style={{ fontSize: "14px" }}
          >
            {loading ? "Loading..." : "Pick Egg"}
          </p>
        </BbirdButton>
      </>
    );
  };

  return (
    <Dialog
      open={isOpen}
      title="Pick an Egg!"
      background="#6288C2"
      innerBackground="#DAE6F6"
      titleClass="text-stroke-secondary font-bold pb-1"
      onClose={onClose}
    >
      <div className="flex flex-col items-center">
        {eggs.length > 0 ? renderEggsList() : renderNoEggs()}
      </div>
    </Dialog>
  );
};

const EggListItem = ({
  egg,
  onEggSelected,
  selectedEgg,
}: {
  egg: Item;
  onEggSelected: Function;
  selectedEgg?: number | null;
}) => {
  const selectedClass = `border-2 border-gray-500 bg-[#b3ccf2]`;
  return (
    <div
      className={`flex align-middle items-center ss gap-1 mb-2 bg-[#F8F9FB] rounded-md border-2 py-2 pr-4 cursor-pointer ${
        selectedEgg === egg.id ? selectedClass : "border-[#C0CFE7]"
      } `}
      onClick={() => {
        if (egg.ids.length > 0) {
          onEggSelected(egg.id, egg.ids[0]);
        }
      }}
    >
      <div
        className={`relative px-2 ${
          selectedEgg === egg.id ? "grayscale" : null
        }`}
      >
        <img src={egg.assets_url} alt={egg.name} width={30}></img>
        <span className="absolute bottom-0 font-extrabold text-stroke right-3 text-[12px] text-[#F8F9FB]">
          {egg.count_total}
        </span>
      </div>
      <div>
        <p className="text-[12px] font-extrabold text-[#3B5274]">{egg.name}</p>
        <p className="text-[10px] text-[#4E6D9B]">
          Hatches into 1 x Hatchling from a {egg.properties.faction} Faction
        </p>
      </div>
    </div>
  );
};

export const LevelUpModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { user, setUser } = useAuth();
  const { nextLevel, loading, upgrade } = useLevel();
  const { energy, setEnergy } = useGame();

  const theNext = nextLevel(user?.level.level ?? 1);
  const canUpgrade = energy?.totalCoin! >= theNext?.upgrade_price! && !loading;

  // render not found if no eggs
  const render = () => {
    return (
      <div
        className="flex flex-col text-center gap-4"
        style={{
          minWidth: "300px",
        }}
      >
        <div className="relative">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="32"
              viewBox="0 0 316 32"
              fill="none"
            >
              <path
                d="M1.16504 2.48528C0.319541 1.51472 1.00887 0 2.29607 0H313.704C314.991 0 315.68 1.51472 314.835 2.48529L303.634 15.3431C303.306 15.7196 303.306 16.2804 303.634 16.6569L314.835 29.5147C315.68 30.4853 314.991 32 313.704 32H2.29605C1.00886 32 0.319542 30.4853 1.16504 29.5147L12.3661 16.6569C12.6941 16.2804 12.6941 15.7196 12.3661 15.3431L1.16504 2.48528Z"
                fill="#353D54"
              />
            </svg>
          </div>
          <div className="flex items-center justify-center gap-2 absolute top-0 mt-1 text-white font-extrabold right-0 left-0 bottom-0">
            <p className="">Lv.{user?.level.level}</p>
            {user?.level.level !== 100 && (
              <>
                <img src="/arrow-right.png" width={25} alt="Target" />
                <p className="">Lv.{theNext?.level}</p>
              </>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex flex-row justify-between">
            <div className="flex gap-1 items-center justify-center align-middle">
              <img src="/up.png" width={25} alt="Target" />
              <p className="font-extrabold text-[#3B5274]">Level</p>
            </div>
            <div className="flex items-center gap-1">
              {user?.level.level !== 100 ? (
                <>
                  <p className="font-extrabold text-[#3B5274]">
                    lv.{theNext?.level || <Skeleton />}
                  </p>
                  <span className="text-sm font-extrabold text-green-500">
                    +{theNext?.level! - user?.level.level!}
                  </span>
                </>
              ) : (
                <span className="text-sm font-extrabold text-green-500">
                  MAX
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex gap-1 items-center justify-center align-middle">
              <img src="/tap.png" width={25} alt="Target" />
              <p className="font-extrabold text-[#3B5274]">BBCoins Per Tap</p>
            </div>
            <div className="flex items-center gap-1">
              {user?.level.level !== 100 ? (
                <>
                  <p className="font-extrabold text-[#3B5274]">
                    {theNext?.earn_per_tap || <Skeleton />}
                  </p>
                  <span className="text-sm font-extrabold text-green-500">
                    +{theNext?.earn_per_tap! - user?.level.earn_per_tap!}
                  </span>
                </>
              ) : (
                <span className="text-sm font-extrabold text-green-500">
                  MAX
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex gap-1 items-center justify-center align-middle">
              <img src="/power.png" width={25} alt="Target" />
              <p className="font-extrabold text-[#3B5274]">Max Energy</p>
            </div>
            <div className="flex items-center gap-1">
              {user?.level.level !== 100 ? (
                <>
                  <p className="font-extrabold text-[#3B5274]">
                    {theNext?.max_tap || <Skeleton />}
                  </p>
                  <span className="text-sm font-extrabold text-green-500">
                    +{theNext?.max_tap! - user?.level.max_tap!}
                  </span>
                </>
              ) : (
                <span className="text-sm font-extrabold text-green-500">
                  MAX
                </span>
              )}
            </div>
          </div>
        </div>
        {user?.level.level !== 100 && (
          <div className="flex flex-col gap-1 font-bold items-center align-middle justify-center">
            <BbirdButton
              theme={BbirdButtonTheme.PRIMARY}
              disabled={!canUpgrade}
              onClick={async () => {
                try {
                  const nextLevel = await upgrade(theNext!);

                  // Update level in the user object
                  setUser({
                    ...user!,
                    level: nextLevel,
                    coin_amount: user?.coin_amount! - theNext?.upgrade_price!,
                  });

                  // update the energy with the new level
                  setEnergy({
                    ...energy!,
                    maxEnergy: nextLevel.max_tap,
                    energyPerTap: nextLevel.earn_per_tap,
                    recoveryRatePerSec: nextLevel.tap_recovered,
                    totalCoin: energy?.totalCoin! - theNext?.upgrade_price!,
                  });

                  // Close the modal
                  onClose();
                } catch (e: any) {
                  // got an error, show on the frontend
                  console.error(e);
                  alert("Failed to upgrade level");
                }
              }}
            >
              <div className="flex gap-1">
                <img src="/Icons_BBCoin.svg" width={20} alt="BBCoins" />
                <p
                  className="text-center text-white font-bold text-stroke"
                  style={{ fontSize: "14px" }}
                >
                  {loading
                    ? "Loading..."
                    : theNext?.upgrade_price.toLocaleString()}
                </p>
              </div>
            </BbirdButton>
            {!canUpgrade && !loading && (
              <p className="text-red-500 text-xs">Insufficent BBCoins</p>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <Dialog
      open={isOpen}
      title="Level Up"
      background="#6288C2"
      innerBackground="#DAE6F6"
      titleClass="text-stroke-secondary font-bold pb-1"
      onClose={onClose}
    >
      <div className="flex flex-col items-center">{render()}</div>
    </Dialog>
  );
};

export const TooltipModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const renderCurrencies = () => {
    return (
      <div className="flex flex-col text-left gap-4" style={{ zIndex: 20 }}>
        <div className="px-3 my-1">
          <div className="flex gap-2 justify-start items-center mb-2">
            <img
              src="/eggcoin_icon.png"
              alt="coin"
              style={{
                maxWidth: "25px",
                maxHeight: "25px",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
            <Style.Typography
              variant="h6"
              className="font-extrabold text-[#5A3D45]"
            >
              BBCoins
            </Style.Typography>
          </div>
          <Style.Typography
            variant="label"
            className="text-[#5A3D45] font-normal"
          >
            Main Currency of Blastbirds Adventures. Equivalent of Gold and used
            for upgrades and purchasing resources & items from the BBCoins shop.
          </Style.Typography>
        </div>

        <div className="px-3 my-1">
          <div className="flex gap-2 justify-start items-center mb-2">
            <img
              src="/amber_icon.png"
              alt="amber"
              style={{
                maxWidth: "25px",
                maxHeight: "25px",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
            <Style.Typography
              variant="h6"
              className="font-extrabold text-[#5A3D45]"
            >
              Ambers
            </Style.Typography>
          </div>
          <Style.Typography
            variant="label"
            className="text-[#5A3D45] font-normal"
          >
            Premium Currency of Blastbirds Adventures. Equivalent of Gems and
            used for accessing premium features or for progressing quicker.
          </Style.Typography>
        </div>

        <div className="px-3 my-1">
          <div className="flex gap-2 justify-start items-center mb-2">
            <img
              src="/ton_icon.png"
              alt="ton"
              style={{
                maxWidth: "25px",
                maxHeight: "25px",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
            <Style.Typography
              variant="h6"
              className="font-extrabold text-[#5A3D45]"
            >
              $TON Tokens
            </Style.Typography>
          </div>
          <Style.Typography
            variant="label"
            className="text-[#5A3D45] font-normal"
          >
            Main Crypto Currency used for Blastbirds Adventures. $TON is the
            native token of The Open Network by Telegram.
          </Style.Typography>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={isOpen}
      title="Currencies"
      background="#6288C2"
      innerBackground="#DAE6F6"
      titleClass="text-stroke-secondary font-bold pb-1"
      onClose={onClose}
    >
      <div className="flex flex-col items-center">{renderCurrencies()}</div>
    </Dialog>
  );
};

export const HatchlingAcquiredModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [step, setStep] = React.useState<"hatch" | "reward">("hatch");
  const { hatch, setActiveEgg, setHatch } = useGame();

  const { bgGradient, border } = hatchlingBg(hatch?.faction);

  React.useEffect(() => {
    if (isOpen) {
      setStep("hatch");
    }
  }, [isOpen]);

  const handleTap = () => {
    if (step === "hatch") {
      setStep("reward");
    } else {
      onClose();
      setActiveEgg(null);
      setHatch(null);
    }
  };

  const renderEgg = () => {
    return (
      <div
        className="h-screen w-screen flex items-center justify-center relative"
        style={{
          backgroundImage: "url('/light-rays.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundBlendMode: "lighten",
          flexShrink: 0,
          position: "absolute",
          top: -60,
          left: 0,
        }}
        onClick={handleTap}
      >
        <div className="flex flex-col">
          <img
            src={ASSETS.EGG_HATCHING}
            alt="Egg Hatching"
            className="relative"
          />
        </div>
        <Style.Typography
          variant="label"
          className="text-white font-bold text-center absolute bottom-3.5"
        >
          Tap Anywhere to Skip
        </Style.Typography>
      </div>
    );
  };

  const renderReward = () => {
    return (
      <div
        className="flex flex-col items-center justify-start gap-5"
        onClick={handleTap}
      >
        <img src={ASSETS.REWARD_RIBBON} alt="Reward" />
        <div
          className={`h-[50px] w-[50px] p-2 ${bgGradient} rounded-lg border-[2.5px] ${border} inline-flex justify-start items-center gap-2.5 relative`}
        >
          <div className="w-full h-full justify-center items-center flex relative">
            <img
              className="w-full h-full"
              src={hatch?.assets_url}
              alt="Hatchling"
            />
          </div>
          <div className="absolute bottom-0 right-0 text-[#FFFDF4] text-xs font-extrabold leading-[14px] p-1 break-words drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
            1
          </div>
        </div>
        <Style.Typography
          variant="label"
          className="text-white font-bold text-center mt-20"
        >
          Tap Anywhere to Exit
        </Style.Typography>
      </div>
    );
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="flex flex-col items-center">
        {" "}
        {step === "hatch" ? renderEgg() : renderReward()}
      </div>
    </Modal>
  );
};

export const NestModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { hatchlings, loading } = useHatch();

  // render not found if no hatchlings
  const renderNoHatchlings = () => {
    return (
      <div className="flex flex-col text-center gap-4">
        <div className="px-5 my-5">
          <Style.Typography
            variant="h5"
            className="font-extrabold text-[#4E6D9B] mb-6"
          >
            You don't have any hatchlings :(
          </Style.Typography>
        </div>
      </div>
    );
  };

  const renderHatchlingsList = () => {
    return (
      <>
        {loading ? (
          <Skeleton height={40} width={300} count={6} />
        ) : (
          <div className="max-h-[254px] overflow-y-auto">
            <div className="grid grid-cols-5 gap-1">
              {hatchlings.map((hatchling: any) => {
                const { bgGradient, border } = hatchlingBg(hatchling?.faction);
                return (
                  <div
                    className={`h-[50px] w-[50px] p-2 ${bgGradient} rounded-lg border-[2.5px] ${border} inline-flex justify-start items-center gap-2.5 relative`}
                    key={hatchling.id}
                  >
                    <img
                      className="w-full h-full"
                      src={hatchling?.assets_url}
                      alt="Hatchling"
                    />
                    <div className="absolute bottom-0 right-0 text-[#FFFDF4] text-[10px] font-extrabold leading-[14px] p-1 break-words drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                      Lvl.{hatchling.level}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <Dialog
      open={isOpen}
      title="Nest"
      background="#6288C2"
      innerBackground="#DAE6F6"
      titleClass="text-stroke-secondary font-bold pb-1"
      onClose={onClose}
    >
      <div className="flex flex-col items-center">
        {hatchlings.length > 0 ? renderHatchlingsList() : renderNoHatchlings()}
      </div>
    </Dialog>
  );
};
