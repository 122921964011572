import { TELEGRAM_APP_URL } from "../../const/clicker.const";

export default function LandingPage() {
  return (
    <div className="App flex items-center justify-center min-h-screen bg-red-100">
      <div className="text-center">
        <p className="text-2xl font-bold text-red-500">
          This app must be opened in Telegram App.
        </p>
        <a href={TELEGRAM_APP_URL}>
          <div className="p-4 bg-red-200 rounded-lg hover:bg-red-300 hover:cursor-pointer">
            <p className="text-xl font-bold text-red-500">Open Telegram</p>
          </div>
        </a>
      </div>
    </div>
  );
}
