import { useState } from "react";
import { usePage } from "../../providers/PageProvider";
import UpperInformation from "../../components/upperInformation/upperInformation";
import LevelUpFooter from "./_components/levelUpFooter";
import LevelUpUpper from "./_components/levelUpUpper";
import { Hatch } from "../../interfaces/item.interface";

export default function LevelUpScreen() {
  const [charOne, setCharOne] = useState<Hatch | null>(null);
  const [charTwo, setCharTwo] = useState<Hatch | null>(null);

  return (
    <div>
      <UpperInformation
        showProfilePicture={false}
        showProgressBar={false}
        useMargin={true}
      />

      <LevelUpUpper
        charOne={charOne}
        charTwo={charTwo}
        setCharOne={setCharOne}
        setCharTwo={setCharTwo}
      />

      <div className="w-full absolute bottom-0 z-10">
        <LevelUpFooter
          charOne={charOne}
          charTwo={charTwo}
          setCharOne={setCharOne}
          setCharTwo={setCharTwo}
        />
      </div>
    </div>
  );
}
