import { useProfilePicture } from "../../../hooks/useProfilePicture";
import { useAuth } from "../../../providers/AuthProvider";
import { MyRankResponse } from "../data/rank.dto";

interface RankBannerI {
  user: MyRankResponse | null;
  isLoading: boolean;
}

export default function RankBanner(props: RankBannerI) {
  const { user: authUser } = useAuth();
  const profilePicture = useProfilePicture();
  const { isLoading, user } = props;

  return (
    <div
      className="flex flex-col w-full py-5 px-4 gap-5 relative"
      style={{
        borderRadius: "10px",
        backgroundColor: isLoading ? "#F4F3D4" : "#FEEFD7",
      }}
    >
      {!isLoading && (
        <img
          src="profile_banner_trophy.png"
          alt="Trophy"
          className="absolute b-0 r-0 z-10"
          style={{ width: "170px", bottom: 0, right: 0, zIndex: 1 }}
        />
      )}
      {isLoading ? (
        <>
          {/* Skeleton for Profile Picture and Name */}
          <div className="w-full flex items-center gap-4 relative z-20">
            <div className="w-[60px] h-[60px] bg-gray-300 rounded-full animate-pulse" />
            <div className="flex-1">
              <div className="w-1/2 h-6 bg-gray-300 rounded-md animate-pulse" />
            </div>
          </div>
          {/* Skeleton for Rank, Level, and Earning */}
          <div className="w-full flex items-center justify-between relative z-20 text-black">
            <div className="flex flex-col items-center gap-1">
              <div className="w-8 h-6 bg-gray-300 animate-pulse rounded-md" />
            </div>
            <div className="flex flex-col items-center gap-1">
              <div className="w-8 h-6 bg-gray-300 animate-pulse rounded-md" />
            </div>
            <div className="flex flex-col items-center gap-1">
              <div className="w-8 h-6 bg-gray-300 animate-pulse rounded-md" />
            </div>
          </div>
        </>
      ) : (
        <>
          {/* Actual Content */}
          <div className="w-full flex items-center gap-4 relative z-20">
            <img
              src={profilePicture}
              alt="Profile picture"
              width={60}
              height={60}
              className="rounded-full"
            />
            <h5 className="text-xl font-extrabold text-black">{`${authUser?.first_name} ${authUser?.last_name}`}</h5>
          </div>
          <div className="w-full flex items-center justify-between relative z-20 text-black">
            <div className="flex flex-col items-center gap-1">
              <h5 className="text-xl font-extrabold">{user?.my_rank.rank}</h5>
              <span className="text-sm">Your Rank</span>
            </div>
            <div className="flex flex-col items-center gap-1">
              <h5 className="text-xl font-extrabold">{user?.my_rank.level}</h5>
              <span className="text-sm">Your Level</span>
            </div>
            <div className="flex flex-col items-center gap-1">
              <h5 className="text-xl font-extrabold">
                {user?.earn_per_minute}
              </h5>
              <span className="text-sm">Earning/min</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
