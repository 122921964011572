import React, { useState } from "react";
import {
  BbirdButtonI,
  BbirdButtonThemeOptions,
  ButtonTheme,
} from "../const/button.const";

export function LtButton(props: BbirdButtonI) {
  const { onClick, children, disabled, width, theme, selected, className } =
    props;
  const [hover, setHover] = useState(false);

  const btnTheme: ButtonTheme =
    typeof theme === "string" ? BbirdButtonThemeOptions[theme] : theme;

  const getButtonStyles = () => {
    if (disabled) {
      return {
        topStrokeColor: btnTheme.topStrokeColor.disabled,
        mainColor: btnTheme.mainColor.disabled,
        bottomStrokeColor: btnTheme.bottomStrokeColor.disabled,
        lightningEffectColor: btnTheme.lightningEffectColor.disabled,
        borderColor: btnTheme.borderColor.disabled,
      };
    }
    return {
      topStrokeColor: btnTheme.topStrokeColor.default,
      mainColor: btnTheme.mainColor.default,
      bottomStrokeColor: btnTheme.bottomStrokeColor.default,
      lightningEffectColor: btnTheme.lightningEffectColor.default,
      borderColor: btnTheme.borderColor.default,
    };
  };

  const getHoverStyles = () => ({
    mainColor: btnTheme.mainColor.pressed,
    topStrokeColor: btnTheme.topStrokeColor.pressed,
    bottomStrokeColor: btnTheme.bottomStrokeColor.pressed,
    lightningEffectColor: btnTheme.lightningEffectColor.pressed,
    borderColor: btnTheme.borderColor.pressed,
  });

  const buttonStyles = getButtonStyles();
  const hoverStyles = getHoverStyles();
  const appliedStyles = hover || selected ? hoverStyles : buttonStyles;

  return (
    <div
      className={`w-12 overflow-hidden inline-block border-r border-${
        appliedStyles.borderColor
      } ${className} transition-transform transform duration-300 ease-in-out ${
        disabled
          ? "opacity-50 cursor-not-allowed"
          : "hover:scale-105 active:scale-95 cursor-pointer"
      }`}
      onClick={(e) => {
        if (onClick && !disabled) {
          onClick(e);
        }
      }}
      style={{
        borderColor: appliedStyles.borderColor,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseDown={() => setHover(false)}
      onTouchCancel={() => setHover(false)}
    >
      <div
        className={`h-12 w-12 bg-${appliedStyles.mainColor} border border-${appliedStyles.borderColor} rounded-lg -rotate-45 translate-x-[33px] shadow-md`}
        style={{
          backgroundColor: appliedStyles.mainColor,
          borderColor: appliedStyles.borderColor,
        }}
      />
    </div>
  );
}
