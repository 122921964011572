// Debug component
//
// This component will show the current state of the application
// turn on this debug component by adding this .env variable
// REACT_APP_DEBUG=true in your.env file

import React from "react";
import { useGame } from "../../../providers/GameProvider";

export const Debug = () => {
  const { activeEgg, energy } = useGame();

  if (process.env.REACT_APP_DEBUG !== "true") {
    return null;
  }

  return (
    <div className="z-50 text-xs absolute top-0 left-0 right-0 bottom-0 bg-gray-700 bg-opacity-75 text-white p-4 inline-table">
      App State Information :
      <div>
        <pre>
          Current Energy : {energy?.currentEnergy}/{energy?.maxEnergy}
        </pre>
        <pre>Current Coin : {energy?.totalCoin}</pre>
        <pre>Passive Income/s : {energy?.passiveIncomePerSec}</pre>
        <pre>
          Current Egg Tap : {activeEgg?.tap_count}/
          {activeEgg?.item?.tap_required_amount}
        </pre>
      </div>
    </div>
  );
};
