import { Dialog } from "../../../components/dialog/dialog";
import { Item, Hatch } from "../../../interfaces/item.interface";
import { hatchlingBg } from "../../../const/asset.const";

export const DetailsModal = ({
  isOpen,
  onClose,
  selected,
  item,
}: {
  isOpen: boolean;
  onClose: () => void;
  selected?: number | string | void;
  item?: any;
}) => {
  const renderResource = () => {
    const faction = item?.properties?.faction;
    const { bgGradient, border } = hatchlingBg(faction);

    return (
      <div className="flex justify-between items-start gap-2">
        <div className="flex justify-center items-center">
          <div
            className={`h-[60px] w-[60px] p-2 ${bgGradient} rounded-lg border-[2.5px] ${border} inline-flex justify-center items-center relative`}
          >
            <img className="w-full h-full" src={item?.assets_url} alt="Egg" />
            <div className="absolute bottom-0 right-0 text-[#FFFDF4] text-[10px] font-extrabold leading-[14px] p-1 break-words drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
              {item?.count_total}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-1.5 justify-center items-start px-2">
          <div className="text-[#353d54] text-sm font-extrabold">
            <span className="capitalize">{faction}</span> Faction
          </div>
          <span className="text-[#5d6476] text-xs font-medium">
            {item?.name}
          </span>
        </div>
      </div>
    );
  };

  const renderCharacter = () => {
    const faction = item?.faction;
    const { bgGradient, border } = hatchlingBg(faction);

    return (
      <div className="flex justify-between items-start gap-2">
        <div className="flex justify-center items-center">
          <div
            className={`h-[60px] w-[60px] p-2 ${bgGradient} rounded-lg border-[2.5px] ${border} inline-flex justify-center items-center relative`}
          >
            <img
              className="w-full h-full"
              src={item?.assets_url}
              alt="Hatchling"
            />
            <div className="absolute top-0 px-1  text-[#FFFDF4] text-[10px] font-extrabold leading-[14px] break-words drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
              Lvl.{item?.level}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-1.5 justify-center items-start px-2">
          <div className="text-[#353d54] text-sm font-extrabold">
            <span className="capitalize">{faction}</span> Faction
          </div>
          <span className="text-[#5d6476] text-xs font-medium">
            {item?.name}
          </span>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={isOpen}
      title={selected === 0 ? "Resource Details" : "Character Details"}
      background="#6288C2"
      innerBackground="#DAE6F6"
      titleClass="text-stroke-secondary font-bold pb-1"
      onClose={onClose}
    >
      <div className="flex flex-col items-center">
        {selected === 0 ? renderResource() : renderCharacter()}
      </div>
    </Dialog>
  );
};
