/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 frontend/public/egg.glb -t 
*/

import * as THREE from "three";
import React, { act } from "react";
import { useFrame, useGraph } from "@react-three/fiber";
import { useGLTF, useAnimations } from "@react-three/drei";
import { GLTF, SkeletonUtils } from "three-stdlib";

type ActionName =
  | "Egg_Crack"
  | "Egg_Idle"
  | "ExclMrk_Idle"
  | "ExclMrk_Appear"
  | "Aura_RotAnim";

interface GLTFAction extends THREE.AnimationClip {
  name: ActionName;
}

type GLTFResult = GLTF & {
  nodes: {
    Plane001: THREE.Mesh;
    Circle001: THREE.Mesh;
    Plane002: THREE.Mesh;
    EggInner: THREE.Mesh;
    Egg_AllCrack: THREE.Mesh;
    AxeAura002: THREE.Mesh;
    AxeAura003: THREE.Mesh;
    AxeAura005: THREE.Mesh;
    AxeAura006: THREE.Mesh;
    Plane003: THREE.Mesh;
    Bone: THREE.Bone;
  };
  materials: {
    ExclamationMark_Base: THREE.MeshStandardMaterial;
    ["Material.002"]: THREE.MeshStandardMaterial;
    Egg_InnerBlack: THREE.MeshStandardMaterial;
    Egg_BaseTest: THREE.MeshStandardMaterial;
    Egg_Aura: THREE.MeshStandardMaterial;
    Egg_Aura1: THREE.MeshStandardMaterial;
    Particles: THREE.MeshStandardMaterial;
  };
  animations: GLTFAction[];
};

type EggProps = JSX.IntrinsicElements["group"] & {
  progress: number;
};

export function Egg(props: EggProps) {
  const group = React.useRef<THREE.Group | null>(null);
  const { scene, animations } = useGLTF("/egg.glb") as GLTFResult;
  const clone = React.useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const { nodes, materials } = useGraph(clone) as GLTFResult;
  const { actions } = useAnimations(animations, group);
  const { progress } = props;

  React.useEffect(() => {
    if (actions.Egg_Idle) actions.Egg_Idle.play();
    if (actions.Aura_RotAnim) actions.Aura_RotAnim.play();
  }, [actions, nodes]);

  // Show excalamation mark after 90% progress
  React.useEffect(() => {
    if (progress >= 80) {
      // Play crack animation
      if (actions.Egg_Crack) actions.Egg_Crack.play();

      // Play the appear animation
      if (actions.ExclMrk_Appear) actions.ExclMrk_Appear.play();
    }
  }, [progress, actions]);

  useFrame((state, delta) => {
    if (nodes.Egg_AllCrack && nodes.Egg_AllCrack.morphTargetInfluences) {
      // console.log(nodes.Egg_AllCrack.morphTargetDictionary.Crack);
      // nodes.Egg_AllCrack.morphTargetDictionary.Crack = 1;
      nodes.Egg_AllCrack.morphTargetInfluences[0] = progress / 100;
    }
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="Egg_Bone"
          position={[0, 1.415, 0]}
          rotation={[0.006, -0.155, 0.037]}
        >
          <primitive object={nodes.Bone} />
        </group>
        <group name="Aura_Root" position={[0, -1.042, 0]} scale={5.114}>
          <mesh
            name="AxeAura002"
            geometry={nodes.AxeAura002.geometry}
            material={materials.Egg_Aura}
            position={[-0.012, 0.529, 0]}
            rotation={[0, 0, Math.PI]}
            scale={0.291}
          />
          <mesh
            name="AxeAura003"
            geometry={nodes.AxeAura003.geometry}
            material={materials.Egg_Aura}
            position={[-0.012, 0.529, 0]}
            rotation={[0, 0, Math.PI]}
            scale={0.291}
          />
          <mesh
            name="AxeAura005"
            geometry={nodes.AxeAura005.geometry}
            material={materials.Egg_Aura1}
            position={[-0.012, 0.529, 0]}
            rotation={[0, 0, Math.PI]}
            scale={0.301}
          />
          <mesh
            name="AxeAura006"
            geometry={nodes.AxeAura006.geometry}
            material={materials.Egg_Aura1}
            position={[-0.012, 0.529, 0]}
            rotation={[0, 0, Math.PI]}
            scale={0.301}
          />
          <mesh
            name="Plane003"
            geometry={nodes.Plane003.geometry}
            material={materials.Particles}
            position={[0.009, 0.988, -0.047]}
            rotation={[-1.106, -0.449, 0.612]}
            scale={0.001}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/egg.glb");
