import { useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import { useGame } from "../../providers/GameProvider";
import UpperInformation from "../../components/upperInformation/upperInformation";
import HomeBottom from "./_components/bottompart";
import HomeClicker from "./_components/clicker";
import HomeAutoFarm from "./_components/autofarm/autofarm";
import { Debug } from "./_components/debug";
import AutoFarmProvider from "../../providers/AutoFarmProvider";
import React from "react";

export default function AppHomePage() {
  const { user } = useAuth();
  const { activeEgg } = useGame();
  const [sectionSelected, setSectionSelected] = useState(0);

  // the percentage is max on 100%
  let eggProgressBar = React.useMemo(() => {
    return activeEgg
      ? (activeEgg.tap_count / activeEgg?.item!.tap_required_amount!) * 100
      : 0;
  }, [activeEgg]);

  // the percentage is max on 100%
  if (eggProgressBar > 100) {
    eggProgressBar = 100;
  }

  // if the user was on level 99 or more, then unlock the autofarm
  const autoFarmUnlock = React.useMemo(() => {
    return user?.level?.level! >= 5;
  }, [user]);

  if (!user) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-start justify-between h-full relative">
      <div className=" w-full flex flex-1">
        {sectionSelected === 0 ? (
          <HomeClicker />
        ) : (
          <AutoFarmProvider>
            {" "}
            <HomeAutoFarm />
          </AutoFarmProvider>
        )}
      </div>
      <div className="absolute flex self-center items-center align-middle justify-center mx-auto my-auto">
        <UpperInformation showProfilePicture showProgressBar useMargin />
      </div>
      <Debug />
      <div
        style={{
          position: "absolute",
          width: "100%",
          bottom: "80px",
        }}
      >
        <HomeBottom
          selected={sectionSelected}
          setSelected={(x: number | string) => {
            if (typeof x === "number") setSectionSelected(x);
          }}
          progressPercentage={eggProgressBar}
          showProgressBar={activeEgg && sectionSelected === 0 ? true : false}
          progressBarColor="bg-purple-300"
          progressBgColor="bg-purple-700"
          autoFarmOpen={autoFarmUnlock}
        />
      </div>
      <div style={{ height: "10.5vh" }} />
    </div>
  );
}
