import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { MenuList, PageI } from "../const/menu.const";
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import { AppContext } from "../const/state.const";

// Define the interface for the query parameters
interface PageQueryI {
  refcode: string | null;
}

// Create a context for the PageProvider
const PageProvContext = createContext({
  selectedPage: null as PageI | null,
  setSelectedPage: (pg: PageI | string) => {},
  query: null as PageQueryI | null,
  setQuery: (query: PageQueryI) => {},
  insertOrUpdateContext: (key: AppContext, value: any) => {},
  getContext: (key: AppContext): any => {},
  removeContext: (key: AppContext) => {},
  flushContext: () => {},
});

export default function PageProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // State for the selected page
  const [selectedPage, setSelectedPage] = useState<PageI | null>(null);
  const [initDataUnsafe] = useInitData();

  // State for context/state management
  const [pageContext, setPageContext] = useState<Object>({});

  // State for the query parameters
  const [query, setQuery] = useState<PageQueryI | null>(null);

  // Handle setting the selected page
  const handleSelectedPage = (pg: PageI | string) => {
    if (typeof pg === "string") {
      let temporary = MenuList.find((menu) => menu.name === pg);
      setSelectedPage(temporary ?? null);
    } else {
      setSelectedPage(pg);
    }
  };

  // Effect to read query parameters from the URL
  useEffect(() => {
    // const _params = (initDataUnsafe?.start_param ?? "").split(";");
    // const params: Record<string, string> = {};
    // _params.forEach((param) => {
    //   try {
    //     const [key, value] = param.split("=");
    //     params[key] = value;
    //   } catch (e) {
    //     console.error(e);
    //   }
    //   console.log(params);
    // });
    const refcode = initDataUnsafe?.start_param;

    if (refcode) {
      setQuery({ refcode });
    } else {
      setQuery({ refcode: null });
    }
  }, []);

  function insertOrUpdateContext<T>(key: AppContext, value: T): T | null {
    try {
      setPageContext((prev) => ({ ...prev, [key]: value }));
      return value;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  function getContext<T>(key: AppContext): T | null {
    try {
      if (!Object.keys(pageContext).includes(key)) {
        return null;
      }
      //@ts-ignore
      return pageContext[key] as T;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  function removeContext(key: AppContext) {
    try {
      setPageContext((prev) => ({ ...prev, [key]: undefined }));
    } catch (e) {
      console.error(e);
    }
  }

  function flushContext() {
    setPageContext({});
  }

  // Memoize the context value
  const contextVal = useMemo(
    () => ({
      selectedPage,
      setSelectedPage: handleSelectedPage,
      query,
      setQuery,
      removeContext,
      getContext,
      insertOrUpdateContext,
      flushContext,
    }),
    [selectedPage, query]
  );

  return (
    <PageProvContext.Provider value={contextVal}>
      {children}
    </PageProvContext.Provider>
  );
}

// Custom hook to use the PageProvider context
export const usePage = () => {
  return useContext(PageProvContext);
};
