import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import "./drawer.css";
import { AlertTheme } from "../../../const/alert.const";

interface QuestDrawerI {
  open: boolean;
  id: string;
  setOpen: (x: boolean) => void;
  title: string;
  description: string;
  reward: string | number;
  onConfirm: (x: any) => Promise<any>;
  alert?: null | undefined | "success" | "error";
  buttonEnabled?: boolean;
  buttonText?: string;
  questImageUrl?: string;
  externalLink?: string;
}

export default function QuestDrawer(props: QuestDrawerI) {
  const {
    open,
    setOpen,
    alert,
    buttonEnabled,
    buttonText,
    questImageUrl,
    title,
    description,
    reward,
    onConfirm,
    id,
    externalLink,
  } = props;

  const [loading, setLoading] = useState(false);
  const [successClaim, setSuccessClaim] = useState(false);
  const [alertType, setAlertType] = useState<"success" | "error" | null>(null);

  useEffect(() => {
    if (alert) {
      setAlertType(alert);
    }
  }, [alert]);

  const handleConfirm = async () => {
    setLoading(true);
    onConfirm(id)
      .then(() => setSuccessClaim(true))
      .catch((e) => {
        console.error("error when confirming quest", e);
        setAlertType("error");
      })
      .finally(() => setLoading(false));
  };

  const btnDisabled = buttonEnabled === false || successClaim || loading;

  return open ? (
    <>
      {/* Overlay with Blur */}
      <div
        className="fixed inset-0 bg-black bg-opacity-70 backdrop-blur-sm z-30"
        onClick={() => setOpen(false)}
      ></div>

      {/* Drawer */}
      <div
        id="drawer-bottom-example"
        className="flex flex-col gap-6 fixed bottom-0 left-0 right-0 z-40 w-full overflow-y-auto transition-transform bg-white dark:bg-gray-800 transform-none rounded-t-xl"
        tabIndex={-1}
        style={{ padding: "30px 16px 16px", backgroundColor: "#353D54" }}
        aria-labelledby="drawer-bottom-label"
      >
        <button
          type="button"
          data-drawer-hide="drawer-bottom-example"
          aria-controls="drawer-bottom-example"
          className="text-gray-400 text-white rounded-full text-sm w-[26px] h-[26px] absolute top-2.5 end-2.5 inline-flex items-center justify-center"
          style={{
            backgroundColor: "#5B6990",
          }}
          onClick={() => setOpen(false)}
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
            style={{ color: "white" }}
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close menu</span>
        </button>
        <div className="relative flex justify-center items-center w-full">
          {questImageUrl ? (
            <img
              src={questImageUrl}
              alt="Logo task"
              className="z-20"
              style={{ width: "60px" }}
            />
          ) : (
            <>
              <img
                src="hatchling_logo_2.png"
                alt="Logo task"
                className="z-20"
                style={{ width: "60px", marginRight: "-15px" }}
              />
              <img
                src="twitter_logo_2.png"
                alt="Logo task"
                className="z-10"
                style={{ width: "60px", marginLeft: "-15px" }}
              />
            </>
          )}
        </div>

        <div className="flex flex-col items-center gap-[6px]">
          <h6 className="text-white font-extrabold">{title}</h6>
          <span className="text-white text-xs">{description}</span>
        </div>
        <div className="flex items-center gap-1 justify-center">
          <img src="amber_icon.png" alt="amber icon" width={25} height={25} />
          <p className="font-semibold text-white">{reward}</p>
        </div>
        <div className="flex flex-col gap-2">
          {alertType === "error" && (
            <div
              className={`flex items-end justify-center pointer-events-none`}
              role="alert"
            >
              <div
                className="w-full px-4 py-3 rounded-lg relative flex flex-row items-center"
                style={{
                  backgroundColor: "#3C1D18",
                  border: "2px solid #FD515D",
                }}
              >
                <ExclamationTriangleIcon
                  className="w-8 h-8 mr-2"
                  color="#FD2635"
                />
                <div className="flex flex-col">
                  <div
                    className="flex items-center gap-1"
                    style={{
                      color: "#FD2635",
                      paddingBottom: "2px",
                    }}
                  >
                    <p className="text-xs font-extrabold">
                      Verification failed
                    </p>
                  </div>
                  <span className="text-xs text-white">
                    Ensure that the task is fully completed.
                  </span>
                </div>
              </div>
            </div>
          )}
          {alertType === "success" && (
            <div
              className={`flex items-end justify-center pointer-events-none`}
              role="alert"
            >
              <div
                className="w-full px-4 py-3 rounded-lg relative flex flex-row items-center"
                style={{
                  backgroundColor: "#163110",
                  border: "2px solid #57CF72",
                }}
              >
                <ExclamationTriangleIcon
                  className="w-8 h-8 mr-2"
                  color="#57CF72"
                />
                <div className="flex flex-col">
                  <div
                    className="flex items-center gap-1"
                    style={{
                      color: "#57CF72",
                      paddingBottom: "2px",
                    }}
                  >
                    <p className="text-xs font-extrabold">
                      Task finished! Your reward
                    </p>
                    <img
                      src="amber_icon.png"
                      alt="amber icon"
                      width={16}
                      height={16}
                    />
                    <p className="text-xs font-semibold">{reward}</p>
                  </div>
                  <span className="text-xs text-white">Claim your reward!</span>
                </div>
              </div>
            </div>
          )}

          {externalLink && (
            <a href={externalLink}>
              <button
                className={`w-full flex justify-center px-2 py-3 text-sm font-medium text-center text-white rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-300`}
                style={{ backgroundColor: "#5B6990" }}
              >
                {loading ? (
                  <div className="w-5 h-5 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
                ) : (
                  <>Open Link</>
                )}
              </button>
            </a>
          )}
          <button
            className={`w-full flex justify-center px-2 py-3 text-sm font-medium text-center text-white rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-300`}
            style={{ backgroundColor: btnDisabled ? "#0054AC" : "#007AFF" }}
            disabled={btnDisabled}
            onClick={() => {
              handleConfirm();
            }}
          >
            {loading ? (
              <div className="w-5 h-5 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
            ) : (
              <>{buttonText ? buttonText : btnDisabled ? "Claimed" : "Claim"}</>
            )}
          </button>
        </div>
      </div>
    </>
  ) : null;
}
