import { useState } from "react";
import { useEggs } from "../../../hooks/useEgg";
import { useHatch } from "../../../hooks/useHatch";
import Skeleton from "react-loading-skeleton";
import { hatchlingBg } from "../../../const/asset.const";
import useModal from "../../../hooks/useModal";
import { DetailsModal } from "./modal";
import { usePage } from "../../../providers/PageProvider";
import { AppContext } from "../../../const/state.const";

interface BagItemsI {
  selectedTab: number | string | void;
}

export default function BagItems(props: BagItemsI) {
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const { selectedTab } = props;
  const { eggs, loading: loadingEggs } = useEggs();
  const { hatchlings, loading: loadingHatch } = useHatch();
  const { isOpen, openModal, closeModal } = useModal();
  const { setSelectedPage, insertOrUpdateContext } = usePage();

  const totalSlots = 40; // 5 columns * 8 rows

  const renderEggsList = () => {
    const filledSlots = eggs.length;
    const emptySlots = totalSlots - filledSlots;

    return (
      <>
        {loadingEggs ? (
          <Skeleton height={40} width={300} count={6} />
        ) : (
          <div className="max-h-[550px] overflow-y-auto p-4 border-2 border-[#6288c2] rounded-xl w-full">
            <div className="grid grid-cols-5 grid-rows-8 gap-2">
              {eggs.map((egg: any) => {
                const { bgGradient, border } = hatchlingBg(
                  egg?.properties?.faction
                );
                return (
                  <div
                    className={`h-[50px] w-[50px] p-2 ${bgGradient} rounded-lg border-[2.5px] ${border} inline-flex justify-center items-center relative cursor-pointer`}
                    key={egg.id}
                    onClick={() => {
                      setSelectedItem(egg);
                      openModal();
                    }}
                  >
                    <img
                      className="w-full h-full"
                      src={egg?.assets_url}
                      alt="Egg"
                    />
                    <div className="absolute bottom-0 right-0 text-[#FFFDF4] text-[10px] font-extrabold leading-[14px] p-1 break-words drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                      {egg.count_total}
                    </div>
                  </div>
                );
              })}
              {Array.from({ length: emptySlots }).map((_, index) =>
                renderPlaceholder(filledSlots + index, "/egg_bg.png")
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  const renderHatchlingsList = () => {
    const filledSlots = hatchlings.length;
    const emptySlots = totalSlots - filledSlots;

    return (
      <>
        {loadingHatch ? (
          <Skeleton height={40} width={300} count={6} />
        ) : (
          <div className="max-h-[550px] overflow-y-auto p-4 border-2 border-[#6288c2] rounded-xl w-full">
            <div className="grid grid-cols-5 grid-rows-8 gap-2">
              {hatchlings
                .sort((a: any, b: any) => b.level - a.level) // sort by highest to lowest level
                .map((hatchling: any, index: number) => {
                  const { bgGradient, border } = hatchlingBg(
                    hatchling?.faction
                  );

                  return (
                    <div
                      className={`h-[50px] w-[50px] p-2 ${bgGradient} rounded-lg border-[2.5px] ${border} inline-flex justify-center items-center relative cursor-pointer`}
                      key={index}
                      onClick={() => {
                        insertOrUpdateContext(AppContext.CHARACTER, hatchling);
                        insertOrUpdateContext(
                          AppContext.HATCHLINGS,
                          hatchlings
                        );
                        insertOrUpdateContext(AppContext.CHAR_INDEX, index);
                        insertOrUpdateContext(AppContext.BAG_TAB, 1);
                        setSelectedPage("character");
                      }}
                    >
                      <img
                        className="w-full h-full"
                        src={hatchling?.assets_url}
                        alt="Hatchling"
                      />
                      <div className="absolute top-0 px-1 text-[#FFFDF4] text-[10px] font-extrabold leading-[14px] break-words drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                        Lv.{hatchling?.level}
                      </div>
                    </div>
                  );
                })}
              {Array.from({ length: emptySlots }).map((_, index) =>
                renderPlaceholder(filledSlots + index, "/hatchling_bg.png")
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  const renderPlaceholder = (key: number, placeholder: string) => {
    return (
      <div
        key={key}
        className="w-[50px] h-[50px] p-2 bg-zinc-200 rounded-lg shadow-inner border border-[#cccccb] justify-center items-center flex"
      >
        <img className="w-full h-full" src={placeholder} alt="placeholder" />
      </div>
    );
  };

  return (
    <div className="w-full flex justify-center">
      <DetailsModal
        isOpen={isOpen}
        onClose={() => {
          closeModal();
          setSelectedItem(null);
        }}
        selected={selectedTab}
        item={selectedItem}
      ></DetailsModal>
      {selectedTab === 0 ? renderEggsList() : renderHatchlingsList()}
    </div>
  );
}
