// @ts-nocheck
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function Bird(props: any) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/bird.glb?id=" + props.bid);
  const { actions } = useAnimations(animations, group);

  React.useEffect(() => {
    actions[props.animation].play();
  }, [actions]);
  return (
    <group receiveShadow castShadow ref={group} {...props} dispose={null}>
      <group receiveShadow castShadow name="Scene">
        <group
          receiveShadow
          castShadow
          name="Rig_Hatchling"
          position={[0, 0.066, 0]}
          scale={1.657}
        >
          <skinnedMesh
            receiveShadow
            castShadow
            name="Body_Pointy"
            geometry={nodes.Body_Pointy.geometry}
            material={materials.OwlBase}
            skeleton={nodes.Body_Pointy.skeleton}
          />
          <skinnedMesh
            receiveShadow
            castShadow
            name="Feather002"
            geometry={nodes.Feather002.geometry}
            material={materials.OwlBase}
            skeleton={nodes.Feather002.skeleton}
          />
          <group name="Feet">
            <skinnedMesh
              receiveShadow
              castShadow
              name="Mesh"
              geometry={nodes.Mesh.geometry}
              material={materials.Feet}
              skeleton={nodes.Mesh.skeleton}
            />
            <skinnedMesh
              receiveShadow
              castShadow
              name="Mesh_1"
              geometry={nodes.Mesh_1.geometry}
              material={materials.Claws}
              skeleton={nodes.Mesh_1.skeleton}
            />
          </group>
          <skinnedMesh
            receiveShadow
            castShadow
            name="Wings"
            geometry={nodes.Wings.geometry}
            material={materials.OwlBase}
            skeleton={nodes.Wings.skeleton}
          />
          <primitive receiveShadow castShadow object={nodes.Root} />
          <primitive receiveShadow castShadow object={nodes.neutral_bone} />
        </group>
      </group>
    </group>
  );
}
