import ShopCard from "./shopCard";
import ShopHeading from "./shopHeading";

export default function ShopAmbers() {
    return (
        <div className="w-full pt-4 px-4 pb-8 flex flex-col gap-8">
            <div className="w-full flex flex-col gap-4">
                <ShopHeading text="Premium Exclusive Benefit" />
                <div className="w-full flex flex-col gap-4">
                    <ShopCard
                        outerBorderColor="#6D286E"
                        innerBorderColor="#BF4BC1"
                        bgColor="linear-gradient(#E174F2, #EFCEF7)"
                        text="Premium Exclusive Benefit"
                        imgUrl="/skip_ads_icon.png"
                        quantity="Skip Ads"
                        price="50,000"
                        discount="60% OFF"
                        type="ambers"
                    />
                </div>
            </div>
            <div className="w-full flex flex-col gap-4">
                <ShopHeading text="Special Offer" />
                <div className="w-full flex flex-col gap-4">
                    <ShopCard
                        outerBorderColor="#2E7388"
                        innerBorderColor="#59B6D3"
                        bgColor="linear-gradient(#C8F0F2, #FFFFEA)"
                        text="Started Pack"
                        imgUrl="/thumb_icon.png"
                        quantity="x 10"
                        price="10,000"
                        discount="60% OFF"
                        type="ambers"
                    />
                </div>
            </div>
        </div>
    );
}
