import { title } from "process";
import ShopCard from "./shopCard";
import ShopEggItem from "./shopEggItem";
import ShopHeading from "./shopHeading";

const eggItems = [
    {
        title: "Random Blast Egg (S)",
        imageUrl: "/nest_egg1.png",
        price: "1,000",
        quantity: 1,
    },
    {
        title: "Random Blast Egg (S)",
        imageUrl: "/nest_egg2.png",
        price: "9,000",
        quantity: 10,
    },
    {
        title: "Random Blast Egg (S)",
        imageUrl: "/nest_egg3.png",
        price: "40,000",
        quantity: 50,
    },
    {
        title: "Random Blast Egg (M)",
        imageUrl: "/nest_egg1.png",
        price: "5,000",
        quantity: 1,
    },
    {
        title: "Random Blast Egg (M)",
        imageUrl: "/nest_egg2.png",
        price: "45,000",
        quantity: 10,
        discount: "30% OFF",
    },
    {
        title: "Random Blast Egg (M)",
        imageUrl: "/nest_egg3.png",
        price: "200,000",
        quantity: 50,
    },
];

export default function ShopCoins() {
    return (
        <div className="w-full pt-4 px-4 pb-8 flex flex-col gap-8">
            <div className="w-full flex flex-col gap-4">
                <ShopHeading text="Blast Egg" />
                <div className="grid grid-cols-3 gap-2">
                    {eggItems.map((item, idx) => (
                        <ShopEggItem
                            key={idx}
                            title={item.title}
                            discount={item.discount}
                            imageUrl={item.imageUrl}
                            price={item.price}
                            quantity={item.quantity}
                        />
                    ))}
                </div>
            </div>
            <div className="w-full flex flex-col gap-4">
                <ShopHeading text="Special Offer" />
                <div className="w-full flex flex-col gap-4">
                    <ShopCard
                        outerBorderColor="#2E7388"
                        innerBorderColor="#59B6D3"
                        bgColor="linear-gradient(#C8F0F2, #FFFFEA)"
                        text="Luxury Package"
                        imgUrl="/nest_egg1.png"
                        quantity="x 200"
                        price="50,000"
                        discount="60% OFF"
                        type="coins"
                    />
                </div>
            </div>
        </div>
    );
}
