interface HelpTipButtonI {
  onClick?: () => void;
  disabled?: boolean;
}

export default function HelpTipButton(props: HelpTipButtonI) {
  return (
    <div
      className={`flex bg-[#424241] rounded-lg ${
        props.disabled ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      style={{
        width: "25px",
        height: "25px",
        border: "2px solid black",
        paddingBottom: "1.7px",
      }}
      onClick={() => {
        if (props.onClick && !props.disabled) {
          props.onClick();
        }
      }}
    >
      <div className="w-full flex justify-center items-center bg-[#8C8C8B] rounded-md">
        <span className="font-extrabold text-white text-center text-lg">i</span>
      </div>
    </div>
  );
}
