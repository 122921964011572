export interface CardDialogI {
  title: string | React.ReactElement;
  titleClass?: string;
  titleStyle?: React.CSSProperties;
  children?: React.ReactElement;
  titleSize?: string;
  onClose?: () => void;
  background: string;
  innerBackground: string;
}
export function CardDialog(props: CardDialogI) {
  return (
    <div
      className="w-full flex flex-col items-center justify-center rounded-2xl shadow-md px-1 pb-1 pt-2"
      style={{
        backgroundColor: props.background,
        boxShadow: `0 5.5px 0 rgba(32, 37, 50, 0.2)`,
      }}
    >
      <div className="grid grid-cols-6 w-full">
        <div className="col-span-1" />
        <div className="col-span-4 flex items-center justify-center ">
          {typeof props.title === "string" ? (
            <h1
              className={
                props.titleClass ?? "font-bold text-red-500 text-stroke pb-1"
              }
              style={{
                fontSize: props.titleSize ?? "24px",
                ...props.titleStyle,
              }}
            >
              {props.title}
            </h1>
          ) : (
            props.title
          )}
        </div>
        {props.onClose && (
          <div
            className="flex items-center justify-center cursor-pointer"
            onClick={props.onClose}
          >
            <img
              src="/close_dialog.svg"
              alt="close"
              className="w-6 h-6 transform transition-transform duration-200 hover:scale-110 pb-0.5"
            />
          </div>
        )}
      </div>
      <div
        className="w-full rounded-md p-4"
        style={{
          backgroundColor: props.innerBackground,
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
