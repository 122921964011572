import { UserItem } from "../../../interfaces/item.interface";
import { HatchlingAcquiredModal } from "./modal";
import useModal from "../../../hooks/useModal";
import { Hatch } from "../../../interfaces/item.interface";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useGame } from "../../../providers/GameProvider";
import { Canvas } from "@react-three/fiber";
import { Egg } from "./model";
import { OrbitControls, useProgress } from "@react-three/drei";
import { ASSETS } from "../../../const/asset.const";

interface CanvasTapButtonI {
  disabled: boolean;
  onClick: (e: MouseEvent | TouchEvent) => void;
  currentEnergy: number;
  energyPerTap: number;
  deviceMode?: "DESKTOP" | "MOBILE";
  activeEgg: UserItem | null;
  hatch?: Hatch | null;
}

interface FloatingText {
  x: number;
  y: number;
  opacity: number;
}

export function CanvasTapButton(props: CanvasTapButtonI) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [tilt, setTilt] = useState({ x: 0, y: 0 });
  const [floatingText, setFloatingText] = useState<(FloatingText | null)[]>([]);
  const [gradientCenter, setGradientCenter] = useState({ x: 0.5, y: 0.5 });
  const [animationLock, setAnimationLock] = useState(false);
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const { isOpen, openModal, closeModal } = useModal();
  const { activeEgg } = useGame();
  const progress = useProgress();
  const modelRef = useRef<any>();
  const eggProgress = React.useMemo(() => {
    return (
      (activeEgg?.tap_count! / activeEgg?.item?.tap_required_amount!) * 100
    );
  }, [activeEgg]);

  useEffect(() => {
    const img = new Image();
    img.src = "/icon_bbcoin.svg";
    img.onload = () => {
      setImage(img);
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext("2d");
    if (!context) return;

    const getEventPosition = (event: MouseEvent | TouchEvent) => {
      const rect = canvas.getBoundingClientRect();
      let x = 0,
        y = 0;
      if (event instanceof MouseEvent) {
        x = event.clientX - rect.left;
        y = event.clientY - rect.top;
      } else if (event instanceof TouchEvent) {
        const touch = event.touches[0];
        x = touch.clientX - rect.left;
        y = touch.clientY - rect.top;
      }
      return { x, y, rect };
    };

    const handleInteraction = (event: MouseEvent | TouchEvent) => {
      const { x, y, rect } = getEventPosition(event);
      const relativeX = x / rect.width;
      const relativeY = y / rect.height;

      if (!props.disabled && props.currentEnergy >= props.energyPerTap) {
        props.onClick(event);
        handleTilt(x, y, rect);
        setFloatingText((prev) => [...prev, { x: x * 1.075, y, opacity: 1 }]);
        setGradientCenter({ x: relativeX, y: relativeY });
      }
    };

    if (props.deviceMode === "MOBILE") {
      canvas.addEventListener("touchstart", handleInteraction);
    } else if (props.deviceMode === "DESKTOP") {
      canvas.addEventListener("click", handleInteraction);
    } else {
      canvas.addEventListener("click", handleInteraction);
      canvas.addEventListener("touchstart", handleInteraction);
    }

    return () => {
      if (props.deviceMode === "MOBILE") {
        canvas.removeEventListener("touchstart", handleInteraction);
      } else if (props.deviceMode === "DESKTOP") {
        canvas.removeEventListener("click", handleInteraction);
      } else {
        canvas.removeEventListener("click", handleInteraction);
        canvas.removeEventListener("touchstart", handleInteraction);
      }
    };
  }, [props.disabled, props.onClick, props.deviceMode]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext("2d");
      if (context) {
        applyTilt(context, canvas.width, canvas.height, tilt);
      }
    }
  }, [tilt]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    let animationFrameId: number;

    const render = () => {
      if (context) {
        animateFloatingText();
        drawCoin(context);
      }
    };

    animationFrameId = requestAnimationFrame(render);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [floatingText]);

  useEffect(() => {
    const hatchling = props.hatch;

    if (!hatchling) return;
    if (hatchling) {
      openModal();
    }
  }, [props.hatch]);

  const handleTilt = (x: number, y: number, rect: DOMRect) => {
    const tiltX = (x - rect.width / 2) / (rect.width / 2);
    const tiltY = (y - rect.height / 2) / (rect.height / 2);
    setTilt({ x: tiltX * 10, y: tiltY * 10 });
  };

  const applyTilt = (
    context: CanvasRenderingContext2D,
    width: number,
    height: number,
    tilt: { x: number; y: number }
  ) => {
    context.save();
    context.translate(width / 2, height / 2);
    context.rotate((tilt.x * Math.PI) / 180);
    context.rotate((tilt.y * Math.PI) / 180);
    context.translate(-width / 2, -height / 2);
    context.restore();
  };

  const drawImageWithOpacity = (
    context: CanvasRenderingContext2D,
    image: any,
    x: number,
    y: number,
    width: number,
    height: number,
    opacity: number
  ) => {
    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = width;
    offscreenCanvas.height = height;
    const offscreenContext = offscreenCanvas.getContext("2d");

    if (offscreenContext && image) {
      try {
        offscreenContext.drawImage(image, 0, 0, width, height);
        const imageData = offscreenContext.getImageData(0, 0, width, height);
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
          data[i + 3] = data[i + 3] * opacity;
        }

        offscreenContext.putImageData(imageData, 0, 0);
        context.drawImage(offscreenCanvas, x, y, width, height);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  };

  const drawCoin = (context: CanvasRenderingContext2D) => {
    const cWidth = context.canvas.width;
    const cHeight = context.canvas.height;
    context.clearRect(0, 0, cWidth, cHeight);

    // Outer circle with radial gradient
    const outerGradient = context.createRadialGradient(
      gradientCenter.x * cWidth,
      gradientCenter.y * cHeight,
      10,
      cWidth / 2,
      cHeight / 2,
      cWidth / 2
    );
    outerGradient.addColorStop(0, "#0c3a85");
    outerGradient.addColorStop(1, "#1e5bb8");
    context.fillStyle = outerGradient;
    context.beginPath();
    context.arc(cWidth / 2, cHeight / 2, cWidth / 2, 0, 2 * Math.PI);
    // context.fill();

    // Inner circle with radial gradient
    // const innerGradient = context.createRadialGradient(
    //   gradientCenter.x * cWidth,
    //   gradientCenter.y * cHeight,
    //   10,
    //   cWidth / 2,
    //   cHeight / 2,
    //   cWidth / 2 - 10
    // );
    // innerGradient.addColorStop(0, "#2b76f0");
    // innerGradient.addColorStop(1, "#5a9bf6");
    // context.fillStyle = innerGradient;
    // context.beginPath();
    // context.arc(cWidth / 2, cHeight / 2, cWidth / 2 - 10, 0, 2 * Math.PI);
    // context.fill();

    // // Adding shadow for 3D effect
    // context.shadowColor = "rgba(0, 0, 0, 0.5)";
    // context.shadowBlur = 15;
    // context.shadowOffsetX = 5;
    // context.shadowOffsetY = 5;

    // // Reset shadow
    // context.shadowColor = "transparent";
    // context.shadowBlur = 0;
    // context.shadowOffsetX = 0;
    // context.shadowOffsetY = 0;

    // Text in the middle
    // context.fillStyle = "white";
    // context.textAlign = "center";
    // context.font = "bold 24px Arial";
    // context.fillText("Tap me!", cWidth / 2, cHeight / 2 + 8);
    for (const ft of floatingText) {
      if (ft && image) {
        // draw image with opacity
        const imgX = ft.x - 40; // Adjust as needed
        const imgY = ft.y - 22; // Adjust as needed
        const imgWidth = 35; // Adjust as needed
        const imgHeight = 35; // Adjust as needed

        drawImageWithOpacity(
          context,
          image,
          imgX,
          imgY,
          imgWidth,
          imgHeight,
          ft.opacity
        );

        // Simulate stroke effect
        context.font = "bold 11pt montserrat";
        context.lineWidth = 10;
        context.strokeStyle = "#412f34";

        const offsets: any[] = [];
        const strokeThickness = 3;
        for (let i = -strokeThickness; i <= strokeThickness; i++) {
          for (let j = -strokeThickness; j <= strokeThickness; j++) {
            if (i !== 0 || j !== 0) {
              offsets.push({ x: i, y: j });
            }
          }
        }

        offsets.forEach((offset) => {
          context.fillStyle = `rgba(65, 47, 52, ${ft.opacity})`; // Dark color for stroke
          context.fillText(
            `+ ${props.energyPerTap ?? "N"}`,
            ft.x + offset.x,
            ft.y + offset.y
          );
        });

        // Draw the main text
        context.fillStyle = `rgba(255, 255, 255, ${ft.opacity})`;
        context.fillText(`+ ${props.energyPerTap ?? "N"}`, ft.x, ft.y);
      }
    }
  };

  const animateFloatingText = () => {
    if (floatingText) {
      setFloatingText((prev) => {
        const newData: FloatingText[] = [];
        for (const pd of prev) {
          if (pd && pd.opacity > 0) {
            newData.push({
              ...pd,
              y: pd.y - 1,
              opacity: pd.opacity - 0.03,
            });
          }
        }

        return newData;
      });
    }
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext("2d");
      if (context) {
        drawCoin(context);
      }
    }
    setAnimationLock(false);
  };

  const Loader = () => {
    return <div style={{ color: "white" }}>Loading...</div>;
  };
  return (
    <div className="relative" style={{ width: "100vw", height: "350px" }}>
      <HatchlingAcquiredModal
        isOpen={isOpen}
        onClose={() => {
          closeModal();
        }}
      ></HatchlingAcquiredModal>
      <div className="z-10 absolute top-0">
        <canvas
          className="opacity-55"
          ref={canvasRef}
          width={400}
          height={400}
          style={{
            zIndex: 10,
          }}
        />
      </div>
      {progress.progress === 100 ? (
        <Canvas camera={{ position: [0, 0, 50], fov: 12 }}>
          <ambientLight intensity={3.5} />
          <Suspense fallback={null}>
            <Egg
              progress={eggProgress}
              position={[0, -1.9, 0]}
              ref={modelRef}
            />
          </Suspense>
          {/* <gridHelper /> */}
          {/* <OrbitControls /> */}
        </Canvas>
      ) : (
        <div className="relative">
          <img src={ASSETS.NO_EGGS} alt="No Eggs" />
          <p
            className="absolute text-center ml-auto mr-auto w-full font-extrabold text-white"
            style={{
              top: "50%",
            }}
          >
            Loading ...
          </p>
        </div>
      )}
    </div>
  );
}
