import FriendListCard from "./card";
import { FriendListI } from "./interface";

interface FriendListComponentI extends FriendListI {}

export default function FriendListComponent(props: FriendListComponentI) {
  const { friends, totalFriend } = props;
  return (
    <div className="w-full flex flex-col items-start justify-start bg-[#d4e3f3] p-4 rounded-lg">
      <div className="w-full flex flex-row items-center justify-between">
        <span className="text-lg font-semibold text-blue-800">
          Friends List: {totalFriend}
        </span>
      </div>

      <div className="w-full flex flex-col items-center justify-start">
        {totalFriend <= 0 ? (
          <div className="my-4 w-full flex flex-col items-center justify-center gap-4">
            <img src="/zero_friends_image.png" alt="bird_images" />
            <div className="text-blue-800 flex flex-col">
              <span className="text-md font-semibold">
                You haven't invited anyone yet
              </span>
              <span className="text-sm">
                But you can always invite someone!
              </span>
            </div>
          </div>
        ) : (
          friends.map((friend) => (
            <FriendListCard
              key={friend.username}
              friend={{
                ...friend,
              }}
            />
          ))
        )}
      </div>
    </div>
  );
}
