interface IMenuButton {
  icon: string | React.ReactElement;
  text: string;
  onClick: () => void;
  disabled?: boolean;
  selected?: boolean;
  imageHeight?: string;
  textSize?: string;
}

export default function MenuButton(props: IMenuButton) {
  return (
    <div
      className="w-full h-full bg-[#8B6D75] border-black border-2 rounded-lg"
      style={{
        paddingBottom: "3.5px",
      }}
      onClick={props.onClick}
    >
      <div className="relative w-full h-full bg-[#F1E4B4] rounded-lg flex flex-col justify-end items-center pb-0.5">
        {typeof props.icon === "string" ? (
          <img
            src={props.icon}
            alt={props.text}
            className="absolute transition-all duration-300"
            style={{
              height: props.imageHeight ?? "30px",
              top: "-10px",
              zIndex: 10,
            }}
          />
        ) : (
          props.icon
        )}
        <span
          className="font-bold text-[#714C56]"
          style={{ fontSize: props.textSize ?? "10.5px" }}
        >
          {props.text}
        </span>
        <div
          className="absolute top-1 left-1"
          style={{
            height: "5px",
            width: "5px",
            borderRadius: "50%",
            marginTop: "1.5px",
            marginRight: "3px",
          }}
        />
      </div>
    </div>
  );
}
