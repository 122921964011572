import React from "react";

import style from "./FloatingIcon.module.css";

export interface FloatingIconProps {
  icon: string;
  onClick?: () => void;
  title: string;
  badge?: number;
}

export const FloatingIcon = ({
  icon,
  onClick,
  title,
  badge,
}: FloatingIconProps) => {
  return (
    <div className={style.floating} onClick={onClick}>
      <div className={style.innerStroke}>
        <svg
          className="absolute top-[13px] left-[2px]"
          xmlns="http://www.w3.org/2000/svg"
          width="5"
          height="6"
          viewBox="0 0 5 6"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.18799 0.159252C5.20647 0.690444 5.2763 2.39296 4.34407 3.96187C3.4118 5.53083 1.83044 6.37199 0.812003 5.84075C-0.206462 5.30951 -0.276309 3.607 0.655925 2.03813C1.58821 0.469171 3.16953 -0.371988 4.18799 0.159252Z"
            fill="#FFFBE9"
          />
        </svg>
        <div className="flex flex-col text-center align-middle items-center -mt-3 mx-auto mb-1">
          <img width={25} height={30} src={icon} alt={title} />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="6"
            viewBox="0 0 20 6"
            fill="none"
          >
            <ellipse
              opacity="0.15"
              cx="10"
              cy="3"
              rx="10"
              ry="3"
              fill="black"
            />
          </svg>
        </div>

        <p className={style.title}>{title}</p>
        <span>{badge}</span>
      </div>
    </div>
  );
};
