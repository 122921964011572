import React from "react";

export interface ModalProps {
  open: boolean;
  onClose?: () => void;
  children?: React.ReactElement;
}

export const Modal = ({ open, onClose, children }: ModalProps) => {
  if (!open) {
    return <></>;
  }

  return (
    <div className="fixed z-50 inset-0 flex items-center justify-center px-4 bg-black bg-opacity-70">
      {children}
    </div>
  );
};
