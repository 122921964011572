interface BagMenuI {
  selectedTab: number | string | void;
  setSelectedTab: (x: number | string | void) => void;
}

export default function BagMenu(props: BagMenuI) {
  const { selectedTab, setSelectedTab } = props;

  return (
    <div className="w-full pt-2 flex flex-col gap-2 px-4">
      <div className="w-full px-2.5 justify-start items-center gap-[3px] inline-flex">
        {/* Resources Tab */}
        <div
          className={`cursor-pointer py-2 px-4 rounded-t-xl ${
            selectedTab === 0
              ? "bg-[#6288c2] text-[#fffdf4]"
              : "bg-[#c0cfe7] text-[#3b5274]"
          }`}
          onClick={() => setSelectedTab(0)}
        >
          <span className="text-sm font-extrabold">Resources</span>
        </div>
        {/* Characters Tab */}
        <div
          className={`cursor-pointer py-2 px-4 rounded-t-xl ${
            selectedTab === 1
              ? "bg-[#6288c2] text-[#fffdf4]"
              : "bg-[#c0cfe7] text-[#3b5274]"
          }`}
          onClick={() => setSelectedTab(1)}
        >
          <span className="text-sm font-extrabold">Characters</span>
        </div>
      </div>
    </div>
  );
}
