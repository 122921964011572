import { Canvas } from "@react-three/fiber";
import { ASSETS } from "../../../../const/asset.const";
import { Bird } from "../BirdModel";
import {
  ContactShadows,
  OrbitControls,
  OrthographicCamera,
  useProgress,
} from "@react-three/drei";
import { Light } from "./Light";
import { NestModal } from "./modal";
import useModal from "../../../../hooks/useModal";
import { useAutoFarmContext } from "../../../../providers/AutoFarmProvider";
import { InfoBlock } from "./info-block";

type BirdOpts = {
  position: [number, number, number];
  animation: string;
  scale: number;
};

const birds: BirdOpts[] = [
  {
    position: [-5, 0, 0],
    animation: "Idle",
    scale: 2,
  },
  {
    position: [0, 0, 5],
    animation: "Blastbird_GetCoin",
    scale: 1,
  },
  {
    position: [5, 0, 30],
    animation: "Idle",
    scale: 2,
  },
  {
    position: [8, 0, -1],
    animation: "Idle",
    scale: 1,
  },
  {
    position: [-5, 0, 25],
    animation: "Blastbird_GetCoin",
    scale: 0.8,
  },
];

export default function HomeAutoFarm() {
  const loading = useProgress();
  const { isOpen, toggleModal } = useModal();
  const { items } = useAutoFarmContext();
  // displayedBirds should be based on items total
  const displayedBirds = birds.slice(0, items.length);

  return (
    <div
      className="w-full"
      style={{
        backgroundImage: `url(${ASSETS.AUTOFARM_BG})`,
        backgroundSize: "cover",
      }}
    >
      {/* Modal */}
      <NestModal isOpen={isOpen} onClose={() => toggleModal()} />

      {/* End of Modal */}
      {loading.progress !== 100 && (
        <div
          className="absolute w-full h-full bg-gray-500 bg-opacity-80 flex align-middle justify-center justify-items-center z-50"
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Loading {loading.progress.toFixed(2)}%...
        </div>
      )}
      <Canvas shadows>
        <OrthographicCamera
          makeDefault
          left={-10}
          right={10}
          top={15}
          bottom={-15}
          near={-105}
          far={50}
          position={[0, 5, 15]} // Position the camera
        />
        <Light />
        {displayedBirds.map((bird, index) => (
          <Bird
            key={index}
            bid={index}
            position={bird.position}
            animation={bird.animation}
            scale={bird.scale}
            onClick={() => {
              console.log("clicked", index);
            }}
          />
        ))}
        {/* Add soft shadows */}
        <ContactShadows
          position={[0, 0, 0]}
          opacity={0.5}
          scale={70}
          blur={1}
          far={10}
        />
        <OrbitControls enableRotate={false} enableZoom={false} />
      </Canvas>
      <InfoBlock
        onDeploClicked={() => {
          toggleModal();
        }}
        items={[]}
      />
    </div>
  );
}
