import { useEffect, useRef, useState } from "react";
import RankItem from "./rankItem";
import { MyRankResponse, UserRankResponse } from "../data/rank.dto";
import { useProfilePicture } from "../../../hooks/useProfilePicture";

interface RanksI {
  ranks: UserRankResponse[];
  user: MyRankResponse | null;
  isLoading: boolean;
  focusMode: boolean;
}

export default function Ranks(props: RanksI) {
  const profilePicture = useProfilePicture();
  const containerRef = useRef<HTMLDivElement>(null); // Create a ref for the container

  // Auto-scroll effect
  useEffect(() => {
    if (props.focusMode && props.user) {
      const userRankElement = containerRef.current?.querySelector(
        `.uid-${props.user.my_rank.user_id}` // Note the '.' to indicate a class selector
      );

      if (userRankElement) {
        userRankElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [props.focusMode, props.user, props.ranks]);

  if (props.isLoading) {
    return (
      <div className="w-full flex flex-col gap-1">
        {Array(10)
          .fill(0)
          .map((_, index) => {
            return (
              <RankItem
                key={index}
                rank={index + 1}
                username="Username"
                level={"0"}
                isLoading={props.isLoading}
                amber={""}
              />
            );
          })}
      </div>
    );
  }
  return (
    <div className="w-full flex flex-col gap-1" ref={containerRef}>
      {props.ranks.map((rank, index) => {
        let borderColor: string;
        let backgroundColor: string;

        switch (rank.rank) {
          case 1:
            borderColor = "#D97706";
            backgroundColor = "#FDE68A";
            break;
          case 2:
            borderColor = "#59B6D3";
            backgroundColor = "#C5EFFD";
            break;
          case 3:
            borderColor = "#D7554E";
            backgroundColor = "#FFC3C0";
            break;
          default:
            borderColor = "#FEEBCD";
            backgroundColor = "#FFFFFF";
            break;
        }

        return (
          <div className={`uid-${rank.user_id}`} key={rank.user_id}>
            <RankItem
              rank={rank.rank}
              username={rank.username}
              amber={rank.total_ambers.toLocaleString()}
              borderColor={borderColor}
              backgroundColor={backgroundColor}
              level={rank.level}
              profilePicture={
                rank.user_id === props.user?.my_rank.user_id
                  ? profilePicture
                  : undefined
              }
              isLoading={props.isLoading}
            />
          </div>
        );
      })}
    </div>
  );
}
