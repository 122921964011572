import { useEffect, useState } from "react";
import RankUpper from "./_components/upperpart";
import RankContent from "./_components/content";
import RankItem from "./_components/rankItem";
import { useAuth } from "../../providers/AuthProvider";
import { useProfilePicture } from "../../hooks/useProfilePicture";
import axios from "axios";
import {
  MyRankResponse,
  RankResponse,
  TimeType,
  UserRankResponse,
} from "./data/rank.dto";

export default function RankScreen() {
  const [sectionSelected, setSectionSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selfRank, setSelfRank] = useState<MyRankResponse | null>(null);
  const [ranks, setRanks] = useState<UserRankResponse[]>([]);
  const [focusMode, setFocusMode] = useState(false);
  const { user } = useAuth();
  const profilePicture = useProfilePicture();

  const fetchRank = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/rank?time_range=${
          sectionSelected === 0 ? TimeType.Weekly : TimeType.All
        }`
      );

      if (response.status === 200) {
        const data: RankResponse = response.data.data;
        const _temp = [...data.top_3_list, ...(data.rank_list ?? [])];
        if (_temp.length > 25) {
          const temp = _temp.slice(0, 25);
          setRanks(temp);
        } else {
          setRanks(_temp);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchMyRank = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/rank/mine?time_range=${
          sectionSelected === 0 ? TimeType.Weekly : TimeType.All
        }`
      );

      if (response.status === 200) {
        const data: MyRankResponse = response.data.data;
        setSelfRank(data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchMyRank()
      .then(() => fetchRank())
      .finally(() => setLoading(false));
  }, [sectionSelected]);

  useEffect(() => {
    if (focusMode) {
      setFocusMode(false);
    }
  }, [focusMode]);

  return (
    <div className="flex flex-col h-full">
      <div
        className="flex flex-col items-start px-4 pb-5 gap-4 relative"
        style={{
          height: "calc(100vh - 152px)",
          overflowY: "auto",
        }}
      >
        <RankUpper
          selected={sectionSelected}
          setSelected={(x: number | string) => {
            if (typeof x === "number") setSectionSelected(x);
          }}
          isLoading={loading}
        />
        <RankContent
          user={selfRank}
          rankList={ranks}
          isLoading={loading}
          focusMode={focusMode}
        />
      </div>
      <div
        className="flex-1"
        style={{
          padding: "8px 16px 72px 16px",
          backgroundColor: "#515150",
        }}
        onClick={() => setFocusMode(true)}
      >
        <RankItem
          rank={selfRank?.my_rank.rank ?? 0}
          username={selfRank?.my_rank.username ?? ""}
          amber={(selfRank?.my_rank.total_ambers ?? 0).toString()}
          profilePicture={profilePicture}
          level={selfRank?.my_rank.level ?? ""}
          isLoading={loading}
        />
      </div>
    </div>
  );
}
