import "tailwindcss/tailwind.css";
import "./upperInformation.css";
import { useGame } from "../../providers/GameProvider";
import { useAuth } from "../../providers/AuthProvider";
import { formatNumber } from "../../utils/helper";
import { useProfilePicture } from "../../hooks/useProfilePicture";
import HelpTipButton from "../HelpTipButton";
import { TooltipModal } from "../../screens/home/_components/modal";
import useModal from "../../hooks/useModal";

interface HomeUpperI {
  showProfilePicture: boolean;
  showProgressBar: boolean;
  useMargin: boolean;
}

interface CoinHolderI {
  text: string;
  coinImage: string;
}

function CoinHolder({ text, coinImage }: CoinHolderI) {
  const { user } = useAuth();
  return (
    <div
      className="relative flex bg-[#8B6D75] rounded-tl-2xl rounded-br-2xl rounded-tr-sm rounded-bl-sm border-black border-2 pb-1"
      style={{
        minWidth: "77px",
        maxWidth: "82px",
        height: "24px",
        paddingBottom: "2.5px",
      }}
    >
      <div className="grid grid-cols-4 py-1 bg-[#FFF8DB] rounded-tl-2xl rounded-br-xl rounded-tr-sm px-2 w-full">
        <div className="col-span-1">
          <img
            src={coinImage}
            alt="coin"
            className="absolute"
            style={{
              maxWidth: "25.5px",
              maxHeight: "25.5px",
              top: "-12px",
              left: "5px",
            }}
          />
        </div>
        <span
          className="absolute col-span-3 font-bold text-[#714C56]"
          style={{
            right: "6px",
            top: "0px",
            fontSize: "12px",
          }}
        >
          {text}
        </span>
      </div>
    </div>
  );
}

export default function UpperInformation(props: HomeUpperI) {
  const { energy, currentEnergy } = useGame();
  const { user } = useAuth();
  //const [initDataUnsafe, initData] = useInitData();
  const percentage =
    (currentEnergy / (energy?.maxEnergy ?? currentEnergy)) * 100;
  const profilePicture = useProfilePicture();

  const { isOpen, openModal, closeModal } = useModal();

  return (
    <div
      className={`w-full flex flex-row items-center justify-between px-2 ${
        props.useMargin ? "mt-4" : ""
      }`}
    >
      {/* Profile Picture Section */}
      {props.showProfilePicture && (
        <div
          className="flex flex-col justify-center items-center mr-2 relative"
          style={{
            width: "60px",
            height: "60px",
          }}
        >
          <div
            className="rounded-full border-black border-2"
            style={{
              width: "55px",
              height: "55px",
            }}
          >
            {profilePicture && (
              <img
                src={profilePicture}
                alt="profile"
                className="w-full h-full rounded-full"
              />
            )}
          </div>
          <div
            className="absolute -bottom-2 -right-4 flex bg-[#C5EFFD] flex rounded-tl-xl rounded-br-xl border-black border-2 pt-0.5"
            style={{
              maxHeight: "22.5px",
            }}
          >
            <div className="flex bg-[#4394AE] rounded-tl-xl rounded-br-lg w-full pb-0.5">
              <div className="flex bg-[#6ED8F9] rounded-tl-lg rounded-br-xl px-2 w-full">
                <span
                  className="font-bold italic text-stroke"
                  style={{ fontSize: "10px" }}
                >
                  Lvl.{user?.level?.level ?? 1}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Coin and Energy Bar Section */}
      <div className="w-full flex flex-col items-end gap-2">
        {/* Coin Section */}
        <div className="w-full flex flex-row justify-end items-center gap-2">
          <TooltipModal
            isOpen={isOpen}
            onClose={() => closeModal()}
          ></TooltipModal>
          <CoinHolder
            text={`${formatNumber(energy?.totalCoin ?? 0)}`}
            coinImage="/eggcoin_icon.png"
          />
          <CoinHolder
            text={`${formatNumber(user?.amber_amount ?? 0)}`}
            coinImage="/amber_icon.png"
          />
          <CoinHolder
            text={`${formatNumber(user?.ton_coin_amount ?? 0)}`}
            coinImage="/ton_icon.png"
          />

          <HelpTipButton
            onClick={async () => {
              openModal();
            }}
          />
        </div>

        {/* Energy Bar Section */}
        {props.showProgressBar && (
          <div
            className="flex justify-center items-center relative"
            style={{ width: "283px", height: "24px" }}
          >
            <div className="absolute h-10 -left-2 z-30">
              <img src="/thunder.png" alt="thunder" className="h-full" />
            </div>
            <div
              className="w-full h-6 bg-[#8B6D75] rounded-lg progress-border"
              style={{
                paddingBottom: "2px",
              }}
            >
              <div className="relative w-full h-full bg-[#9e8389] rounded-lg">
                <div
                  className={`absolute top-0 left-0 bg-[#9AE2AA] ${
                    percentage > 98.5 ? "rounded-lg" : ""
                  }`}
                  style={{
                    width: `${percentage}%`,
                    height: "19px",
                    paddingTop: "2px",
                    transition: "width 1s ease-in-out",
                  }}
                >
                  <div
                    className={`w-full h-full bg-[#46A65B] ${
                      percentage > 98.5 ? "rounded-lg" : ""
                    }`}
                    style={{ paddingBottom: "1.8px" }}
                  >
                    <div
                      className={`w-full h-full bg-[#57CF72] ${
                        percentage > 98.5 ? "rounded-lg" : ""
                      }`}
                    />
                  </div>
                </div>
              </div>

              <div
                className="absolute text-white font-semibold text-stroke"
                style={{ fontSize: "14px", top: "2px", left: "100px" }}
              >
                {currentEnergy} / {energy?.maxEnergy ?? 0}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
