import "tailwindcss/tailwind.css";
import "../home.css";
import { useGame } from "../../../providers/GameProvider";

interface HomeBottomI {
  selected: number | string;
  setSelected: (x: number | string) => void;
  progressPercentage?: number | null;
  progressBarColor?: string;
  progressBgColor?: string;
  showProgressBar?: boolean;
  autoFarmOpen?: boolean;
}

export default function HomeBottom(props: HomeBottomI) {
  const {
    selected,
    setSelected,
    progressPercentage = 0,
    autoFarmOpen = false,
    showProgressBar = true,
  } = props;

  const progressBar = () => {
    return (
      <div className="w-full flex justify-center items-center mb-1">
        {!!progressPercentage && showProgressBar && (
          <div className={`w-4/5 flex justify-center items-center relative`}>
            <div
              className="absolute mr-2 -left-2 -top-5"
              style={{
                height: "50px",
              }}
            >
              <img src="/egg_oh_bar.png" alt="oh-my-egg" className="h-full" />
            </div>
            <div
              className={`w-full bg-[#6D286E] rounded-lg progress-border`}
              style={{
                height: "23px",
                paddingBottom: "2px",
              }}
            >
              <div className="w-full h-full bg-[#8E48AA] rounded-lg">
                <div
                  className={`transition duration-150 ease-out h-full bg-[#F19EF2] ${
                    progressPercentage < 100 ? "" : "rounded-lg"
                  }`}
                  style={{
                    width: `${progressPercentage}%`,
                    paddingTop: "2px",
                    transition: "width 1s ease-in-out",
                  }}
                >
                  <div
                    className={`h-full w-full bg-[#6D286E] ${
                      (progressPercentage ?? 0) % 100 < 98 ? "" : "rounded-lg"
                    }`}
                    style={{ paddingBottom: "1.8px" }}
                  >
                    <div
                      className={`h-full w-full bg-[#E85DEA]  ${
                        (progressPercentage ?? 0) % 100 < 98 ? "" : "rounded-lg"
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full py-2 flex flex-col gap-2 px-6">
      {/* Progress Bar */}
      {showProgressBar && progressBar()}

      {/* Div Buttons */}
      <div
        className="w-full flex border-black border-2 bg-[#FFBAB9] rounded-full"
        style={{
          height: "37px",
        }}
      >
        <div className="relative w-full h-full flex flex-row border-[#FF9A97] border-b-4 rounded-full">
          <div
            onClick={() => setSelected(0)}
            className={`absolute left-0 w-1/2 flex text-center font-bold ${
              selected === 0
                ? "bg-[#FF9A97] border-2 border-[#5A3D45] rounded-full"
                : "border-transparent text-[#fffbea] cursor-pointer"
            }`}
            style={{
              paddingTop: "2.5px",
            }}
          >
            <div
              className={`w-full flex  rounded-full ${
                selected === 0 ? "bg-[#AB3636]" : ""
              }`}
              style={{
                paddingBottom: "2.5px",
              }}
            >
              <div
                className={`relative w-full rounded-full ${
                  selected === 0 ? "bg-[#FF5B5A]" : ""
                }`}
              >
                <span
                  className="text-stroke"
                  data-text="Clicker"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  Clicker{" "}
                </span>
                {selected === 0 && (
                  <div
                    className="absolute inset-0 top-1 left-1 bg-[#FF9A97] rounded-l-full max-w-8 max-h-5"
                    style={{ clipPath: "circle(20% at 20% 20%)" }}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              if (!autoFarmOpen) return;
              setSelected(1);
            }}
            className={`absolute right-0 w-1/2 flex text-center ${
              selected === 1
                ? "bg-[#FF9A97] border-2 border-[#5A3D45] rounded-full"
                : "border-transparent text-[#fffbea]"
            } ${
              selected !== 1 && autoFarmOpen
                ? "cursor-pointer"
                : "cursor-disabled"
            }`}
            style={{
              paddingTop: "2.5px",
            }}
          >
            <div
              className={`w-full flex  rounded-full ${
                selected === 1 ? "bg-[#AB3636]" : ""
              }`}
              style={{
                paddingBottom: "2.5px",
              }}
            >
              <div
                className={`relative w-full rounded-full ${
                  selected === 1 ? "bg-[#FF5B5A]" : ""
                }`}
              >
                {autoFarmOpen ? (
                  <span
                    className="text-stroke-home-opt font-bold"
                    data-text="Auto-Farm"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Auto-Farm{" "}
                  </span>
                ) : (
                  <div className="w-full flex flex-row items-center justify-center gap-2">
                    <div className="flex flex-col items-center justify-center">
                      <span
                        className="text-stroke font-bold"
                        data-text="Auto-Farm"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Auto-Farm{" "}
                      </span>
                      <span
                        className="text-stroke font-semibold"
                        data-text="Auto-Farm"
                        style={{
                          fontSize: "8px",
                        }}
                      >
                        Unlocked at Lvl.99{" "}
                      </span>
                    </div>
                    <img
                      src="/lock_icon.png"
                      alt="auto farm"
                      className="h-5 w-5"
                    />
                  </div>
                )}
                {selected === 1 && (
                  <div
                    className="absolute inset-2 top-1 left-1 bg-[#FF9A97] rounded-l-full max-w-8 max-h-5"
                    style={{ clipPath: "circle(20% at 20% 20%)" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
