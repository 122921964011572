import { useInitData } from "@vkruglikov/react-telegram-web-app";
import React, { useContext, useEffect, useState } from "react";
import LandingPage from "./screens/landingPage/screen";
import FooterMenu from "./components/FooterMenu";
import { usePage } from "./providers/PageProvider";
import { PageLocation } from "./const/menu.const";
import { AlertContext } from "./components/alert/AlertProvider";
import { AlertTheme } from "./const/alert.const";
import TeleDesktopScreen from "./screens/teleDesktop/screen";

// 2 seconds inactivity threshold

function App() {
  const [initDataUnsafe, initData] = useInitData();
  const [isMobile, setIsMobile] = useState(true);
  const { selectedPage, setSelectedPage } = usePage();
  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    if (!initDataUnsafe?.user) {
      console.error("Telegram Web App context not ready");
      return;
    }
    if (!selectedPage) {
      setSelectedPage("onboarding");
    }

    if (initDataUnsafe?.user) {
      const userAgent = navigator.userAgent || navigator.vendor;
      if (!/Mobile|Android|iPhone|iPad/i.test(userAgent)) {
        setIsMobile(false);
      }
    }
  }, [initData, initDataUnsafe]);

  useEffect(() => {
    window.alert = (message: string | React.ReactNode, theme?: AlertTheme) => {
      setAlert(message, theme);
    };
  }, [setAlert]);

  if (!initDataUnsafe?.user) {
    return <LandingPage />;
  }

  if (
    initDataUnsafe.user &&
    !isMobile &&
    process.env.REACT_APP_ALLOW_DESKTOP !== "true"
  ) {
    return <TeleDesktopScreen />;
  }

  return (
    <div
      className="h-screen flex flex-col overflow-y-auto"
      style={{
        backgroundImage: `${selectedPage?.background ?? "white"}`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="flex-grow">{selectedPage?.screen}</div>
      {selectedPage?.location.includes(PageLocation.FOOTER) && (
        <div className="w-full absolute bottom-0">
          <FooterMenu
            selectedMenu={selectedPage?.name}
            setSelectedMenu={setSelectedPage}
            setScreen={(screen) => {}}
          />
        </div>
      )}
    </div>
  );
}

export default App;
