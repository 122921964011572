import { useEffect } from "react";
import { Modal } from "../../../components/Modal/Modal";
import { AlertTheme } from "../../../const/alert.const";
import { hatchlingBg } from "../../../const/asset.const";
import Style from "../../../components/Style";

export const EvolutionModal = ({
  isOpen,
  onClose,
  item,
}: {
  isOpen: boolean;
  onClose: () => void;
  item: any;
}) => {
  const { bgGradient, border } = hatchlingBg(item?.faction);
  // TODO: API integration
  useEffect(() => {
    if (isOpen) {
      alert(
        "Successful Evolve!",
        //@ts-ignore
        AlertTheme.SUCCESS
      );
    }
  }, [isOpen]);

  const handleTap = () => {
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div
        className="flex flex-col items-center justify-start gap-5"
        onClick={handleTap}
      >
        <div className="text-center text-white text-2xl font-extrabold font-['Montserrat'] leading-loose">
          Combination Complete
        </div>

        <div className="flex justify-center items-center relative">
          <img
            className="w-[159px] h-[159.83px] border-black"
            src="/intersect.png"
            alt="radial background"
          />
          <div
            className={`h-[50px] w-[50px] p-2 ${bgGradient} rounded-lg border-[2.5px] ${border} inline-flex justify-center items-center absolute`}
          >
            <img
              className="w-full h-full"
              src={item?.assets_url}
              alt="Hatchling"
            />

            <div className="absolute bottom-0 right-0 text-[#FFFDF4] text-xs font-extrabold leading-[14px] p-1 break-words drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
              Lv.{item?.level ?? 1}
            </div>
          </div>
        </div>

        <div className="text-center text-white text-sm font-extrabold leading-none">
          Level Increase
        </div>
        <div className="w-[136px] text-center text-white text-[32px] font-extrabold leading-10">
          + 1
        </div>

        <Style.Typography
          variant="label"
          className="text-white font-bold text-center mt-20"
        >
          Tap Anywhere to Exit
        </Style.Typography>
      </div>
    </Modal>
  );
};
