import { useEffect, useState } from "react";
import BagMenu from "./_components/menu";
import BagItems from "./_components/items";
import { AppContext } from "../../const/state.const";
import { usePage } from "../../providers/PageProvider";

export default function BagScreen() {
  const [tabSelected, setTabSelected] = useState<number | string | void>(0);
  const { getContext, removeContext } = usePage();

  useEffect(() => {
    const storedTab = getContext(AppContext.BAG_TAB);
    if (storedTab !== null) {
      setTabSelected(storedTab);
      removeContext(AppContext.BAG_TAB);
    }
  }, [getContext, removeContext]);

  return (
    <div className="w-full h-full flex flex-col items-center p-4">
      <BagMenu
        selectedTab={tabSelected}
        setSelectedTab={(x: number | string | void) => {
          setTabSelected(x);
        }}
      />
      <BagItems selectedTab={tabSelected} />
    </div>
  );
}
