import React from "react";
import Skeleton from "react-loading-skeleton";
import { Dialog } from "../../../../components/dialog/dialog";
import Style from "../../../../components/Style";
import { ASSETS, hatchlingBg } from "../../../../const/asset.const";
import { useHatch } from "../../../../hooks/useHatch";
import { BbirdButton } from "../../../../components/BbirdButton";
import { BbirdButtonTheme } from "../../../../const/button.const";
import { useAutoFarmContext } from "../../../../providers/AutoFarmProvider";
import { HatchlingDeployed } from "../../../../interfaces/item.interface";

export const NestModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { deploy, items } = useAutoFarmContext();
  const { hatchlings, loading } = useHatch();
  const [_selectedItems, _setSelectedItems] = React.useState<number[]>([]);

  React.useEffect(() => {
    _setSelectedItems(
      items.map((item: HatchlingDeployed) => {
        return item.hatchling_id;
      })
    );
  }, [items]);

  // render not found if no hatchlings
  const renderNoHatchlings = () => {
    return (
      <div className="flex flex-col text-center gap-4">
        <div className="px-5 my-5">
          <Style.Typography
            variant="h5"
            className="font-extrabold text-[#4E6D9B] mb-6"
          >
            You don't have any hatchlings :(
          </Style.Typography>
        </div>
      </div>
    );
  };

  const isOnList = (hatchling: any) => {
    return _selectedItems.some((item) => item === hatchling.id);
  };

  const handleDeployButton = () => {
    deploy(_selectedItems);
    onClose();
  };

  const renderHatchlingsList = () => {
    return (
      <>
        {loading ? (
          <Skeleton height={40} width={300} count={6} />
        ) : (
          <div className="max-h-[254px] overflow-y-auto">
            <div className="grid grid-cols-5 gap-1">
              {hatchlings.map((hatchling: any) => {
                const { bgGradient, border } = hatchlingBg(hatchling?.faction);
                return (
                  <div
                    key={hatchling.id}
                    className="relative"
                    onClick={() => {
                      if (isOnList(hatchling)) {
                        _setSelectedItems((prev) =>
                          prev.filter((item) => item !== hatchling.id)
                        );
                      } else {
                        if (_selectedItems.length >= 5) {
                          alert("You can only deploy 5 hatchlings");
                          return;
                        }
                        _setSelectedItems((prev) => [...prev, hatchling.id]);
                      }
                    }}
                  >
                    {/* Check if hatchling.id on selectedItems */}
                    {isOnList(hatchling) && (
                      <div className="absolute bg-gray-500 bg-opacity-60 rounded-md z-10 w-full h-full flex align-middle items-center justify-center">
                        <img
                          width={30}
                          src={ASSETS.ICON_CHECK}
                          alt="Icon check"
                        />
                      </div>
                    )}
                    {/* Hatchling */}
                    <div
                      className={`h-[50px] p-2 w-[50px] ${bgGradient} rounded-lg border-[2.5px] ${border} inline-flex justify-start items-center gap-2.5 relative`}
                      key={hatchling.id}
                    >
                      <img
                        className="w-full h-full"
                        src={hatchling?.assets_url}
                        alt="Hatchling"
                      />
                      <div className="absolute bottom-0 right-0 text-[#FFFDF4] text-[10px] font-extrabold leading-[14px] p-1 break-words drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                        Lvl.{hatchling.level}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <Dialog
      open={isOpen}
      title="Nest"
      background="#6288C2"
      innerBackground="#DAE6F6"
      titleClass="text-stroke-secondary font-bold pb-1"
      onClose={onClose}
    >
      <div className="flex flex-col items-center gap-4">
        {hatchlings.length > 0 ? renderHatchlingsList() : renderNoHatchlings()}
        <div className="flex w-full items-center flex-col">
          <BbirdButton
            theme={BbirdButtonTheme.PRIMARY}
            onClick={() => handleDeployButton()}
          >
            <p
              className="text-center text-white font-bold text-stroke"
              style={{ fontSize: "14px" }}
            >
              Deploy
            </p>
          </BbirdButton>
        </div>
      </div>
    </Dialog>
  );
};
