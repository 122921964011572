import { usePage } from "../../../providers/PageProvider";

interface ShopFootbarI {
    activeTab: string;
    setActiveTab: (x: string) => void;
}

export default function ShopFootbar(props: ShopFootbarI) {
    const { setSelectedPage } = usePage();
    const { activeTab, setActiveTab } = props;

    return (
        <footer className="w-full bg-[#353D54] px-4 py-2 mt-auto rounded-t-3xl">
            <nav className="flex items-center gap-4">
                <div
                    className="w-[45px] h-[45px] border border-[#442E34] border-2 rounded-xl bg-[#FBBF24] flex items-center justify-center hover:cursor-pointer"
                    onClick={() => {
                        setSelectedPage("Home");
                    }}
                >
                    <img src="/arrow-left.png" alt="Arrow Back" width={24} />
                </div>
                <div
                    className={`w-[120px] h-[56px] border ${
                        activeTab === "coins" ? "bg-[#E0CE83]" : "bg-[#F1E4B4]"
                    } border-[#2D1E22] border-[1.8px] rounded-xl  hover:bg-[#E0CE83] flex items-center justify-center hover:cursor-pointer relative`}
                    onClick={() => {
                        setActiveTab("coins");
                    }}
                >
                    <img src="/eggcoin_icon.png" alt="Coin Shop" width={40} />
                    <div className="absolute top-1 left-1 flex flex-col items-center justify-center h-fit">
                        <div
                            className="bg-[#FEF3C7]"
                            style={{
                                width: "6px",
                                transform: "rotate(45deg)",
                                height: "9.5px",
                                borderRadius: "50%",
                            }}
                        />
                    </div>
                </div>
                <div
                    className={`w-[120px] h-[56px] border ${
                        activeTab === "ambers" ? "bg-[#E0CE83]" : "bg-[#F1E4B4]"
                    } border-[#2D1E22] border-[1.8px] rounded-xl  hover:bg-[#E0CE83] flex items-center justify-center hover:cursor-pointer relative`}
                    onClick={() => {
                        setActiveTab("ambers");
                    }}
                >
                    <img src="/amber_icon.png" alt="Amber Shop" width={40} />
                    <div className="absolute top-1 left-1 flex flex-col items-center justify-center h-fit">
                        <div
                            className="bg-[#FEF3C7]"
                            style={{
                                width: "6px",
                                transform: "rotate(45deg)",
                                height: "9.5px",
                                borderRadius: "50%",
                            }}
                        />
                    </div>
                </div>
            </nav>
        </footer>
    );
}
