import React from "react";

export const Light = () => {
  const lightRef = React.useRef();

  // useHelper(lightRef, DirectionalLightHelper, 10, "red");
  return (
    <>
      <ambientLight type="natural" intensity={1} />
      <directionalLight
        ref={lightRef}
        castShadow
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        shadow-camera-near={1}
        shadow-camera-far={1000}
      />
    </>
  );
};
