import { useEffect } from "react";
import { usePage } from "../../../providers/PageProvider";
import { BackButton } from "../../../components/BackButton";
import { BbirdButtonTheme } from "../../../const/button.const";
import { useHatch } from "../../../hooks/useHatch";
import { hatchlingBg } from "../../../const/asset.const";
import Skeleton from "react-loading-skeleton";
import { Hatch } from "../../../interfaces/item.interface";

export default function LevelUpFooter({
  charOne,
  charTwo,
  setCharOne,
  setCharTwo,
}: {
  charOne: Hatch | null;
  charTwo: Hatch | null;
  setCharOne: (charOne: Hatch | null) => void;
  setCharTwo: (charTwo: Hatch | null) => void;
}) {
  const { setSelectedPage } = usePage();
  const { hatchlings, loading, getNest } = useHatch();

  useEffect(() => {
    getNest();
  }, [charOne, charTwo]);

  const totalSlots = 15; // 5 columns * 3 rows

  const renderHatchlingsList = () => {
    const filledSlots = hatchlings.length;
    const emptySlots = totalSlots - filledSlots;
    return (
      <>
        {loading ? (
          <Skeleton height={40} width={300} count={6} />
        ) : (
          <div className="max-h-[220px] overflow-y-auto">
            <div className="grid grid-cols-5 gap-3">
              {hatchlings.map((hatchling: any, index: number) => {
                const { bgGradient, border } = hatchlingBg(hatchling?.faction);
                const isSelected = charOne && charOne.id === hatchling.id;
                const isIdentical =
                  charOne &&
                  charOne.faction === hatchling.faction &&
                  charOne.level === hatchling.level;
                const isTwoSelected = charTwo && charTwo.id === hatchling.id;

                return (
                  <div
                    className={`relative cursor-pointer h-[50px] w-[50px] `}
                    key={index}
                    onClick={() => {
                      if (isSelected) {
                        setCharOne(null);
                      } else if (!charOne) {
                        setCharOne(hatchling);
                      } else if (
                        charOne.faction === hatchling.faction &&
                        charOne.level === hatchling.level
                      ) {
                        if (charTwo && charTwo.id === hatchling.id) {
                          setCharTwo(null);
                        } else {
                          setCharTwo(hatchling);
                        }
                      }
                    }}
                  >
                    <div
                      className={`h-full w-full p-2 ${bgGradient} rounded-lg border-[2.5px] ${border} inline-flex justify-start items-center gap-2.5  `}
                    >
                      <img
                        className="w-full h-full"
                        src={hatchling?.assets_url}
                        alt="Hatchling"
                      />
                      <div className="absolute bottom-0 right-0 text-[#FFFDF4] text-[10px] font-extrabold leading-[14px] p-1 break-words drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                        Lvl.{hatchling.level}
                      </div>
                    </div>

                    {charOne && !isIdentical && (
                      <div
                        className={`absolute inset-0 bg-black/50 rounded-lg`}
                      ></div>
                    )}
                    {isSelected && (
                      <div
                        className={`absolute inset-0 bg-black/50 flex items-center justify-center rounded-lg`}
                      >
                        <img
                          className="w-[30px] h-[30px]"
                          src="/tick_icon.svg"
                          alt="check icon"
                        />
                      </div>
                    )}
                    {isTwoSelected && (
                      <div
                        className={`absolute inset-0 bg-black/50 flex items-center justify-center rounded-lg`}
                      >
                        <img
                          className="w-[30px] h-[30px]"
                          src="/tick_icon.svg"
                          alt="check icon"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
              {Array.from({ length: emptySlots }).map((_, index) =>
                renderPlaceholder(filledSlots + index, "/hatchling_bg.png")
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  const renderPlaceholder = (key: number, placeholder: string) => {
    return (
      <div
        key={key}
        className="w-[50px] h-[50px] p-2 bg-zinc-200 rounded-lg shadow-inner border border-[#cccccb] justify-center items-center flex"
      >
        <img className="w-full h-full" src={placeholder} alt="placeholder" />
      </div>
    );
  };

  return (
    <div className="h-[50vh] bg-[#353d54] rounded-t-3xl flex flex-col gap-3 justify-start items-center px-9 py-2">
      <div className="flex text-center text-white text-xl font-extrabold leading-7">
        My Characters
      </div>
      <div className="p-3 bg-[#edf2f9] rounded-md flex justify-center items-center ">
        {renderHatchlingsList()}
      </div>
      <div className="flex flex-row justify-start items-center w-full">
        <BackButton
          theme={BbirdButtonTheme.PRIMARY}
          onClick={() => setSelectedPage("character")}
          width="45px"
        >
          <div className="flex justify-center items-center">
            <img src="/arrow-left.png" alt="back" />
          </div>
        </BackButton>
      </div>
    </div>
  );
}
