import { FunctionComponent, useEffect, useState } from "react";
import BannerSection from "./_components/banner";
import TotalPlayersSection from "./_components/total_player";
import FriendCodeSection from "./_components/friend_code";
import InviteFriendSection from "./_components/invite_friend";
import { BbirdButton } from "../../components/BbirdButton";
import { BbirdButtonTheme } from "../../const/button.const";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import FriendListComponent from "./_components/friend_list/component";
import axios from "axios";
import { AlertTheme } from "../../const/alert.const";
import { FriendI } from "./_components/friend_list/interface";

interface StatsI {
  max_code_usage: number;
  total_ambers: number;
  total_friends: number;
  total_players: number;
}

const FriendScreen: FunctionComponent = () => {
  const [myFriendCode, setMyFriendCode] = useState("Loading...");
  const [stats, setStats] = useState<StatsI>({
    max_code_usage: 0,
    total_ambers: 0,
    total_friends: 0,
    total_players: 0,
  });
  const [friendsList, setFriendsList] = useState<FriendI[]>([]);

  const getOnClickLink = (referralCode: string) => {
    return `https://t.me/share/url?url=${process.env.REACT_APP_TELEGRAM_APP_URL}?startapp=${referralCode}&text=${process.env.REACT_APP_INVITATION_DEFAULT_COPY}`;
  };

  useEffect(() => {
    const getMyFriendCode = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/friends/code`
        );
        const data = response.data.data;

        if (!data.code) {
          throw new Error("Something went wrong, no referral code found");
        }

        setMyFriendCode(data.code);
      } catch (e) {
        //@ts-ignore
        alert(e, AlertTheme.ERROR);
      }
    };

    const getFriends = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/friends`
        );
        const data = response.data.data;

        if (!Array.isArray(data)) {
          throw new Error("Something went wrong, no friends found");
        }

        const _tempFriends: FriendI[] = [];

        for (let i = 0; i < data.length; i++) {
          if (!data[i].referral_code) continue;

          const _friendUserInfo = data[i].referral_code.user;

          if (!_friendUserInfo) continue;

          _tempFriends.push({
            name: `${_friendUserInfo.first_name} ${_friendUserInfo.last_name}`,
            username: `${_friendUserInfo.username}`,
            score: data[i].egg_bonus_amount ?? 0,
          });

          setFriendsList(_tempFriends);
        }
      } catch (e) {
        //@ts-ignore
        alert(e, AlertTheme.ERROR);
      }
    };

    const getTotalPlayer = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/friends/stats`
        );
        const data = response.data.data;

        if (!data) {
          throw new Error("Something went wrong, no stats found");
        }

        setStats(data as StatsI);
      } catch (e) {
        //@ts-ignore
        alert(e, AlertTheme.ERROR);
      }
    };

    getTotalPlayer();
    getFriends();
    getMyFriendCode();
  }, []);

  return (
    <div className="flex items-start justify-center">
      <div className="p-4 flex w-full text-center flex flex-col items-center gap-4">
        <BannerSection />

        <TotalPlayersSection totalPlayer={stats.total_players} />

        <FriendCodeSection />

        <InviteFriendSection
          onInvite={function (friendCode: string): void {}}
          userFriendCode={myFriendCode}
          currentReffer={stats.total_friends}
          targetReffer={stats.max_code_usage}
          earned={stats.total_ambers}
        />

        <div className="w-full">
          <a href={getOnClickLink(myFriendCode)}>
            <BbirdButton theme={BbirdButtonTheme.PRIMARY} width="100%">
              <div className="w-full flex flex-row gap-2 items-center justify-center">
                <span>
                  <PaperAirplaneIcon className="w-5 h-5" color="#8b3618" />
                </span>
                <p
                  className="text-center text-white font-bold text-stroke"
                  style={{ fontSize: "18px" }}
                >
                  Invite
                </p>
              </div>
            </BbirdButton>
          </a>
        </div>

        <FriendListComponent
          friends={friendsList}
          totalFriend={friendsList.length}
        />

        <div style={{ height: "12.5vh" }} />
      </div>
    </div>
  );
};

export default FriendScreen;
