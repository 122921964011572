export interface TypographyProps {
  children: React.ReactNode;
  // theme?: Theme; // Implement later
  variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "label";
  className?: string;
}

const VARIANT_CLASS: {
  [key: string]: { fontSize: string; lineHeight: string };
} = {
  h5: {
    fontSize: "20px",
    lineHeight: "28px",
  },
  label: {
    fontSize: "12px",
    lineHeight: "14px",
  },
  h6: {
    fontSize: "14px",
    lineHeight: "16px",
  },
};

export const Typography = (props: TypographyProps) => {
  const { children, variant, className } = props;
  let variantClass = {};

  // Typography variant
  if (!VARIANT_CLASS[variant]) {
    return <p>Invalid variant!</p>;
  }
  variantClass = VARIANT_CLASS[variant];

  return (
    <p className={`${className}`} style={variantClass}>
      {children}
    </p>
  );
};
