import axios from "axios";
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import { UserItem, Hatch } from "../interfaces/item.interface";

export interface Energy {
  currentEnergy: number;
  maxEnergy: number;
  energyPerTap: number;
  recoveryRatePerSec: number;
  totalCoin: number;
  passiveIncomePerSec?: number;
}

interface GameContextProps {
  energy: Energy | null;
  setEnergy: (energy: any) => void;
  tap: (energyPerTap: number) => void;
  currentEnergy: number;
  setCurrentEnergy: (currentEnergy: number) => void;
  activeEgg: UserItem | null;
  setActiveEgg: (egg: UserItem | null) => void;
  hatch: Hatch | null;
  setHatch: (hatch: Hatch | null) => void;
}

const GameContext = createContext<GameContextProps>({
  energy: null,
  setEnergy: (energy: any) => {},
  tap: () => {},
  currentEnergy: 0,
  setCurrentEnergy: () => {},
  activeEgg: null,
  setActiveEgg: () => {},
  hatch: null,
  setHatch: () => {},
});

const GameProvider = ({ children }: { children: ReactNode }) => {
  const [energy, setEnergyState] = useState<Energy>({
    currentEnergy: 0,
    maxEnergy: 0,
    energyPerTap: 0,
    recoveryRatePerSec: 0,
    totalCoin: 0,
  });
  const [currentEnergy, setCurrentEnergy] = useState<number>(0);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [tapTimeoutId, setTapTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [totalClick, setTotalClick] = useState<number>(0);
  const [tapLock, setTapLock] = useState<boolean>(false);
  const [activeEgg, setActiveEggState] = useState<UserItem | null>(null);
  const [hatch, setHatchState] = useState<Hatch | null>(null);

  const setActiveEgg = (egg: UserItem | null) => {
    setActiveEggState(egg);
  };

  const setEnergy = (newEnergy: Energy) => {
    setEnergyState(newEnergy);
  };

  const setHatch = (hatch: Hatch | null) => {
    setHatchState(hatch);
  };

  const tap = useCallback(
    (energyPerTap: number) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (tapTimeoutId) {
        clearTimeout(tapTimeoutId);
      }

      setEnergyState((prevEnergy) => {
        if (prevEnergy.currentEnergy - energyPerTap < 0) {
          return prevEnergy;
        }
        setTapLock(true);

        setTotalClick((prevTotalClick) => prevTotalClick + 1);

        const newEnergy =
          prevEnergy.currentEnergy - energyPerTap > 0
            ? prevEnergy.currentEnergy - energyPerTap
            : prevEnergy.currentEnergy;

        setCurrentEnergy(newEnergy);

        return {
          ...prevEnergy,
          currentEnergy: newEnergy,
          totalCoin:
            prevEnergy.totalCoin +
            (prevEnergy.currentEnergy - energyPerTap > 0 ? energyPerTap : 0),
        };
      });

      // set egg tap count
      if (
        activeEgg &&
        activeEgg?.tap_count < activeEgg?.item?.tap_required_amount!
      ) {
        setActiveEggState((prevEgg: any) => ({
          ...prevEgg,
          tap_count: prevEgg.tap_count + 1,
        }));
      }

      if (
        activeEgg &&
        activeEgg?.tap_count >= activeEgg?.item?.tap_required_amount!
      ) {
        axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/clicker/tap`, {
            clicks: totalClick + 1,
            available_clicks: energy?.currentEnergy,
          })
          .then((response) => {
            setTapLock(true);
            setActiveEggState((prevEgg: any) => ({
              ...prevEgg,
              is_active: false,
            }));
            setHatchState(response.data.data.hatch);
          })
          .catch((e) => console.warn(e));
      }

      // const newTimeoutId = setTimeout(() => {
      //   axios
      //     .post(`${process.env.REACT_APP_BACKEND_URL}/clicker/tap`, {
      //       clicks: totalClick + 1,
      //       available_clicks: energy?.currentEnergy,
      //     })
      //     .then((response) => {
      //       setEnergyState((prevEnergy) => ({
      //         ...prevEnergy,
      //         currentEnergy: response.data.data.energy,
      //       }));
      //     })
      //     .catch((e) => console.warn(e))
      //     .finally(() => {
      //       setTotalClick(0);
      //     });
      // }, 3000);

      // setTimeoutId(newTimeoutId);
    },
    [energy, timeoutId, tapTimeoutId, totalClick, activeEgg]
  );

  useEffect(() => {
    if (tapLock) {
      if (tapTimeoutId) {
        clearTimeout(tapTimeoutId);
      }
      console.log("BEFORE trigger tap request to be", totalClick);
      if (totalClick < 1) {
        return;
      }
      console.log("trigger tap request to be", totalClick);
      const newTapTimeoutId = setTimeout(() => {
        axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/clicker/tap`, {
            clicks: totalClick,
            available_clicks: energy?.currentEnergy,
          })
          .then((response) => {
            setEnergyState((prevEnergy) => ({
              ...prevEnergy,
              currentEnergy: response.data.data.energy,
            }));
            setHatchState(response.data.data.hatch);
          })
          .catch((e) => console.warn(e))
          .finally(() => {
            setTotalClick(0);
            setTapLock(false);
          });
      }, 2000);
      setTapTimeoutId(newTapTimeoutId);
    }
  }, [tapLock, totalClick]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (energy.recoveryRatePerSec > 0) {
      interval = setInterval(() => {
        setCurrentEnergy((prev) => {
          return Math.min(prev + energy.recoveryRatePerSec, energy.maxEnergy);
        });
      }, 1250);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [energy.recoveryRatePerSec, energy.maxEnergy, energy.currentEnergy]);

  // Autofarm logic
  useEffect(() => {
    // If passive income is 0, return
    if (energy.passiveIncomePerSec! <= 0) {
      return;
    }

    const interval = setInterval(() => {
      setEnergyState((prevEnergy: Energy) => {
        if (!prevEnergy) {
          return prevEnergy;
        }

        return {
          ...prevEnergy,
          totalCoin: prevEnergy.totalCoin + prevEnergy.passiveIncomePerSec!,
        };
      });
    }, 1000);

    // Cleanup
    return () => {
      clearInterval(interval);
    };
  }, [energy.passiveIncomePerSec]);

  const contextValue = useMemo(
    () => ({
      energy,
      setEnergy,
      tap,
      currentEnergy,
      setCurrentEnergy,
      activeEgg,
      setActiveEgg,
      hatch,
      setHatch,
    }),
    [energy, tap, currentEnergy, activeEgg, hatch]
  );

  return (
    <GameContext.Provider value={contextValue}>{children}</GameContext.Provider>
  );
};

export const useGame = () => {
  return useContext(GameContext);
};

export default GameProvider;
