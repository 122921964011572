import ShopFootbar from "./_components/shopFootbar";
import ShopHeader from "./_components/shopHeader";
import ShopCoins from "./_components/shopCoins";
import { useState } from "react";
import ShopAmbers from "./_components/shopAmbers";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { usePage } from "../../providers/PageProvider";

export default function ShopScreen() {
  const [activeTab, setActiveTab] = useState("coins");
  const { setSelectedPage } = usePage();

  return (
    <div className="mb-[75px]">
      {activeTab === "coins" ? (
        <>
          <ShopHeader
            title="BBCoins Deals"
            description="Any first purchase of any tier earns 2x Rebate rewards"
          />
          <ShopCoins />
        </>
      ) : (
        <>
          <ShopHeader
            title="Ambers Deals"
            description="Any first purchase of any tier earns 2x Rebate rewards"
          />
          <ShopAmbers />
        </>
      )}
      <div className="w-full absolute bottom-0 z-50">
        <ShopFootbar activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <BackButton
        onClick={() => {
          setSelectedPage("Home");
        }}
      />
    </div>
  );
}
