import { useState, useEffect } from "react";
import { Hatch } from "../interfaces/item.interface";
import axios from "axios";
import { useGame } from "../providers/GameProvider";

type UseHatchI = {
  hatchlings: Hatch[];
  loading: boolean;
  getNest: () => void;
  hatchByFactionLvl: Hatch[];
};

export function useHatch(): UseHatchI {
  const [hatchlings, setHatchlings] = useState<Hatch[]>([]);
  const [hatchByFactionLvl, setHatchByFactionLvl] = useState<Hatch[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { activeEgg } = useGame();

  useEffect(() => {
    setLoading(true);
    getNest();
  }, []);

  // When the active egg changes, we need to refresh the nest
  useEffect(() => {
    if (!activeEgg) {
      getNest();
    }
  }, [activeEgg]);

  const getNest = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/nests`)
      .then((data) => {
        const result: Hatch[] = data.data.data;
        setHatchlings(result);

        const groupedData = result.reduce((acc: any, item: any) => {
          const { faction, level, assets_url } = item;

          let factionGroup = acc.find(
            (group: any) => group.faction === faction
          );

          if (!factionGroup) {
            factionGroup = { faction, assets_url, levels: [] };
            acc.push(factionGroup);
          }

          let levelGroup = factionGroup.levels.find(
            (l: any) => l.level === level
          );

          if (!levelGroup) {
            levelGroup = { level, items: [] };
            factionGroup.levels.push(levelGroup);
          }

          levelGroup.items.push(item);

          return acc;
        }, []);

        setHatchByFactionLvl(groupedData);
      })
      .finally(() => setLoading(false));
  };

  return {
    hatchlings,
    loading,
    getNest,
    hatchByFactionLvl,
  };
}
