import React from "react";
import { FloatingIcon } from "../../../components/FloatingIcon/FloatingIcon";
import useModal from "../../../hooks/useModal";
import { LevelUpModal } from "./modal";

export const LevelUp = () => {
  const { isOpen, toggleModal } = useModal();
  return (
    <>
      <LevelUpModal isOpen={isOpen} onClose={() => toggleModal()} />
      <div className="animated-button cursor-pointer">
        <FloatingIcon
          icon={"/Icons_LevelUp.svg"}
          title="Level Up"
          onClick={() => {
            toggleModal();
          }}
        />
      </div>
    </>
  );
};
