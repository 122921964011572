import React, { useState } from "react";
import {
  BbirdButtonI,
  BbirdButtonThemeOptions,
  ButtonTheme,
} from "../const/button.const";

export function BbirdButton(props: BbirdButtonI) {
  const { onClick, children, disabled, width, theme, selected } = props;
  const [hover, setHover] = useState(false);

  const btnTheme: ButtonTheme =
    typeof theme === "string" ? BbirdButtonThemeOptions[theme] : theme;

  const getButtonStyles = () => {
    if (disabled) {
      return {
        topStrokeColor: btnTheme.topStrokeColor.disabled,
        mainColor: btnTheme.mainColor.disabled,
        bottomStrokeColor: btnTheme.bottomStrokeColor.disabled,
        lightningEffectColor: btnTheme.lightningEffectColor.disabled,
        borderColor: btnTheme.borderColor.disabled,
      };
    }
    return {
      topStrokeColor: btnTheme.topStrokeColor.default,
      mainColor: btnTheme.mainColor.default,
      bottomStrokeColor: btnTheme.bottomStrokeColor.default,
      lightningEffectColor: btnTheme.lightningEffectColor.default,
      borderColor: btnTheme.borderColor.default,
    };
  };

  const getHoverStyles = () => ({
    mainColor: btnTheme.mainColor.pressed,
    topStrokeColor: btnTheme.topStrokeColor.pressed,
    bottomStrokeColor: btnTheme.bottomStrokeColor.pressed,
    lightningEffectColor: btnTheme.lightningEffectColor.pressed,
    borderColor: btnTheme.borderColor.pressed,
  });

  const buttonStyles = getButtonStyles();
  const hoverStyles = getHoverStyles();
  const appliedStyles = hover || selected ? hoverStyles : buttonStyles;

  return (
    <div
      className={`border-2 rounded-xl shadow-md transition-transform transform duration-300 ease-in-out ${
        disabled
          ? "opacity-50 cursor-not-allowed"
          : "hover:scale-105 active:scale-95 cursor-pointer"
      }`}
      onClick={(e) => {
        if (onClick && !disabled) {
          onClick(e);
        }
      }}
      style={{
        paddingTop: `1.8px`,
        width: width || "50%",
        boxShadow: `0 2.5px 0 rgba(32, 37, 50, 0.2)`,
        backgroundColor: appliedStyles.topStrokeColor,
        borderColor: appliedStyles.borderColor,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseDown={() => setHover(false)}
      onTouchCancel={() => setHover(false)}
    >
      <div
        className="w-full flex flex-row items-center justify-center rounded-lg"
        style={{
          paddingBottom: `2.5px`,
          backgroundColor: appliedStyles.bottomStrokeColor,
        }}
      >
        <div
          className="relative py-1 w-full flex flex-row items-center justify-center rounded-lg"
          style={{
            backgroundColor: appliedStyles.mainColor,
          }}
        >
          {children}
          <div className="absolute -top-1 left-1 flex flex-col items-center justify-center h-full">
            <div
              className="bg-[#FEF3C7]"
              style={{
                width: "6px",
                transform: "rotate(45deg)",
                height: "9.5px",
                borderRadius: "50%",
                backgroundColor: appliedStyles.lightningEffectColor,
              }}
            />
            <div
              className="bg-[#FEF3C7] w-1.5 h-1.5"
              style={{
                height: "5px",
                width: "5px",
                borderRadius: "50%",
                marginTop: "1.5px",
                marginRight: "3px",
                backgroundColor: appliedStyles.lightningEffectColor,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
