import { useState, useEffect } from "react";
import { Hatch } from "../../../interfaces/item.interface";
import { hatchlingBg } from "../../../const/asset.const";
import { BbirdButtonTheme } from "../../../const/button.const";
import { BbirdButton } from "../../../components/BbirdButton";
import { EvolutionModal } from "./modal";
import useModal from "../../../hooks/useModal";
import axios from "axios";
import { AlertTheme } from "../../../const/alert.const";
import { useHatch } from "../../../hooks/useHatch";

export default function LevelUpUpper({
  charOne,
  charTwo,
  setCharOne,
  setCharTwo,
}: {
  charOne: Hatch | null;
  charTwo: Hatch | null;
  setCharOne: (charOne: Hatch | null) => void;
  setCharTwo: (charTwo: Hatch | null) => void;
}) {
  // TODO: Level up API integration
  const [evolvedChar, setEvolvedChar] = useState<Hatch | null>(null);
  const { isOpen, closeModal, openModal } = useModal();
  const { bgGradient, border } = hatchlingBg(charOne?.faction);

  const upgradeHatchling = async (hatchlingIds: [number, number]) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/nests/upgrade`;
    const payload = {
      hatchling_ids: hatchlingIds,
    };
    const response = await axios.post(url, payload);

    if (response.status === 200) {
      setEvolvedChar(response.data.data);
      openModal();
    } else {
      //@ts-ignore
      alert("Failed to upgrade hatchlings", AlertTheme.ERROR);
    }
  };

  const renderEvolution = () => {
    return (
      <>
        {charOne ? (
          <div className="w-[313px] h-[91px] bg-[#feefd7] rounded-xl border border-[#714c56] justify-between items-center inline-flex">
            <div className="w-[313px] h-[91px] relative rounded-xl border border-[#ff8781] flex-row justify-center items-center flex gap-1 p-2">
              <div
                className={`h-14 w-14 p-2 ${bgGradient} rounded-lg border-[2.5px] ${border} inline-flex justify-center items-center relative`}
              >
                <img
                  className="w-full h-full"
                  src={charOne?.assets_url}
                  alt="Hatchling"
                />
                <div className="absolute bottom-0 right-0 px-1 text-[#FFFDF4] text-[10px] font-extrabold leading-[14px] break-words drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                  Lv.{charOne?.level}
                </div>
              </div>

              <div className="flex flex-col gap-1 justify-start items-center px-1">
                <div className="relative">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100%"
                      height="32"
                      viewBox="0 0 316 32"
                      fill="none"
                    >
                      <path
                        d="M1.16504 2.48528C0.319541 1.51472 1.00887 0 2.29607 0H313.704C314.991 0 315.68 1.51472 314.835 2.48529L303.634 15.3431C303.306 15.7196 303.306 16.2804 303.634 16.6569L314.835 29.5147C315.68 30.4853 314.991 32 313.704 32H2.29605C1.00886 32 0.319542 30.4853 1.16504 29.5147L12.3661 16.6569C12.6941 16.2804 12.6941 15.7196 12.3661 15.3431L1.16504 2.48528Z"
                        fill="#353D54"
                      />
                    </svg>
                  </div>
                  <div className="flex items-center justify-center gap-2 absolute top-0 mt-1 text-white font-extrabold right-0 left-0 bottom-0">
                    <p className="">Lv.{charOne?.level ?? 1}</p>
                    {charOne?.level !== 100 && (
                      <>
                        <img src="/arrow-right.png" width={25} alt="Target" />
                        <p className="">Lv.{(charOne?.level ?? 1) + 1}</p>
                      </>
                    )}
                  </div>
                </div>

                <div className="text-[#442e34] text-xs font-extrabold leading-[14px] text-left text-wrap">
                  Evolve to Level {(charOne?.level ?? 1) + 1}{" "}
                  <span className="capitalize">{charOne?.faction ?? ""}</span>{" "}
                  Hatchling
                </div>
              </div>
            </div>
          </div>
        ) : (
          <img
            className="w-[75px] h-[75px]"
            src="/leveled_up.png"
            alt="Upgraded hatchling placeholder"
          />
        )}
      </>
    );
  };

  const renderCharOne = () => {
    return (
      <>
        {charOne ? (
          <div
            className={`h-[60px] w-[60px] p-2 ${bgGradient} rounded-lg border-[2.5px] ${border} inline-flex justify-center items-center relative`}
          >
            <img
              className="w-full h-full"
              src={charOne?.assets_url}
              alt="Hatchling"
            />
            <div className="absolute bottom-0 right-0 px-1 text-[#FFFDF4] text-[10px] font-extrabold leading-[14px] break-words drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
              Lv.{charOne?.level}
            </div>
          </div>
        ) : (
          <img
            className="w-[60px] h-[60px]"
            src="/level_up.png"
            alt="hatchling 1 placeholder"
          />
        )}
      </>
    );
  };

  const renderCharTwo = () => {
    return (
      <>
        {charTwo ? (
          <div
            className={`h-[60px] w-[60px] p-2 ${bgGradient} rounded-lg border-[2.5px] ${border} inline-flex justify-center items-center relative`}
          >
            <img
              className="w-full h-full"
              src={charTwo?.assets_url}
              alt="Hatchling"
            />
            <div className="absolute bottom-0 right-0 px-1 text-[#FFFDF4] text-[10px] font-extrabold leading-[14px] break-words drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
              Lv.{charTwo?.level}
            </div>
          </div>
        ) : (
          <img
            className="w-[60px] h-[60px]"
            src="/level_up.png"
            alt="hatchling 2 placeholder"
          />
        )}
      </>
    );
  };

  const renderCombine = () => {
    if (charOne && charTwo) {
      return (
        <div className="z-100">
          <BbirdButton
            theme={BbirdButtonTheme.PRIMARY}
            width="155px"
            onClick={async () => {
              await upgradeHatchling([charOne.id, charTwo.id]);
              setCharOne(null);
              setCharTwo(null);
            }}
          >
            <p
              className="text-center text-white font-bold text-stroke"
              style={{ fontSize: "14px" }}
            >
              Combine
            </p>
          </BbirdButton>
        </div>
      );
    } else if (charOne || charTwo) {
      return (
        <BbirdButton theme={BbirdButtonTheme.PRIMARY} width="155px" disabled>
          <p
            className="text-center text-white font-bold text-stroke"
            style={{ fontSize: "14px" }}
          >
            Combine
          </p>
        </BbirdButton>
      );
    } else {
      return (
        <div className="flex text-center text-[#442e34] text-sm font-extrabold leading-none">
          Choose and combine to evolve
        </div>
      );
    }
  };

  const handleCloseModal = () => {
    closeModal();
    setEvolvedChar(null);
  };

  return (
    <>
      <EvolutionModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        item={evolvedChar}
      ></EvolutionModal>
      <div className="flex flex-col justify-center items-center gap-1 p-2">
        {renderEvolution()}
        <img className="w-[30px] h-[30px]" src="/up_icon.png" alt="up icon" />
        <div className="relative bg-[#feefd7] rounded-xl border border-[#714c56]">
          <div className="absolute inset-0 rounded-xl border border-[#ff8781] z-10 pointer-events-none"></div>
          <div className="flex flex-col justify-center items-center gap-2 py-3 px-10">
            <div className="flex flex-row justify-center items-center gap-5">
              {renderCharOne()}
              <img className="w-10 h-10" src="/plus_icon.png" />
              {renderCharTwo()}
            </div>
            {renderCombine()}
          </div>
        </div>
      </div>
    </>
  );
}
