import AppHomePage from "../screens/home/screen";
import OnboardingScreen from "../screens/onboarding/screen";
import RankScreen from "../screens/rank/screen";
import FriendScreen from "../screens/friend/screen";
import QuestScreen from "../screens/quest/screen";
import BagScreen from "../screens/bag/screen";
import ShopScreen from "../screens/shop/screen";
import Character from "../screens/bag/_components/character";
import LevelUpScreen from "../screens/level-up/screen";

export enum PageLocation {
  NAVBAR = "navbar",
  FOOTER = "footer",
  SIDEBAR = "sidebar",
  NONE = "none",
}

export interface PageI {
  name: string;
  url: string;
  location: PageLocation[];
  toolTip?: string;
  icon: string | React.ReactElement;
  iconOnHover?: string | React.ReactElement;
  iconOnSelected?: string | React.ReactElement;
  screen?: React.ReactElement;
  disabled?: boolean;
  background?: string;
}

export const MenuList: PageI[] = [
  {
    name: "Home",
    location: [PageLocation.FOOTER],
    icon: "/menuicon/home_menu.png",
    iconOnSelected: "/menuicon/home_selected.svg",
    toolTip: "Home page",
    screen: <AppHomePage />,
    url: "/home",
    background: "url('/homebg.png')",
  },
  {
    name: "Quest",
    location: [PageLocation.FOOTER],
    icon: "/menuicon/quest_menu.png",
    iconOnSelected: "/menuicon/quest_selected.svg",
    toolTip: "Open here to buy some interesting items",
    screen: <QuestScreen />,
    disabled: false,
    url: "/quest",
    background: "white",
  },
  {
    name: "Friends",
    location: [PageLocation.FOOTER],
    icon: "/menuicon/friends_menu.png",
    iconOnSelected: "/menuicon/friends_selected.png",
    toolTip: "Friend page",
    screen: <FriendScreen />,
    disabled: false,
    url: "/friends",
    background: "('/friends_bg.png')",
  },
  {
    name: "Rank",
    location: [PageLocation.FOOTER],
    icon: "/menuicon/rank_menu.png",
    iconOnSelected: "/menuicon/rank_selected.png",
    toolTip: "Rank page",
    screen: <RankScreen />,
    disabled: false,
    url: "/rank",
  },
  {
    name: "Bag",
    location: [PageLocation.FOOTER],
    icon: "/menuicon/wallet_menu.png",
    iconOnSelected: "/menuicon/wallet_selected.png",
    toolTip: "Bag page",
    screen: <BagScreen />,
    disabled: false,
    url: "/bag",
    background: "url('/friends_bg.png')",
  },
  {
    name: "onboarding",
    location: [PageLocation.NONE],
    icon: "",
    url: "on-boarding",
    screen: <OnboardingScreen />,
    background: "url('/onboard_bg.png')",
  },
  {
    name: "shop",
    location: [PageLocation.NONE],
    icon: "",
    url: "shop",
    screen: <ShopScreen />,
  },
  {
    name: "character",
    location: [PageLocation.NONE],
    icon: "",
    url: "character",
    screen: <Character />,
  },
  {
    name: "level-up",
    location: [PageLocation.NONE],
    icon: "",
    url: "level-up",
    screen: <LevelUpScreen />,
    background: "url('/shop_bg.png')",
  },
];
