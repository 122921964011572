import { useState, useEffect } from "react";
import { BbirdButton } from "../../../components/BbirdButton";
import { BbirdButtonTheme } from "../../../const/button.const";
import { BackButton } from "../../../components/BackButton";
import { usePage } from "../../../providers/PageProvider";
import { AppContext } from "../../../const/state.const";
import { LtButton } from "../../../components/LtButton";
import { RtButton } from "../../../components/RtButton";

const Character = () => {
  const { getContext, removeContext, setSelectedPage, insertOrUpdateContext } =
    usePage();

  const [item, setItem] = useState<any>(getContext(AppContext.CHARACTER));
  const [index, setIndex] = useState<any>(getContext(AppContext.CHAR_INDEX));

  const hatchlings: any = getContext(AppContext.HATCHLINGS);

  useEffect(() => {
    const currentItem = getContext(AppContext.CHARACTER);
    const currentIndex = getContext(AppContext.CHAR_INDEX);
    setItem(currentItem);
    setIndex(currentIndex);
  }, [getContext]);

  // Update both local state & context when navigating
  const updateCharacter = (newIndex: number) => {
    const newItem = hatchlings[newIndex];
    setItem(newItem);
    setIndex(newIndex);
    insertOrUpdateContext(AppContext.CHARACTER, newItem);
    insertOrUpdateContext(AppContext.CHAR_INDEX, newIndex);
  };

  const handleCloseCharacter = () => {
    removeContext(AppContext.CHARACTER);
    setSelectedPage("Bag");
  };

  const handleNext = () => {
    const nextIndex = (index + 1) % hatchlings.length;
    updateCharacter(nextIndex);
  };

  const handlePrev = () => {
    const prevIndex = (index - 1 + hatchlings.length) % hatchlings.length;
    updateCharacter(prevIndex);
  };

  const characterBg = (faction: string | undefined) => {
    switch (faction) {
      case "lava":
        return {
          drawerBg:
            "bg-[#53200A] shadow-[0_-5px_5px_rgba(15,17,24,0.3)] border-t-4 border-[#FF8903]",
          title: "text-white",
          text: "text-amber-50",
          textarea: "bg-[#66341f]",
        };
      case "sun":
        return {
          drawerBg:
            "bg-[#d97706] shadow-[0_-5px_5px_rgba(15,17,24,0.3)] border-t-4 border-[#F1E4B4]",
          title: "text-amber-50",
          text: "text-amber-50",
          textarea: "bg-amber-500",
        };
      case "ocean":
        return {
          drawerBg:
            "bg-[#0097de] shadow-[0_-5px_5px_rgba(15,17,24,0.3)] border-t-4 border-[#BFF5FF]",
          title: "text-white",
          text: "text-amber-50",
          textarea: "bg-[#00b8ee]",
        };
      case "shadow":
        return {
          drawerBg:
            "bg-[#151822] shadow-[0_-5px_5px_rgba(15,17,24,0.3)] border-t-4 border-[#9D62D0]",
          title: "text-[#d7d8dd]",
          text: "text-[#d7d8dd]",
          textarea: "bg-[#353d54]",
        };
      case "nature":
        return {
          drawerBg:
            "bg-[#347C44] shadow-[0_-5px_5px_rgba(15,17,24,0.3)] border-t-4 border-[#CFE8D2]",
          title: "text-white",
          text: "text-amber-50",
          textarea: "bg-[#46a65b]",
        };
      default:
        return {
          drawerBg:
            "bg-[#151822] shadow-[0_-5px_5px_rgba(15,17,24,0.3)] border-t-4 border-[#9D62D0]",
          title: "text-[#d7d8dd]",
          text: "text-[#d7d8dd]",
          textarea: "bg-[#353d54]",
        };
    }
  };

  const { drawerBg, title, text, textarea } = characterBg(item.faction);

  return (
    <div className="fixed inset-0 flex flex-col justify-end bg-black">
      {/* Top Half */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url('/${item.faction}_bg_full.png')` }}
      >
        <BackButton
          theme={BbirdButtonTheme.PRIMARY}
          onClick={handleCloseCharacter}
          width="10%"
          className="absolute top-6 left-4"
        >
          <div className="flex justify-center items-center">
            <img src="/arrow-left.png" alt="back" />
          </div>
        </BackButton>
        <div className="flex items-center justify-center mt-12 px-20">
          <img src={`/${item.faction}_title.png`} alt="title" />
        </div>
        <div className="flex justify-between items-center mt-4 p-px">
          <div className="flex justify-center items-center p-2">
            <LtButton
              theme={BbirdButtonTheme.PRIMARY}
              onClick={handlePrev}
              width="10%"
            ></LtButton>
          </div>

          <img
            src={`/${item.faction}_char.png`}
            alt="character"
            className="w-[200px] z-50"
          />

          <div className="flex justify-center items-center p-2">
            <RtButton
              theme={BbirdButtonTheme.PRIMARY}
              onClick={handleNext}
              width="10%"
            ></RtButton>
          </div>
        </div>
      </div>

      {/* Bottom Half */}
      <div
        className={`relative h-[53%] rounded-t-3xl p-6 overflow-y-auto z-10 ${drawerBg}`}
      >
        <div className="flex flex-col justify-start items-center gap-5 mt-6">
          <div className="justify-start items-center gap-2.5 inline-flex">
            <div className="text-center text-white text-xl font-extrabold font-['Montserrat'] leading-[18px]">
              Lv. {item.level}
            </div>
          </div>
          <div className="self-stretch h-[168px] flex-col justify-start items-start gap-2.5 flex">
            <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
              <div
                className={`grow shrink basis-0 h-[60px] px-3 py-2 ${textarea} rounded-[10px] justify-start items-center gap-2 flex`}
              >
                <img
                  className="w-[30px] h-[30px]"
                  src="/battle_icon.png"
                  alt="Battle"
                />
                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                  <div
                    className={`text-center ${title} text-xs font-extrabold font-['Montserrat'] leading-[14px]`}
                  >
                    Combat
                  </div>
                  <div className="self-stretch text-white text-lg font-extrabold font-['Montserrat'] leading-normal">
                    {item?.stats?.combat ?? 0}
                  </div>
                </div>
              </div>
              <div
                className={`grow shrink basis-0 h-[60px] px-3 py-2 ${textarea} rounded-[10px] justify-start items-center gap-2 flex`}
              >
                <div className="w-[25px] h-[25px] justify-center items-center flex">
                  <img
                    className="w-[25px] h-[25px]"
                    src="/eggcoin_icon.png"
                    alt="BBCoin"
                  />
                </div>
                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                  <div
                    className={`text-center ${title} text-xs font-extrabold font-['Montserrat'] leading-[14px]`}
                  >
                    BBCoin
                  </div>
                  <div className="self-stretch">
                    <span className="text-white text-lg font-extrabold font-['Montserrat'] leading-normal">
                      {item?.passive_coin_per_second * 60 ?? 0}
                    </span>
                    <span className="text-white text-xs font-extrabold font-['Montserrat'] leading-[14px]">
                      /min
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`self-stretch px-3 py-2 ${textarea} rounded-[10px] flex-col justify-center items-center gap-1 inline-flex`}
              >
                <div
                  className={`text-center  ${title}  text-xs font-extrabold font-['Montserrat'] leading-[14px]`}
                >
                  Faction
                </div>
                <img
                  className="w-[26px] h-[26px]"
                  src={`${item.faction}_icon.png`}
                  alt={`${item.faction} faction`}
                />
              </div>
            </div>
            <div
              className={`self-stretch h-[98px] p-4 ${textarea} rounded-[10px] flex-col justify-start items-start gap-2 flex`}
            >
              <div
                className={`self-stretch ${title}  text-sm font-extrabold font-['Montserrat'] leading-none`}
              >
                Ability
              </div>
              <div
                className={`self-stretch ${text} text-xs font-medium font-['Montserrat'] leading-[14px]`}
              >
                {item?.stats?.ability ?? ""}
              </div>
            </div>
          </div>
          <BbirdButton
            theme={BbirdButtonTheme.PRIMARY}
            width="40%"
            onClick={async () => {
              setSelectedPage("level-up");
            }}
          >
            <p
              className="text-center text-white font-bold text-stroke"
              style={{ fontSize: "14px" }}
            >
              Level Up
            </p>
          </BbirdButton>
        </div>
      </div>
    </div>
  );
};

export default Character;
